import React, { useContext, useEffect, useState } from 'react'
import Step from 'components/Step'
import Choix from './paimentFactureContainer/choix'
import FacturePaid from './paimentFactureContainer/facturePaid'
import Paiement from './paimentFactureContainer/paiement'
import RecapComponentPaiementFacture from './paimentFactureContainer/recapComponentPaiementFacture'
import { confirmPayment, payedFacture, userFacture } from 'requests/paiement'
import UserContext from 'contexts/UserContext'
import { tracks } from 'requests/transfer'
import paimentFactureContext from 'contexts/paimentFactureContext'

export default function PaimentFacture() {
  const { user } = useContext(UserContext)
  const { paimentFacture } = useContext(paimentFactureContext)

  const nomstep = ['Infos Facture', 'Facture à payer', 'Paiement']
  const [numStep, setNumStep] = useState(0)
  const [isOperateur, setIsOperateur] = useState((paimentFacture && paimentFacture?.isChoisiroperator) || 'null')
  const [isVal2, setVal2] = useState('0')
  const [isVal3, setVal3] = useState('0')
  const [facture, setFacture] = useState([])

  const [failedPaiementFacture, setFailedPaiementFacture] = useState(false)
  const [legnth, setLength] = useState<any>(1)
  const [loading, setLoading] = useState(true)
  //props choix
  const [pays, setPays] = useState('')
  const [numeroADSL, setNumeroADSL] = useState('')
  const [operateur, setOperateur] = useState('')

  //props facture paid
  const [montantDinar, setMontantDinar] = useState('')
  const [MontantEuro, setMontantEuro] = useState('')
  const [billerCode, setBillerCode] = useState(
    paimentFacture && paimentFacture?.isChoisiroperator === 'Topnet'
      ? 's600'
      : paimentFacture?.isChoisiroperator === 'Soned'
        ? 's502'
        : paimentFacture?.isChoisiroperator === 'Steg'
          ? 's701'
          : '',
  )
  const [objectReference, setObjectReference] = useState('')
  const [idFacture, setIdFacture] = useState('')
  const [sonedNumero, setSonedNumero] = useState('')
  const [stegNumber, setStegNumber] = useState('')

  //props paiement
  const [numCarte, setNumCarte] = useState('')
  const [codeCVC, setCodeCVC] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  const [invoiceId, setInvoiceId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const paiementFactureUser = () => {
    const phoneNumber = billerCode === 's600' ? numeroADSL : billerCode === 's502' ? sonedNumero : null
    userFacture(user?.token?.accessToken, {
      phoneNumber: phoneNumber,
      billerCode: billerCode ? billerCode : null,
      //objectReference: operateur === 'SD' ? sonedNumero : stegNumber
      objectReference: billerCode === 's701' ? stegNumber : null,
    })
      .then((response: any) => {
        if (response?.success) {
          setFacture(response)
          setLoading(false)
        } else {
          setFailedPaiementFacture(true)
          setLoading(false)
          console.log(response)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }
  const paiementFacture = async () => {
    const id = user?.user?._id
    const dataFacture = {
      id: idFacture,
      userId: id,
      SendValue: MontantEuro,
      recieveValue: montantDinar,
      billerCode: billerCode,
    }

    try {
      const response = await payedFacture(dataFacture)
      if (response?.success === true) {
        setClientSecret(response?.client_secret)
        setInvoiceId(response?.invoice_id)
      } else {
        console.log('Response failed payed Payment', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleConfirmPayment = async () => {
    const invoice_id = invoiceId
    try {
      const response = await confirmPayment(invoice_id)
      if (response?.success === true) {
        console.log('response Confirm Payment', response)
      } else {
        console.log('Response failed Payment', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const [trackId, setTrackId] = useState('')

  const functionTracks = async (stepNumber: string) => {
    const id = user?.user?._id
    let dataTracks = {
      label: 'invoice',
      step: stepNumber,
      userId: id,
    }
    if (stepNumber !== '0') {
      const newDataTracks = {
        ...dataTracks,
        trackId: trackId,
      }
      dataTracks = newDataTracks
    }
    try {
      const response = await tracks(dataTracks)
      if (response?.success === true) {
        setTrackId(response?.trackId)
      } else {
        console.error('response failed', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    functionTracks('0')
  }, [])

  return (
    <div
      className={`flex justify-center xl:items-center gap-[200px] xl:gap-[300px] 7xl:gap-5 2xl:gap-5 4xl:ml-10 xl:flex-col max-w-max 2sm:mx-auto sm:mx-auto ${
        numStep === 1 || numStep === 2 ? '3xl:gap-0' : ''
      }`}
    >
      <div className="flex flex-col items-center lg:mt-[50px] w-auto 6xl:ml-0 3xl:ml-0 ml-auto">
        <div
          className={`h-[180px] sm:h-[150px] 2sm:h-[150px] ${numStep === 1 || numStep === 2 ? '3xl:h-[250px]' : ''}`}
        >
          <div className="md:text-[25px] text-center md:ml-0 md:mr-[35px] sm:mr-0 xl:text-center text-[#1D3A70] text-[35px] font-semibold sm:mt-4 ">
            Paiement facture
          </div>
          <div className="flex mt-[25px] sm:mt-4 justify-between sm:mx-4 mx-auto 2xl:mr-5">
            {nomstep.map((item, index) => (
              <Step key={index} nomStep={item} numStep={index + 1} isActive={index <= numStep} lastStep={index < 2} />
            ))}
          </div>
        </div>
        {numStep === 0 && (
          <Choix
            onChangeStep={() => setNumStep(1)}
            pays={pays}
            setPays={(value: string) => setPays(value)}
            numeroADSL={numeroADSL}
            setNumeroADSL={(value: string) => setNumeroADSL(value)}
            isOperateur={isOperateur}
            onChange={(value: string) => setIsOperateur(value)}
            setOperateur={(value: string) => setOperateur(value)}
            paiementFactureUser={paiementFactureUser}
            billerCode={billerCode}
            facture={facture}
            setBillerCode={(value: string) => setBillerCode(value)}
            objectReference={objectReference}
            setObjectReference={(value: string) => setObjectReference(value)}
            functionTracks={functionTracks}
            stegNumber={stegNumber}
            setStegNumber={(value: string) => setStegNumber(value)}
            sonedNumero={sonedNumero}
            setSonedNumero={(value: string) => setSonedNumero(value)}
          />
        )}

        {numStep === 1 && (
          <FacturePaid
            isTopnet={isOperateur === 'Topnet'}
            isSoned={isOperateur === 'Soned' ? true : false}
            isSteg={isOperateur === 'Steg' ? true : false}
            onChangeVal={(val2: string, val3: string) => {
              setVal2(val2)
              setVal3(val3)
            }}
            onChangeStep={() => setNumStep(2)}
            onBackChangeStep={() => setNumStep(0)}
            setMontantDinar={(value: string) => setMontantDinar(value)}
            setMontantEuro={(value: string) => setMontantEuro(value)}
            facture={facture}
            billerCode={billerCode}
            setBillerCode={(value: string) => setBillerCode(value)}
            idFacture={idFacture}
            setIdFacture={(value: string) => setIdFacture(value)}
            failedPaiementFacture={failedPaiementFacture}
            legnth={legnth}
            functionTracks={functionTracks}
            handleConfirmPayment={handleConfirmPayment}
            setInvoiceId={(value: string) => setInvoiceId(value)}
            paiementFacture={paiementFacture}
            loading={loading}
          />
        )}

        {numStep === 2 && (
          <Paiement
            isTopnet={isOperateur === 'Topnet'}
            isSoned={isOperateur === 'Soned' ? true : false}
            isSteg={isOperateur === 'Steg' ? true : false}
            onChangeVal={(val2: string, val3: string) => {
              setVal2(val2)
              setVal3(val3)
            }}
            ProviderCode={isOperateur}
            setNumCarte={(value: string) => setNumCarte(value)}
            setCodeCVC={(value: string) => setCodeCVC(value)}
            setMonth={(value: string) => setMonth(value)}
            setYear={(value: string) => setYear(value)}
            onChangeStep={() => setNumStep(3)}
            onBackChangeStep={() => setNumStep(1)}
            paiementFactureUser={paiementFactureUser}
            functionTracks={functionTracks}
            handleConfirmPayment={handleConfirmPayment}
            clientSecret={clientSecret}
          />
        )}
      </div>
      <div
        className={`mt-[2rem] md:mt-0 ml-auto mx-auto sm:ml-0 sm:mt-4 sm:px-4 ${
          numStep === 1 ? '3xl:ml-[5.5rem]' : ''
        }`}
      >
        {/* <RecapComponentPaiementFacture
          phoneNumber={numeroADSL}
          objectReference={objectReference}
          operateur={operateur}
          montantEur={MontantEuro}
          montantTnd={montantDinar}
        /> */}
      </div>
    </div>
  )
}
