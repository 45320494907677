import BeneficiaryContext from 'contexts/beneficiaryContext'
import React, { useContext, useEffect } from 'react'
import recapIconBnf from '../assets/recapIconBnf.svg'
import recapIconTel from '../assets/recapIconTel.svg'
import recapIconRecharge from '../assets/recapIconRecharge.svg'
import recapIconMontant from '../assets/recapIconMontant.svg'
import RechargeContext from 'contexts/rechargeContext'

const RecapComponent: React.FC<{
  prefix?: string
  phoneNumber?: string
  operateur?: string
  montantEur?: string
  montantTnd?: string
  isChangePrefix?: boolean
}> = ({ prefix, phoneNumber, operateur, montantEur, montantTnd, isChangePrefix }) => {
  const { recharges, setRecharges } = useContext(RechargeContext)

  return (
    <div className="sm:hidden xs:hidden md:hidden px-4 sm:pt-[48px] py-[28px] sm:h-[473px] w-[375px] max-h-max sm:w-full rounded-lg shadow-lg">
      <div className="text-[20px] font-semibold">Synthèse</div>
      <div className="flex justify-between items-center mt-[25px]">
        <div className="flex items-center">
          <img src={recapIconBnf} /> <div className="text-[#474747] text-[15px] font-semibold pl-3">Bénéficiaire</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">
          {recharges?.phoneNumber &&
          ((recharges?.prefix === '+216' && recharges.phoneNumber.length > 8) ||
            (recharges?.prefix === '+212' && recharges.phoneNumber.length > 9))
            ? 'Numéro invalide'
            : prefix !== undefined && recharges?.phoneNumber !== undefined
              ? `${recharges?.prefix} ${recharges?.phoneNumber}`
              : '--'}
        </div>
      </div>

      <div className="flex justify-between items-center mt-[25px]">
        <div className="flex items-center">
          <img src={recapIconTel} />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">Opérateur Mobile</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">
          {recharges?.isOperateur === 'Orange'
            ? 'Orange Tunisie'
            : recharges?.isOperateur === 'Ooredoo'
              ? 'Ooredoo'
              : recharges?.isOperateur === 'Telecom'
                ? 'Tunisie Télécom'
                : recharges?.isOperateur === 'OrangeMaroc'
                  ? 'Orange Maroc'
                  : recharges?.isOperateur === 'TTMaroc'
                    ? 'Télécom Maroc'
                    : recharges?.isOperateur === 'Inwi'
                      ? 'Inwi'
                      : '--'}
        </div>
      </div>

      <div className="flex justify-between items-center mt-[25px]">
        <div className="flex items-center">
          <img src={recapIconRecharge} />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">Montant à payer</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">
          {recharges?.MontantEur ? `${recharges?.MontantEur} €` : '--'}
          {/* {montantEur ? `${recharges?.MontantEur} €` : '--'}  */}
        </div>
      </div>

      <div className="flex justify-between items-center mt-[25px]">
        <div className="flex items-center">
          <img src={recapIconMontant} />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">Recharge à envoyer</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">
          {recharges?.MontantDinar ? `${recharges?.MontantDinar}` : '--'}
          {/* {montantTnd ? `${recharges?.MontantDinar}` : '--'} */}
          {recharges?.prefix === '+216' ? `${' '} TND` : `${' '} MAD`}
        </div>
      </div>
    </div>
  )
}

export default RecapComponent
