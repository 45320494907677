export const getSenderCountries = () => {
    const countries = [
      {englishName: 'Allemagne', frenshName : 'Allemagne', flagName: 'de', avatar: '€', code: 'DEU', prefix:'+49'},
      {englishName: 'France', frenshName : 'France', flagName: 'fr', avatar: '€', code: 'FRA',prefix:'+33'},
      {englishName: 'Italie', frenshName : 'Italie', flagName: 'it', avatar: '€', code: 'ITA', prefix:'+39'},
      {englishName: 'Belgique', frenshName : 'Belgique', flagName: 'be', avatar: '€', code: 'BEL', prefix:'+32'},
      // {englishName: 'Suisse', frenshName : 'Suisse', flagName: 'ch', avatar: 'CHF', code: 'CHE', prefix:'41'},
      {englishName: 'Pays Bas', frenshName : 'Pays Bas', flagName: 'nl', avatar: '€', code: 'NLD', prefix:'+31'},
      {englishName: 'Espagne', frenshName : 'Espagne', flagName: 'es', avatar: '€', code: 'ESP', prefix:'+34'},
      {englishName: 'Portugal', frenshName : 'Portugal', flagName: 'pt', avatar: '€', code: 'PRT', prefix:'+351'},
      {englishName: 'Luxembourg', frenshName : 'Luxembourg', flagName: 'lu', avatar: '€', code: 'LUX', prefix:'+352'},
      {englishName: 'Grèce', frenshName : 'Grèce', flagName: 'gr', avatar: '€', code: 'GRC', prefix:'+30'},
      {englishName: 'Autriche', frenshName : 'Autriche', flagName: 'at', avatar: '€', code: 'AUT', prefix:'+43'},
      // {englishName: 'Danemark', frenshName : 'Danemark', flagName: 'dk', avatar: '€', code: 'DNK', prefix:'45'},
      // {englishName: 'Norvège', frenshName : 'Norvège', flagName: 'no', avatar: '€', code: 'NOR', prefix:'47'},
      // {englishName: 'Royaume-Uni', frenshName : 'Royaume-Uni', flagName: 'gb', code: '£', countryCode: 'GB', prefix:'44'},
      // {englishName: 'Suède', frenshName : 'Suède', flagName: 'se', avatar: '€', code: 'SWE', prefix:'46'},
      // {englishName: 'Malte', frenshName : 'Malte', flagName: 'mt', avatar: '€', code: 'MLT',  prefix:'356'},     
  
    ]
    return countries
  }

  export const getAllCountries = () => {
    const countries = [
      {englishName: 'France', frenshName: 'France', code : 'FRA'}, 
      {englishName: 'Tunisia', frenshName: 'Tunisie', code : 'TUN'}, 
      {englishName: 'South Africa', frenshName: 'Afrique du Sud', code : 'ZAF'}, 
      {englishName: 'Algeria', frenshName: 'Algérie', code : 'DZA'}, 
      {englishName: 'Allemagne', frenshName: 'Allemagne', code : 'DEU'}, 
      {englishName: 'Andorre', frenshName: 'Andorre', code : 'AND'}, 
      {englishName: 'Angola', frenshName: 'Angola', code : 'AGO'}, 
      {englishName: 'Arabie Saoudite', frenshName: 'Arabie Saoudite', code : 'SAU'}, 
      {englishName: 'Argentine', frenshName: 'Argentine', code : 'ARG'}, 
      {englishName: 'Arménie', frenshName: 'Arménie', code : 'ARM'}, 
      {englishName: 'Australie', frenshName: 'Australie', code : 'AUS'}, 
      {englishName: 'Autriche', frenshName: 'Autriche', code : 'AUT'}, 
      {englishName: 'Bahamas', frenshName: 'Bahamas', code : 'BHS'}, 
      {englishName: 'Bahrein', frenshName: 'Bahrein', code : 'BHR'}, 
      {englishName: 'Bangladesh', frenshName: 'Bangladesh', code : 'BGD'}, 
      {englishName: 'Bélarus', frenshName: 'Bélarus', code : 'BLR'}, 
      {englishName: 'Belgique', frenshName: 'Belgique', code : 'BEL'}, 
      {englishName: 'Bénin', frenshName: 'Bénin', code : 'BEN'}, 
      {englishName: 'Bolivie', frenshName: 'Bolivie', code : 'BMU'}, 
      {englishName: 'Bosnie-Herzégovine', frenshName: 'Bosnie-Herzégovine', code : 'BIH'}, 
      {englishName: 'Botswana', frenshName: 'Botswana', code : 'BWA'}, 
      {englishName: 'Brésil', frenshName: 'Brésil', code : 'BRA'}, 
      {englishName: 'Bulgarie', frenshName: 'Bulgarie', code : 'BGR'}, 
      {englishName: 'Burkina Faso', frenshName: 'Burkina Faso', code : 'BFA'}, 
      {englishName: 'Burundi', frenshName: 'Burundi', code : 'BDI'}, 
      {englishName: 'Cambodge', frenshName: 'Cambodge', code : 'KHM'}, 
      {englishName: 'Cameroun', frenshName: 'Cameroun', code : 'CMR'}, 
      {englishName: 'Canada', frenshName: 'Canada', code : 'CAN'}, 
      {englishName: 'Chili', frenshName: 'Chili', code : 'CHL'}, 
      {englishName: 'Chine', frenshName: 'Chine', code : 'CHN'}, 
      {englishName: 'Chypre', frenshName: 'Chypre', code : 'CYP'}, 
      {englishName: 'Colombie', frenshName: 'Colombie', code : 'COL'}, 
      {englishName: 'Comores', frenshName: 'Comores', code : 'COM'}, 
      {englishName: 'Congo', frenshName: 'Congo', code : 'COG'}, 
      {englishName: 'Costa Rica', frenshName: 'Costa Rica', code : 'CRI'}, 
      {englishName: "Côte d'Ivoire", frenshName: "Côte d'Ivoire", code : 'CIV'}, 
      {englishName: 'Croatie', frenshName: 'Croatie', code : 'HRV'}, 
      {englishName: 'Cuba', frenshName: 'Cuba', code : 'CUB'}, 
      {englishName: 'Danemark', frenshName: 'Danemark', code : 'DNK'}, 
      {englishName: 'Djibouti', frenshName: 'Djibouti', code : 'DJI'}, 
      {englishName: 'Dominicaine, République', frenshName: 'Dominicaine, République', code : 'DOM'}, 
      {englishName: 'Egypte', frenshName: 'Egypte', code : 'EGY'}, 
      {englishName: 'Emirats arabes unis', frenshName: 'Emirats arabes unis', code : 'ARE'}, 
      {englishName: 'Equateur', frenshName: 'Equateur', code : 'ECU'}, 
      {englishName: 'Erythrée', frenshName: 'Erythrée', code : 'ERI'}, 
      {englishName: 'Espagne', frenshName: 'Espagne', code : 'ESP'}, 
      {englishName: 'Estonie', frenshName: 'Estonie', code : 'EST'}, 
      {englishName: "Etats-Unis d'Amérique", frenshName: "Etats-Unis d'Amérique", code : 'USA'}, 
      {englishName: 'Ethiopie', frenshName: 'Ethiopie', code : 'ETH'}, 
      {englishName: 'Finlande', frenshName: 'Finlande', code : 'FIN'},
      {englishName: 'Gabon', frenshName: 'Gabon', code : 'GAB'}, 
      {englishName: 'Gambie', frenshName: 'Gambie', code : 'GMB'}, 
      {englishName: 'Géorgie', frenshName: 'Géorgie', code : 'GEO'}, 
      {englishName: 'Ghana', frenshName: 'Ghana', code : 'GHA'}, 
      {englishName: 'Grèce', frenshName: 'Grèce', code : 'GRC'}, 
      {englishName: 'Guadeloupe', frenshName: 'Guadeloupe', code : 'GLP'}, 
      {englishName: 'Guatemala', frenshName: 'Guatemala', code : 'GTM'}, 
      {englishName: 'Guinée-Bissau', frenshName: 'Guinée-Bissau', code : 'GNB'}, 
      {englishName: 'Guinée équatoriale', frenshName: 'Guinée équatoriale', code : '	GIN'}, 
      {englishName: 'Guyane française', frenshName: 'Guyane française', code : '	GUF'}, 
      {englishName: 'Haïti', frenshName: 'Haïti', code : 'HTI'}, 
      {englishName: 'Honduras', frenshName: 'Honduras', code : 'HND'}, 
      {englishName: 'Hong Kong', frenshName: 'Hong Kong', code : 'HKG'}, 
      {englishName: 'Hongrie', frenshName: 'Hongrie', code : 'HUN'}, 
      {englishName: 'Indonésie', frenshName: 'Indonésie', code : 'IDN'}, 
      {englishName: 'Iraq', frenshName: 'Iraq', code : 'IRQ'}, 
      {englishName: 'Irlande', frenshName: 'Irlande', code : 'IRL'}, 
      {englishName: 'Islande', frenshName: 'Islande', code : 'ISL'}, 
      {englishName: 'Italie', frenshName: 'Italie', code : 'ITA'}, 
      {englishName: 'Jamaïque', frenshName: 'Jamaïque', code : 'JAM'}, 
      {englishName: 'Japon', frenshName: 'Japon', code : 'JPN'}, 
      {englishName: 'Jordanie', frenshName: 'Jordanie', code : 'JOR'}, 
      {englishName: 'Kenya', frenshName: 'Kenya', code : 'KEN'}, 
      {englishName: 'Koweït', frenshName: 'Koweït', code : 'KWT'}, 
      {englishName: 'Lettonie', frenshName: 'Lettonie', code : 'LVA'}, 
      {englishName: 'Liban', frenshName: 'Liban', code : 'LBN'}, 
      {englishName: 'Libéria', frenshName: 'Libéria', code : 'LBR'}, 
      {englishName: 'Libye', frenshName: 'Libye', code : 'LBY'}, 
      {englishName: 'Lituanie', frenshName: 'Lituanie', code : 'LTU'}, 
      {englishName: 'Luxembourg', frenshName: 'Luxembourg', code : 'LUX'}, 
      {englishName: 'Madagascar', frenshName: 'Madagascar', code : 'MDG'}, 
      {englishName: 'Malaisie', frenshName: 'Malaisie', code : 'MYS'},
      {englishName: 'Malawi', frenshName: 'Malawi', code : 'MWI'}, 
      {englishName: 'Maldives', frenshName: 'Maldives', code : 'MDV'}, 
      {englishName: 'Mali', frenshName: 'Mali', code : 'MLI'}, 
      {englishName: 'Malte', frenshName: 'Malte', code : 'MLT'}, 
      {englishName: 'Maroc', frenshName: 'Maroc', code : 'MAR'}, 
      {englishName: 'Mauritanie', frenshName: 'Mauritanie', code : 'MRT'}, 
      {englishName: 'Mexique', frenshName: 'Mexique', code : 'MEX'},
      {englishName: 'Monaco', frenshName: 'Monaco', code : 'MCO'}, 
      {englishName: 'Monténégro', frenshName: 'Monténégro', code : 'MNE'}, 
      {englishName: 'Mozambique', frenshName: 'Mozambique', code : 'MOZ'}, 
      {englishName: 'Namibie', frenshName: 'Namibie', code : 'NAM'}, 
      {englishName: 'Népal', frenshName: 'Népal', code : 'NPL'}, 
      {englishName: 'Nicaragua', frenshName: 'Nicaragua', code : 'NIC'}, 
      {englishName: 'Niger', frenshName: 'Niger', code : 'NER'},
      {englishName: 'Nigéria', frenshName: 'Nigéria', code : 'NGA'}, 
      {englishName: 'Norvège', frenshName: 'Norvège', code : 'NOR'},
      {englishName: 'Nouvelle-Calédonie', frenshName: 'Nouvelle-Calédonie', code : 'NCL'}, 
      {englishName: 'Nouvelle-Zélande', frenshName: 'Nouvelle-Zélande', code : 'NZL'}, 
      {englishName: 'Oman', frenshName: 'Oman', code : 'OMN'}, 
      {englishName: 'Ouganda', frenshName: 'Ouganda', code : 'UGA'}, 
      {englishName: 'Palestine', frenshName: 'Palestine', code : 'PSE'}, 
      {englishName: 'Paraguay', frenshName: 'Paraguay', code : 'PRY'}, 
      {englishName: 'Pays-Bas', frenshName: 'Pays-Bas', code : 'NLD'},
      {englishName: 'Pérou', frenshName: 'Pérou', code : 'PER'}, 
      {englishName: 'Philippines', frenshName: 'Philippines', code : 'PHL'}, 
      {englishName: 'Pologne', frenshName: 'Pologne', code : 'POL'}, 
      {englishName: 'Porto Rico', frenshName: 'Porto Rico', code : 'PRI'}, 
      {englishName: 'Portugal', frenshName: 'Portugal', code : 'PRT'}, 
      {englishName: 'Qatar', frenshName: 'Qatar', code : 'QAT'}, 
      {englishName: 'Roumanie', frenshName: 'Roumanie', code : 'ROU'},
      {englishName: 'Royaume-Uni', frenshName: 'Royaume-Uni', code : 'GBR'}, 
      {englishName: 'Russie', frenshName: 'Russie', code : 'RUS'},
      {englishName: 'Rwanda', frenshName: 'Rwanda', code : 'RWA'}, 
      {englishName: 'Sénégal', frenshName: 'Sénégal', code : 'SEN'},
      {englishName: 'Serbie', frenshName: 'Serbie', code : 'SRB'}, 
      {englishName: 'Seychelles', frenshName: 'Seychelles', code : 'SYC'},
      {englishName: 'Sierra Leone', frenshName: 'Sierra Leone', code : 'SLE'}, 
      {englishName: 'Singapour', frenshName: 'Singapour', code : 'SGP'},
      {englishName: 'Slovaquie', frenshName: 'Slovaquie', code : 'SVK'}, 
      {englishName: 'Slovénie', frenshName: 'Slovénie', code : 'SVN'},
      {englishName: 'Somalie', frenshName: 'Somalie', code : 'SOM'}, 
      {englishName: 'Soudan', frenshName: 'Soudan', code : 'SDN'},
      {englishName: 'Sri Lanka', frenshName: 'Sri Lanka', code : 'LKA'}, 
      {englishName: 'Suède', frenshName: 'Suède', code : 'SWE'},
      {englishName: 'Suisse', frenshName: 'Suisse', code : 'CHE'}, 
      {englishName: 'Tchad', frenshName: 'Tchad', code : 'TCD'},
      {englishName: 'Thaïlande', frenshName: 'Thaïlande', code : 'THA'}, 
      {englishName: 'Togo', frenshName: 'Togo', code : 'TGO'},
      {englishName: 'Turquie', frenshName: 'Turquie', code : 'TUR'}, 
      {englishName: 'Ukraine', frenshName: 'Ukraine', code : 'UKR'},
      {englishName: 'Uruguay', frenshName: 'Uruguay', code : 'URY'}, 
      {englishName: 'Venezuela', frenshName: 'Venezuela', code : 'VEN'},
      {englishName: 'Viet Nam', frenshName: 'Viet Nam', code : 'VNM'}, 
      {englishName: 'Yémen', frenshName: 'Yémen', code : 'YEM'},
      {englishName: 'Zambie', frenshName: 'Zambie', code : 'ZMB'}, 
      {englishName: 'Zimbabwe', frenshName: 'Zimbabwe', code : 'ZWE'},
    ]
    return countries
  }