import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaiementForm from './paiementForm'

interface props {
    handleConfirmPayment?:any
    clientSecret?: string
    functionTracks:any
}
const Paiement: React.FC<props> = ({ handleConfirmPayment, clientSecret, functionTracks }) => {
    const stripePromise = loadStripe(
        'pk_test_51OzdEkP1vfmwjObRWZs9jeUgtjVr5yPKXnOEtrKZbJdd7boKLUA7KP6VVEmXkqJJegtrebeTm3z9plymQavS3ZwL009Vrh8Udc',
      )
  return (
    <div className="sm:mt-5 xs:mt-5 5xl:flex 5xl:justify-center xs:flex xs:justify-center">
    <Elements stripe={stripePromise}> 
        <PaiementForm 
          handleConfirmPayment="" 
          clientSecret=""
          functionTracks={functionTracks}
        /> 
      </Elements> 
  </div>
  )
}
export default Paiement
