import axios from '../helpers/axios'

export const RechargeSherlockSecure = (token: string | undefined, body: any) =>
  axios
    .post(`/recharges/sherlockSecure`, body, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
})
