import * as React from 'react'
import PropTypes from 'prop-types'

function HistoriqueIcon({
  fill = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bill-4--accounting-billing-payment-finance-cash-currency-money-bill-dollar-stack">
        <path
          id="Vector"
          d="M18.1426 3H3.57122C2.62445 3 1.85693 3.76751 1.85693 4.71429V15C1.85693 15.9468 2.62445 16.7143 3.57122 16.7143H18.1426C19.0894 16.7143 19.8569 15.9468 19.8569 15V4.71429C19.8569 3.76751 19.0894 3 18.1426 3Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7 21.0001H22.4286C22.8832 21.0001 23.3193 20.8195 23.6408 20.498C23.9622 20.1765 24.1429 19.7404 24.1429 19.2858V10.7144"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="Group 8">
          <path
            id="Ellipse 1"
            d="M5.32847 10.04C5.09178 10.04 4.8999 9.84813 4.8999 9.61143C4.8999 9.37474 5.09178 9.18286 5.32847 9.18286"
            stroke={fill}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Ellipse 2"
            d="M5.32875 10.04C5.56545 10.04 5.75732 9.84813 5.75732 9.61143C5.75732 9.37474 5.56545 9.18286 5.32875 9.18286"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Group 9">
          <path
            id="Ellipse 1_2"
            d="M16.3861 10.04C16.1494 10.04 15.9575 9.84813 15.9575 9.61143C15.9575 9.37474 16.1494 9.18286 16.3861 9.18286"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Ellipse 2_2"
            d="M16.3864 10.04C16.6231 10.04 16.8149 9.84813 16.8149 9.61143C16.8149 9.37474 16.6231 9.18286 16.3864 9.18286"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          id="Vector_3"
          d="M10.8572 12.0001C12.0407 12.0001 13.0001 11.0407 13.0001 9.85721C13.0001 8.67375 12.0407 7.71436 10.8572 7.71436C9.67375 7.71436 8.71436 8.67375 8.71436 9.85721C8.71436 11.0407 9.67375 12.0001 10.8572 12.0001Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
HistoriqueIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default HistoriqueIcon
