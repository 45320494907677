import React, { useState } from 'react'
import imageDefault from '../../assets/imageDefault.jpeg'
import newDefaultImageFemme from '../../assets/newDefaultImageFemme.svg'
import newDefaultImageHomme from '../../assets/newDefaultImageHomme.svg'
import ModalHistoryTransfert from './modalHistoryTransfert'

interface Props {
  transactionData: any
}

const HistoryTable: React.FC<Props> = ({ transactionData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [status, setStatus] = useState<string>('complete')
  const [statusCanceled, setStatusCanceled] = useState<string>('canceled')
  const [statusInProgress, setStatusInProgress] = useState<string>('in progress')

  const [modalHistoryTransfert, setModalHistoryTransfert] = useState(false)
  const [indexTransaction, setIndexTransaction] = useState(-1)

  const closeModalHistoryTransfert = () => {
    setModalHistoryTransfert(false)
    setIndexTransaction(-1)
  }

  function formatDate(originalDate: any) {
    const dateObj = new Date(originalDate)
    const jour = dateObj.getDate()
    const mois = dateObj.getMonth() + 1
    const annee = dateObj.getFullYear()
    const jourFormatte = jour < 10 ? `0${jour}` : jour
    const moisFormatte = mois < 10 ? `0${mois}` : mois
    return `${jourFormatte}/${moisFormatte}/${annee}`
  }

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
    setIndexTransaction(-1)
  }

  const [clickedRows, setClickedRows] = useState<boolean[]>([])

  const handleInputClick = (index: number) => {
    const newClickedRows = [...clickedRows]
    newClickedRows[index] = !newClickedRows[index]
    setClickedRows(newClickedRows)
  }

  return (
    <div className="mx-auto mt-[2rem] 2xl:mt-5 mr-[5rem] sm:mr-0 lg:mr-1 sm:mt-[1rem] w-auto">
      <div className="table-container">
        <table className="table-auto w-full flex1 text-left">
          <thead>
            <tr>
              <th className="border-b px-4 py-2 2xl:px-3 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[15px] 2xl:text-[13px] lg:text-[13px] text-[#939393]">
                  Bénéficiaire
                </div>
              </th>
              <th className="border-b px-4 py-2 2xl:px-3 sm:border-none sm:hidden">
                <div className="font-semibold text-[14px] sm:text-[15px] 2xl:text-[13px] lg:text-[13px] text-[#939393]">
                  Référence
                </div>
              </th>
              <th className="border-b px-4 py-2 2xl:px-3 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[15px] 2xl:text-[13px] lg:text-[13px] text-[#939393]">
                  Montant envoyé
                </div>
              </th>
              <th className="border-b px-4 py-2 2xl:px-3 sm:border-none sm:hidden">
                <div className="font-semibold text-[14px] sm:text-[15px] 2xl:text-[13px] lg:text-[13px] text-[#939393]">
                  Date
                </div>
              </th>
              <th className="border-b px-4 py-2 2xl:px-3 sm:border-none sm:hidden">
                <div className="font-semibold text-[14px] sm:text-[15px] 2xl:text-[13px] lg:text-[13px] text-[#939393]">
                  Points de collecte
                </div>
              </th>
              <th className="border-b px-4 py-2 2xl:px-3 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[15px] 2xl:text-[13px] lg:text-[13px] text-[#939393]">
                  Statut
                </div>
              </th>
              {/* <th className="border-b px-4 py-2 2xl:px-3 sm:border-none">
                <div className="font-semibold text-[14px] sm:text-[15px] 2xl:text-[13px] lg:text-[13px] text-[#939393] sm:hidden">
                  Reçu
                </div>
              </th> */}
            </tr>
          </thead>
          {transactionData &&
            transactionData.map((listTransaction: any, index: number) => (
              <React.Fragment key={index}>
                <tbody
                  key={index}
                  //onClick={() => setIndexTransaction(index)}
                >
                  <tr className="hover:bg-gray-100 hover:cursor-pointer">
                    <td className={`border-b px-4 lg:px-2 py-2`} onClick={() => handleInputClick(index)}>
                      <div className="flex items-center">
                        <img
                          src={
                            listTransaction?.recipient?.avatar || listTransaction?.personalInfo?.gender === 'female'
                              ? newDefaultImageFemme
                              : newDefaultImageHomme
                          }
                          className="w-8 h-8 rounded-full object-cover sm:w-[35px] sm:h-[35px]"
                        />
                        <div className="ml-2 sm:pr-2">
                          <div className="text-[#22242C] text-[14px] sm:text-[13px] lg:text-[13px] font-semibold">
                            <span>
                              {listTransaction?.recipient?.personalInfo?.firstName}{' '}
                              {listTransaction?.recipient?.personalInfo?.lastName}
                            </span>
                          </div>
                          <div className="text-[#8E8EA1] text-[11px] sm:text-[10px] lg:text-[9px] font-normal">
                            <span>
                              {listTransaction?.recipient?.personalInfo?.phone?.prefix.startsWith('+')
                              ? listTransaction?.recipient?.personalInfo?.phone?.prefix
                              : `${'+'}${listTransaction?.recipient?.personalInfo?.phone?.prefix}`
}
                              {listTransaction?.recipient?.personalInfo?.phone?.number}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="border-b px-4 py-2 sm:hidden">
                      <div className="text-[#22242C] text-[14px] lg:text-[13px] 2md:text-[10px] font-semibold">
                        {listTransaction?.partnerCode}
                      </div>
                    </td>
                    <td className="border-b px-4 py-2">
                      <div className="text-[#22242C] text-[16px] sm:pl-2 sm:tetx-[14px] font-semibold">
                        {listTransaction?.amount?.amountToSend} {listTransaction?.amount?.departureCurrency?.avatar}
                      </div>
                    </td>
                    <td className="border-b px-4 py-2 sm:hidden">
                      <div className="text-[#22242C] text-[14px] lg:text-[13px] font-semibold">
                        {formatDate(listTransaction?.createdAt)}
                      </div>
                    </td>

                    <td className="border-b px-4 py-2 sm:hidden">
                      <div
                        className="text-[#22242C] text-[14px] lg:text-[10px] font-semibold"
                        title={listTransaction?.partner?.storeInfo?.name}
                      >
                        {listTransaction?.partner?.storeInfo?.name &&
                        listTransaction.partner?.storeInfo?.name?.length > 18
                          ? `${listTransaction.partner?.storeInfo?.name?.slice(0, 18)}...`
                          : listTransaction.partner?.storeInfo?.name}
                      </div>
                    </td>

                    <td className="border-b ">
                      {status === listTransaction?.status ? (
                        <div className="bg-[#EBFFF3] flex items-center justify-center w-auto p-2 sm:p-[5px] text-[14px] sm:text-[12px]  rounded-lg">
                          <span className="text-[#20C374] ml-1">Terminé</span>
                        </div>
                      ) : statusInProgress === listTransaction?.status ? (
                        <div className="bg-[#FFDECC] flex items-center justify-center w-auto p-2 h-[35px] 2md:h-[22px] text-[14px] sm:text-[12px] rounded-lg">
                          <span className="text-[#FF5C00]">En cours</span>
                        </div>
                      ) : statusCanceled === listTransaction?.status ? (
                        <div className="bg-[#FED7D8] flex items-center justify-center w-auto p-2 h-[35px] 2md:h-[22px] text-[14px] sm:text-[12px] rounded-lg">
                          <span className="text-[#EC1C24]">Annulé</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                    {/* <td className="border-b px-4 py-2">
                      <div className="flex ml-4 sm:ml-0">
                        <img
                          src={iconPrint}
                          className="w-5 h-5 mr-5 cursor-pointer sm:hidden"
                          onClick={() => {
                            setIndexTransaction(index)
                            //openModal()
                          }}
                        />
                      </div>
                    </td> */}
                  </tr>
                  {/* <ModalPrintHistory isOpen={index === indexTransaction} closeModal={closeModal} list={listTransaction} /> */}
                </tbody>
                {/* <ModalHistoryTransfert
                  isOpen={index === indexTransaction}
                  closeModal={closeModalHistoryTransfert}
                  beneficiaire={`${listTransaction?.recipient?.personalInfo?.firstName} ${' '} ${listTransaction
                    ?.recipient?.personalInfo?.lastName}`}
                  montantEnvoye={listTransaction?.amount?.amountToSend}
                  pointsCollecte={listTransaction?.partner?.storeInfo?.name}
                  date={formatDate(listTransaction?.createdAt)}
                  distributionChannel={listTransaction?.distributionChannel}
                /> */}
              </React.Fragment>
            ))}
        </table>
      </div>
    </div>
  )
}

export default HistoryTable
