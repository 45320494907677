import * as React from 'react'
import PropTypes from 'prop-types'

function TransfertCompteBancaireIcon({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2619 1.5959L13.2274 1.67641L9.89218 9.41652H6.61442C5.83236 9.41652 5.0848 9.57753 4.40625 9.86505L6.41891 5.05768L6.46491 4.94267L6.54542 4.75865C6.56842 4.68965 6.59142 4.62064 6.62593 4.56314C8.13254 1.07836 9.83468 0.2848 13.2619 1.5959Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4594 9.64658C18.9418 9.48557 18.3898 9.41656 17.8377 9.41656H9.89062L13.2259 1.67645L13.2604 1.59595C13.4329 1.65345 13.5939 1.73396 13.7664 1.80296L16.3081 2.87255C17.7227 3.45909 18.7118 4.06864 19.3099 4.8047C19.4249 4.94271 19.5169 5.06922 19.5974 5.21873C19.7009 5.37975 19.7814 5.54076 19.8274 5.71327C19.8734 5.81678 19.9079 5.92029 19.9309 6.0123C20.2414 6.97837 20.0574 8.16297 19.4594 9.64658Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4519 15.0293V17.272C23.4519 17.502 23.4404 17.732 23.4289 17.962C23.2104 21.9759 20.9677 24 16.7124 24H7.7417C7.46568 24 7.18966 23.977 6.92514 23.9425C3.26785 23.701 1.3127 21.7458 1.07118 18.0886C1.03667 17.824 1.01367 17.548 1.01367 17.272V15.0293C1.01367 12.7176 2.41678 10.728 4.41794 9.86541C5.10799 9.57788 5.84405 9.41687 6.62611 9.41687H17.851C18.4145 9.41687 18.9666 9.49738 19.4726 9.64689C21.7613 10.3484 23.4519 12.4876 23.4519 15.0293Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41693 5.05762L4.40427 9.865C2.40311 10.7276 1 12.7172 1 15.0289V11.6591C1 8.39288 3.32318 5.66717 6.41693 5.05762Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4481 11.6586V15.0284C23.4481 12.4982 21.7689 10.3475 19.4688 9.65746C20.0668 8.16234 20.2393 6.98925 19.9518 6.01167C19.9288 5.90816 19.8943 5.80465 19.8483 5.71265C21.9874 6.81673 23.4481 9.08241 23.4481 11.6586Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
TransfertCompteBancaireIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default TransfertCompteBancaireIcon
