import React, { useEffect, useState } from 'react'
import iconSelectInput from '../../assets/iconSelectInput.svg'
import classNames from 'classnames'
import checkImg from '../../assets/checkImg.svg'
import habittation from '../../assets/habittation.svg'
import maghrebia from '../../assets/maghrebia.svg'

import { io, Socket } from 'socket.io-client'
// Define the type for your socket state
type SocketType = Socket | null

interface props {
  produitAssurance: string
  setProduitAssurance: (value: string) => void
  partenaireAssurance: string
  setPartenaireAssurance: (value: string) => void
  setNumStep: any
  functionTracks: any
}
const Produit: React.FC<props> = ({
  produitAssurance,
  setProduitAssurance,
  partenaireAssurance,
  setPartenaireAssurance,
  setNumStep,
  functionTracks,
}) => {
  const [socket, setSocket] = useState<SocketType>(null)

  const [isOpenlistProduit, setIsOpenlistProduit] = useState(false)

  const [isOpenlistPartenaire, setIsOpenlistPartenaire] = useState(false)

  const [isAssurance, setIsAssurance] = useState(false)

  const handleSelectProduit = (produit: any) => {
    setProduitAssurance(produit)
  }

  const handleSelectPartenaire = (partenaire: any) => {
    setPartenaireAssurance(partenaire)
  }

  // useEffect(() => {
  //   const socketInstance = io('https://app-dev.api.myeasytransfer.com')
  //   setSocket(socketInstance)
    
  //   socketInstance.on('connect', () => {
  //     console.log('Connected to server')
  //   })
  
  //   socketInstance.on('message', (data) => {
  //     console.log(`Received message: ${data}`)
  //   })
  
  //   return () => {
  //     if (socketInstance) {
  //       socketInstance.disconnect()
  //     }
  //   }
  // }, [])


  return (
    <div>
      <div className="md:text-[25px] text-left md:ml-0 md:mr-[35px] sm:mr-0 sm:ml-5 text-[#4743C9] text-[35px] font-semibold sm:mt-4 ">
        Souscription
      </div>

      <div className="relative h-[120px] mt-10 sm:mt-5 sm:ml-5">
        <div className="font-bold text-[18px] text-[#1D3A70]">
          Sélectionnez le produit d’assurance <span className="text-red-500">*</span>
        </div>

        <div className="relative items-center inline-block mt-3 w-full">
          <div className="text-[#1D3A70] text-[15px] font-semibold p-2 bg-[#F9FAFB] rounded-xl h-[56px]">
            <div className="flex items-center p-1">
              <img src={habittation} className="w-8 h-8 ml-3" />
              <div className="font-bold text-[18px] text-[#0C266C] pl-5">Assurance habitation</div>
            </div>
          </div>
          {/* <input
            type="text"
            className={`p-2 bg-[#F9FAFB] font-bold text-[18px] pl-[55px] sm:h-[55px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#6B7280]`}
            onClick={() => {
              setIsOpenlistProduit(!isOpenlistProduit)
            }}
            placeholder="Choisir"
            value={produitAssurance}
            name="produitAssurance"
            readOnly
          />
          {produitAssurance && (
            <img src={habittation} className="w-8 h-8 ml-3 absolute top-1/2 transform -translate-y-1/2" />
          )}
          <div
            className="absolute top-0 right-0 mt-[15px] mx-3"
            onClick={() => setIsOpenlistProduit(!isOpenlistProduit)}
          >
            <img src={iconSelectInput} className="h-6 w-6" />
          </div>

          <div
            className={`absolute top-full left-0 z-10 min-h-auto rounded-lg max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
              isOpenlistProduit ? 'block' : 'hidden'
            }`}
          >
            <div
              className="p-2 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                handleSelectProduit('Assurance habitation')
                setIsOpenlistProduit(false)
              }}
            >
              <div className="text-[#1D3A70] text-[15px] font-semibold">
                <div className="flex items-center p-1">
                  <img src={habittation} className="w-8 h-8 ml-3" />
                  <div className="font-bold text-[18px] text-[#0C266C] pl-5">Assurance habitation</div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className={`relative h-[120px] mt-10 sm:mt-5 sm:ml-5 ${produitAssurance === '' ? 'hidden' : 'block'}`}> */}

      <div className={`relative h-[120px] mt-10 sm:mt-5 sm:ml-5`}>
        <div className="font-bold text-[18px] text-[#1D3A70]">
          Assurance Partenaire <span className="text-red-500">*</span>
        </div>
        <div className="flex items-center mt-3 p-2 w-full border-[#5653E4] border-2 rounded-xl">
          <img src={maghrebia} className="w-8 h-8 ml-3" />
          <div className="font-bold text-[18px] text-[#0C266C] pl-5">Maghrebia</div>
          <img src={checkImg} className="w-6 h-6 ml-3" />
        </div>
        {/* <div className="relative items-center inline-block mt-3 w-full">
          <input
            type="text"
            className="p-2 bg-[#F9FAFB] font-bold pl-[55px] text-[18px] sm:h-[55px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#6B7280]"
            onClick={() => {
              if (produitAssurance) {
                setIsOpenlistPartenaire(!isOpenlistPartenaire)
              }
            }}
            placeholder="Choisir"
            value={partenaireAssurance}
            name="partenaireAssurance"
            readOnly
          />
          {partenaireAssurance && (
            <img src={maghrebia} className="w-8 h-8 ml-3 absolute top-1/2 transform -translate-y-1/2" />
          )}
          <div
            className="absolute top-0 right-0 mt-[15px] mx-3"
            onClick={() => {
              if (produitAssurance) {
                setIsOpenlistPartenaire(!isOpenlistPartenaire)
              }
            }}
          >
            <img src={iconSelectInput} className="h-6 w-6" />
          </div>

          <div
            className={`absolute top-full left-0 z-10 min-h-auto rounded-lg max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
              isOpenlistPartenaire && produitAssurance ? 'block' : 'hidden'
            }`}
          >
            <div
              className="p-2 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                handleSelectPartenaire('Maghrebia')
                setIsOpenlistPartenaire(false)
              }}
            >
              <div className="text-[#1D3A70] text-[15px] font-semibold">
                <div className="flex items-center mt-3 p-1">
                  <img src={maghrebia} className="w-8 h-8 ml-3" />
                  <div className="font-bold text-[18px] text-[#0C266C] pl-5">Maghrebia</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="flex gap-[16px] mt-[25px] justify-center w-full h-[56px] sm:px-4 ">
        <button
          className={classNames(
            // produitAssurance === ''
            //   ? 
            //     'bg-[#A5A5A5] hover:cursor-not-allowed'
            //   : 'bg-[#4743C9] hover:cursor-pointer',
            'text-[18px] text-[#FFF] bg-[#4743C9] hover:cursor-pointer px-[28px] py-[16px] md:my-0 rounded-lg w-[375px] sm:w-full ',
          )}
          onClick={() => {
            setNumStep(1)
            functionTracks(1)
          }}
          // disabled={
          //   produitAssurance === ''
          //     ? 
          //       true
          //     : false
          // }
        >
          Suivant
        </button>
      </div>
    </div>
  )
}
export default Produit
