import axios from '../helpers/axios'

export const AddTransaction = (token: any, data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/transfers/SDK`, data, {
        headers: {
          authorization: token,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const ValidateTransfer = (token: any, data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/transfers/validatePayment`, data, {
        headers: {
          authorization: token,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const TransactionsList = (id: string | undefined, token: string | undefined) =>
  axios
    .get(`/transfers/${id}`, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })

export const RechargeList = (id: string | undefined, token: string | undefined) =>
  axios
    .get(`/recharges/${id}`, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })

export const RechargeMobile = (id: string | undefined, token: string | undefined, body: any) =>
  axios
    .post(`/recharges/createRecharge`, body, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})

export const Products = (id: string | undefined, token: string | undefined, countryIsoCode: string) =>
  axios
    .get(`/products?country=${countryIsoCode}`, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})


  export const VerifAccount = (id: string | undefined, token: string | undefined) =>
    axios
      .get(`users/${id}/kyc-checks/document`, {
        headers: {
          Authorization: token,
          accept: 'application/json',
        },
      })
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        console.log(error)
  })   


  export const updateProfile = (id: string | undefined, token: string | undefined) =>
    axios
      .get(`users/${id}`, {
        headers: {
          Authorization: token,
          accept: 'application/json',
        },
      })
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        console.log(error)
  })   


  export const SearchPromoCodes = (body: any) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/promos/search-promo', body, {
          headers: {
            accept: 'application/json',
          },
        })
        .then(response => {
          resolve(response?.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  export const tracks = (body: any) =>
  axios
    .post(`/tracks/track`, body, {
      headers: {
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})

export const getListByCountry = (country: string | undefined, amount: number | undefined) =>
    axios
      .get(`/partners/listByCountry?amount=${amount}&country=${country}`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        console.log(error)
  })   

  export const getListByGovernorate = (country: string | undefined, amount: number | undefined, governorate: string | undefined) =>
    axios
      .get(`/partners/listByGovernorate?amount=${amount}&country=${country}&governorate=${governorate}`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        console.log(error)
  })
  
  export const getListByCity = (country: string | undefined, amount: number | undefined, governorate: string | undefined, city: string | undefined) =>
  axios
    .get(`/partners/listByCity?amount=${amount}&country=${country}&governorate=${governorate}&city=${city}`, {
      headers: {
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})