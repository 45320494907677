import React, { useContext } from 'react'
import classNames from 'classnames'
import btnDeleted from '../../assets/btnDeleted.svg'
import { useNavigate } from 'react-router-dom'
import UserContext from 'contexts/UserContext'
import localforage from 'localforage'
import logOutImage from '../../assets/logOutImage.svg'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

interface Props {
  isOpen: boolean
  closeModal: () => void
  openModalLogOut: boolean
}

const ModalLogOutContainer: React.FC<Props> = ({ isOpen, closeModal, openModalLogOut }) => {
  if (!isOpen) return null
  const navigate = useNavigate()
  const { setUser } = useContext(UserContext)
  const { setTransferData } = useContext(transferBeneficiaryContext)

  return (
    <div>
      {openModalLogOut && (
        <div
          className={classNames(
            'fixed justify-center items-center flex flex-col inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm',
          )}
        >
          <div
            className={classNames(
              'rounded-xl relative py-3 xl:py-1 flex flex-col w-[373px] sm:w-[350px] bg-image-bg-beneficiary bg-cover bg-no-repeat',
            )}
          >
            <div className="p-5 flex flex-col text-center">
              <div className="font-bold text-[19px] text-[#1D3A70] mt-8">Déconnexion</div>
              <div className="text-[14px] font-normal text-[#6B7280] mt-5">
                Etes-vous sûr de vouloir vous déconnecter ?
              </div>
              <div className="flex justify-center mt-8">
                <button
                  className="text-white text-[16px] font-semibold bg-[#4743C9] p-4 rounded-xl"
                  onClick={() => {
                    localforage.clear()
                    setUser(null)
                    setTransferData(null)
                    closeModal()
                    navigate('/login')
                  }}
                >
                  Je confirme
                </button>
                <button
                  className="text-white text-[16px] font-semibold bg-[#F00] p-4 rounded-xl ml-3"
                  onClick={closeModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
          <div className="absolute 4xl:top-[33%] xs:top-[32%]  2xs:top-[31%] sm:top-[41%] mxm:top-[33%] transform -translate-x-1/2 -translate-y-1/2 left-1/2">
            <div className="relative">
              <img
                className="w-24 h-24 object-cover rounded-full shadow-xl border-white border-[5px]"
                src={logOutImage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ModalLogOutContainer
