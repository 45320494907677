import React, { useContext } from 'react'
import UserContext from 'contexts/UserContext'
import SettingProfileFirstPart from './SettingProfileContainer/settingProfileFirstPart'
import SettingProfileSecondPart from './SettingProfileContainer/settingProfileSecondPart'
import { useParams } from 'react-router-dom'

const SettingProfile = () => {

const code  = useParams()?.code

return (
    <div>
      <div className="flex items-start justify-around sm:justify-center sm:items-center sm:flex-col xl:gap-3">
        <SettingProfileFirstPart />
        <SettingProfileSecondPart code={code}/>
      </div>
    </div>
  )
}

export default SettingProfile
