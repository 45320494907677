import * as React from 'react'
import PropTypes from 'prop-types'

function BeneficiaryIcon({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.57087 11.1428C11.9378 11.1428 13.8566 9.22406 13.8566 6.85713C13.8566 4.49019 11.9378 2.57141 9.57087 2.57141C7.20394 2.57141 5.28516 4.49019 5.28516 6.85713C5.28516 9.22406 7.20394 11.1428 9.57087 11.1428Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.85742 23.1428H9.57171H17.286V22.2134C17.2723 20.9068 16.9277 19.6249 16.2844 18.4876C15.641 17.3502 14.7199 16.3945 13.607 15.7096C12.4941 15.0248 11.2258 14.6332 9.9206 14.5714C9.80423 14.5659 9.6879 14.563 9.57171 14.5627C9.45551 14.563 9.33919 14.5659 9.22282 14.5714C7.91757 14.6332 6.64928 15.0248 5.53641 15.7096C4.42354 16.3945 3.5024 17.3502 2.85903 18.4876C2.21567 19.6249 1.87107 20.9068 1.85742 22.2134V23.1428Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4277 11.1428C18.7947 11.1428 20.7134 9.22406 20.7134 6.85713C20.7134 4.49019 18.7947 2.57141 16.4277 2.57141"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.714 23.1428H24.1426V22.2134C24.1289 20.9068 23.7843 19.6249 23.141 18.4876C22.4976 17.3502 21.5765 16.3945 20.4636 15.7097C19.7442 15.267 18.9599 14.9468 18.1426 14.759"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
BeneficiaryIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default BeneficiaryIcon
