import * as React from 'react'
import PropTypes from 'prop-types'

function PaimentFactureIcon({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7292 20.2858C19.7292 20.695 19.5667 21.0874 19.2773 21.3767C18.988 21.6661 18.5956 21.8286 18.1864 21.8286H4.30067C3.89148 21.8286 3.49905 21.6661 3.2097 21.3767C2.92036 21.0874 2.75781 20.695 2.75781 20.2858V3.31434C2.75781 2.90515 2.92036 2.51272 3.2097 2.22338C3.49905 1.93403 3.89148 1.77148 4.30067 1.77148H14.3292L19.7292 7.17148V20.2858Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5586 1.77148V7.94291H19.73"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4438 18.295C11.1281 18.4314 10.7877 18.5012 10.4438 18.5C9.682 18.465 8.96505 18.1297 8.44972 17.5676C7.93439 17.0054 7.66262 16.262 7.69382 15.5C7.66262 14.738 7.93439 13.9947 8.44972 13.4325C8.96505 12.8703 9.682 12.5351 10.4438 12.5C10.7212 12.4985 10.9968 12.5441 11.2588 12.635"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.44336 14.75H9.44336" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.44336 16.25H9.44336" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
PaimentFactureIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default PaimentFactureIcon
