/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'

export default createContext<{
  mantantToSend: number | null
  setMantantToSend: (value: number) => void
  mantantToRecu: number | null
  setMantantToRecu: (value: number) => void
  taux: number | null
  setTaux: (value: number) => void
  commision: number | null
  setCommision: (value: number) => void
}>({
  mantantToSend: null,
  setMantantToSend: () => {},
  mantantToRecu: null,
  setMantantToRecu: () => {},
  taux: null,
  setTaux: () => {},
  commision: null,
  setCommision: () => {},
})
