/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { TransferBeneficiary } from 'types/transferBeneficiary'

  interface TransferBeneficiaryProps {
    TransferData: TransferBeneficiary | null
    setTransferData: (TransferData: TransferBeneficiary | null) => void
  }
  
  const transferBeneficiaryContext = createContext<TransferBeneficiaryProps>({
    TransferData: null,
    setTransferData: () => {},
  })
  
  export default transferBeneficiaryContext