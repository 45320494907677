import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Spinner } from 'utils/loading'

interface props {
  linkAssurance: string
  setNumStep: any
  functionTracks: any
}
const Inscription: React.FC<props> = ({ linkAssurance, setNumStep, functionTracks }) => {
  const [loading, setLoading] = useState(true)

  const getURL = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }

  useEffect(() => {
    getURL()
  }, [])

  return (
    <div>
      <div className="flex flex-col">
        <div className="text-[#A5A5A5] text-[15px] font-normal">
          Veuillez commencez votre inscription chez notre partenaire.
        </div>
        {loading ? (
         <div className='mt-5 text-center mx-auto'>
            <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
         </div> 
        ) : (
          <iframe src={linkAssurance} className="w-full h-[500px]"></iframe>
        )}
      </div>
      <div className="flex gap-[16px] mt-[25px] justify-center w-full h-[56px] sm:px-4 ">
        <button
          className={classNames(
            'text-[18px] text-[#556987] px-[28px] py-[16px] border border-[#556987] w-[180px] sm:w-full font-sans rounded-lg',
          )}
          onClick={() => {
            setNumStep(0)
          }}
        >
          Précédent
        </button>
        <button
          className={classNames(
            'text-[18px] bg-[#4743C9] hover:cursor-pointer text-[#FFF]  px-[28px] py-[16px] md:my-0 rounded-lg w-[375px] sm:w-full ',
          )}
          onClick={() => {
            setNumStep(2)
            functionTracks(2)
          }}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}
export default Inscription
