import UserContext from 'contexts/UserContext'
import React, { useContext, useEffect, useState } from 'react'
import iconAddBnf from '../../assets/iconAddBnf.svg'

import { Spinner } from 'utils/loading'
import { getListBeneficary } from 'requests/benificary'
import ModalBeneficiary from 'containers/beneficiaireContainer/modalBeneficiary'
import ModalAddBeneficiary from 'containers/beneficiaireContainer/modalAddBeneficiary'

import avatar1 from '../../assets/avatar1.svg'
import avatar2 from '../../assets/avatar2.svg'
import avatar3 from '../../assets/avatar3.svg'
import avatar4 from '../../assets/avatar4.svg'
import avatar5 from '../../assets/avatar5.svg'
import avatar6 from '../../assets/avatar6.svg'
import avatar7 from '../../assets/avatar7.svg'
import avatar8 from '../../assets/avatar8.svg'
import avatar9 from '../../assets/avatar9.svg'
import avatar10 from '../../assets/avatar10.svg'
import avatar11 from '../../assets/avatar11.svg'
import avatar12 from '../../assets/avatar12.svg'
import avatar13 from '../../assets/avatar13.svg'
import avatar14 from '../../assets/avatar14.svg'
import avatar15 from '../../assets/avatar15.svg'
import avatar16 from '../../assets/avatar16.svg'
import avatar17 from '../../assets/avatar17.svg'
import avatar18 from '../../assets/avatar18.svg'
import avatar19 from '../../assets/avatar19.svg'
import avatar20 from '../../assets/avatar20.svg'
import avatar21 from '../../assets/avatar21.svg'
import avatar22 from '../../assets/avatar22.svg'
import avatar23 from '../../assets/avatar23.svg'
import avatar24 from '../../assets/avatar24.svg'

import newDefaultImageFemme from '../../assets/newDefaultImageFemme.svg'
import newDefaultImageHomme from '../../assets/newDefaultImageHomme.svg'

const ContainerHome = () => {
  const [person, setPerson] = useState<any>([])
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null)
  const [isAdded, setIsAdded] = useState(false)
  const [newImage, setNewImage] = useState('')
  const [newImageSelected, setNewImageSelected] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null)

  const dataAvatar = [
    { index: 1, image: avatar1 },
    { index: 2, image: avatar2 },
    { index: 3, image: avatar3 },
    { index: 4, image: avatar4 },
    { index: 5, image: avatar5 },
    { index: 6, image: avatar6 },
    { index: 7, image: avatar7 },
    { index: 8, image: avatar8 },
    { index: 9, image: avatar9 },
    { index: 10, image: avatar10 },
    { index: 11, image: avatar11 },
    { index: 12, image: avatar12 },
    { index: 12, image: avatar13 },
    { index: 14, image: avatar14 },
    { index: 15, image: avatar15 },
    { index: 16, image: avatar16 },
    { index: 17, image: avatar17 },
    { index: 18, image: avatar18 },
    { index: 19, image: avatar19 },
    { index: 20, image: avatar20 },
    { index: 21, image: avatar21 },
    { index: 22, image: avatar22 },
    { index: 23, image: avatar23 },
    { index: 24, image: avatar24 },
  ]

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await getListBeneficary(id, token)
        if (response?.success === true) {
          setPerson(response?.beneficiaries)
          setLoading(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user, isAdded])

  if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div className="flex items-center 2xl:mr-[3rem]">
      <div className="cursor-pointer mr-2 mt-3">
        <img src={iconAddBnf} className="w-10 h-10 object-cover" onClick={openModal} />
        <div className="text-[14px] sm:text-[15px] text-center font-normal text-blue-950">Ajoutez</div>
      </div>
      <ModalBeneficiary isOpen={isModalOpen} closeModal={closeModal}>
        <ModalAddBeneficiary
          setBeneficiaryData={setPerson}
          closeModal={closeModal}
          newImage={newImage}
          setNewImage={setNewImage}
          setNewImageSelected={setNewImageSelected}
          setIsAdded={() => setIsAdded(!isAdded)}
          selectedImageId={selectedImageId}
          setSelectedImageId={setSelectedImageId}
          dataAvatar={dataAvatar}
        />
      </ModalBeneficiary>

      <div className="flex overflow-x-auto max-w-[280px] mx-auto">
        {person.map((item: any, index: number) => (
          <div className="flex flex-col p-2 rounded-lg mt-[10px]" key={index}>
            <div className="w-10 h-10">
              <img
                src={
                  dataAvatar.some((v: any) => v.index === item.avatar)
                    ? dataAvatar.find((v) => v.index === item.avatar)?.image ?? newDefaultImageHomme
                    : item?.personalInfo?.gender === 'female' && !item.avatar
                    ? newDefaultImageFemme
                    : newDefaultImageHomme
                }
                className="w-full h-full object-contain"
              />
            </div>
            <div className="text-[14px] sm:text-[15px] font-normal text-blue-950 text-center">
              {item?.personalInfo?.firstName?.length > 5
                ? `${item?.personalInfo?.firstName}`.slice(0, 5)
                : `${item?.personalInfo?.firstName}`}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContainerHome
