import React, { useState, useContext, useEffect, useMemo } from 'react'
import UserContext from 'contexts/UserContext'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { UpdateForKyc } from 'helpers/api'
import { getAllCountries } from 'requests/countries'
import iconSelectInput from '../assets/iconSelectInput.svg'
import { getListBeneficary } from 'requests/benificary'
import PersonalInformationContainer from './personalInformationContainer/personalInformationContainer'
import { VerifAccount } from 'requests/transfer'
import { Spinner } from 'utils/loading'
import localforage from 'localforage'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const Personalinformation: React.FC = () => {
  const { user, setUser } = useContext(UserContext)
  const token = user?.token?.accessToken
  const [selectedOptionPays, setSelectedOptionPays] = useState('')
  const paysNaissance = getAllCountries()
  const [isOpenPays, setIsOpenPays] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState('')

  const [selectedDate, setSelectedDate] = useState(null)
  const [maxDate, setMaxDate] = useState(new Date())

  const handleSelectPays = (optionPays: any) => {
    setSelectedCountryCode(optionPays.code)
    setSelectedOptionPays(optionPays.frenshName)
    setIsOpenPays(false)
  }

  // Calculer la date maximale en fonction de l'âge
  useEffect(() => {
    const today = new Date()
    const maxDateOfBirth = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
    setMaxDate(maxDateOfBirth)
  }, [])

  const [person, setPerson] = useState([])

  const [loading, setLoading] = useState(false)

  const [isLink, setIsLink] = useState('')

  const [isVerified, setIsVerified] = useState(false)

  const [onVerif, setOnVerif] = useState(false)

  const fetchDataVerification = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    try {
      const response = await VerifAccount(id, token)
      if (response?.success) {
        setIsLink(response?.documentUrl)
        setIsVerified(true)
        setLoading(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClick = () => {
    if (user) {
      fetchDataVerification()
    }
  }

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await getListBeneficary(id, token)
        if (response?.success === true) {
          setPerson(response?.beneficiaries)
          setLoading(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  const initialFormData = {
    dateNaissance: '',
    adresseResidance: '',
    ville: '',
    codePostal: '',
    selectedOptionPays: '',
  }

  const [formData, setFormData] = useState(initialFormData)

  const validationSchema = yup.object({
    dateNaissance: yup
      .date()
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Vous devez avoir au moins 18 ans.')
      .required('Date de naissance obligatoire'),

    selectedOptionPays: yup
      .string()
      .required('Pays de naissance obligatoire')
      .test('', function (valuePays) {
        if (valuePays === undefined || valuePays.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return true
      }),
    adresseResidance: yup
      .string()
      .required('adresse residance obligatoire')
      .test('', function (valueAdresse) {
        if (valueAdresse === undefined || valueAdresse.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return true
      }),
    ville: yup
      .string()
      .required('Ville obligatoire')
      .test('', function (valueVille) {
        if (valueVille === undefined || valueVille.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return true
      }),
    codePostal: yup
      .string()
      .matches(/^[0-9]+$/, 'Des nombres uniquement')
      .required('Code postal obligatoire')
      .test('', function (valueCodePostal) {
        if (valueCodePostal === undefined || valueCodePostal.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return true
      }),
  })

  const handleSubmit = (values: typeof initialFormData) => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    if (formik.isValid) {
      const personalInfo: any = {
        dateNaissance: values.dateNaissance,
        adresseResidance: values.adresseResidance,
        valuePaysSelect: values.selectedOptionPays,
        ville: values.ville,
        codePostal: values.codePostal,
      }
      setFormData(personalInfo)
      const bodyOfPersonalInfo = {
        ...formik.values,
        countryOfBirthCode: selectedCountryCode,
      }
      UpdateForKyc(id, token, bodyOfPersonalInfo)
        .then((response: any) => {
          if (response?.success) {
            setOnVerif(true)
            if (user) {
              setUser({ token: user.token, success: true, user: response.user }),
                localforage.setItem('auth', JSON.stringify({ token: user.token, success: true, user: response.user }))
            }
            setLoading(true)
          }
        })
        .catch((error) => {
          console.error('Error updating for KYC:', error)
        })
    } else {
      console.log('Le formulaire contient des erreurs')
    }
  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  function formatDateToCustomFormat(dateNaissance: any) {
    if (!dateNaissance) {
      return ''
    }
    const dateObject = new Date(dateNaissance)
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', year: 'numeric', month: 'long' }
    return new Intl.DateTimeFormat('fr-FR', options).format(dateObject)
  }
  const formattedDate = formatDateToCustomFormat(formik.values.dateNaissance)

  if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div className="flex xs:flex-col  justify-around sm:gap-y-[27px] sm:mx-2">
      <div className="max-w-max xl:w-[500px] lg:ml-8 lg:w-full sm:w-[358px] sm:ml-[10px] sm:h-[450px] mx-auto">
        <div
          className={`sm:text-[25px] text-[30px]  my-5 sm:font-semibold font-bold ${
            user?.user?.birthDate ? 'mt-0' : 'mt-[50px]'
          }`}
        >
          <span className={`text-[#1D3A70] text-center ${user?.user?.birthDate ? 'hidden' : 'block'}`}>
            Complétez votre profil
          </span>
        </div>
        <div className={`text-[#6B7280] text-[14px] ${user?.user?.birthDate ? 'hidden' : 'block'}`}>
          Nous avons besoin de vous connaître un peu plus.
        </div>

        <form
          className="grid grid-cols-1 justify-center items-center sm:gap-[15px] gap-[24px]"
          onSubmit={formik.handleSubmit}
        >
          <div className="maw-w-max">
            {user?.user?.birthDate ? (
              <div>
                {isLink !== '' && isVerified !== false ? (
                  <iframe src={isLink} width="540" height="750"></iframe>
                ) : (
                  <PersonalInformationContainer handleClick={handleClick} />
                )}
              </div>
            ) : (
              <div>
                <div
                  className={`text-[#1D3A70] text-[18px] font-medium mt-5 ${
                    user?.user?.birthDate ? 'hidden' : 'block'
                  }`}
                >
                  Votre adresse
                </div>

                <div className="h-[80px] w-full">
                  <input
                    type="text"
                    placeholder="Adresse de résidence"
                    value={formik.values.adresseResidance}
                    name="adresseResidance"
                    className="rounded-xl bg-[#F9FAFB] text-[#1D3A70] placeholder:text-[#9CA3AF] h-[56px] sm:p-3  sm:text-[14px] p-5 mt-5 w-full"
                    onChange={formik.handleChange}
                    autoComplete="off"
                    onBlur={formik.handleBlur('adresseResidance')}
                  />
                  {formik.touched.adresseResidance && formik.errors.adresseResidance ? (
                    <div className="text-red-500 text-[14px] sm:text-[13px]">{formik.errors.adresseResidance}</div>
                  ) : null}
                </div>

                <div className="flex items-center gap-4 mt-5 sm:gap-0">
                  <div className="mr-5 sm:mr-2 h-[80px]">
                    <input
                      type="text"
                      placeholder="Code postal"
                      value={formik.values.codePostal}
                      name="codePostal"
                      className="rounded-xl bg-[#F9FAFB] text-[#1D3A70]  placeholder:text-[#9CA3AF] p-5 sm:p-3  sm:text-[14px] h-[56px] w-full"
                      onChange={formik.handleChange}
                      autoComplete="off"
                      onBlur={formik.handleBlur('codePostal')}
                    />
                    <br />
                    {formik.errors.codePostal && formik.touched.codePostal && (
                      <div className="text-red-500 text-[14px] text-sm sm:text-[13px]">{formik.errors.codePostal}</div>
                    )}
                  </div>
                  <div className="h-[80px]">
                    <input
                      type="text"
                      placeholder="Ville"
                      value={formik.values.ville}
                      name="ville"
                      className="rounded-xl bg-[#F9FAFB] text-[#1D3A70] placeholder:text-[#9CA3AF] sm:p-3  sm:text-[14px] h-[56px] w-full p-5"
                      onChange={formik.handleChange}
                      autoComplete="off"
                      onBlur={formik.handleBlur('ville')}
                    />
                    <br />
                    {formik.errors.ville && formik.touched.ville && (
                      <span className="text-red-500 text-[14px] text-sm sm:text-[13px]">{formik.errors.ville}</span>
                    )}
                  </div>
                  <div className="ml-5 sm:ml-2 h-[80px]">
                    <input
                      type="text"
                      placeholder={user?.user.address.country.name}
                      value={user?.user.address.country.name}
                      name="pays"
                      onChange={(e) => {
                        formik.setFieldValue('pays', e.target.value)
                      }}
                      className="rounded-xl bg-[#F9FAFB] text-[#1D3A70] placeholder:text-[#9CA3AF] sm:p-3  sm:text-[14px] h-[56px] p-5 w-full"
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>

                <div className=" text-[#1D3A70] text-[18px] mt-5 font-medium">Vos informations personnelles</div>
                <div className="flex items-center">
                  <div className="w-full mr-5 sm:mr-3 h-[100px] sm:h-[85px]">
                    <div className="relative inline-block mt-5 w-full">
                      <input
                        type="text"
                        className="p-5 h-[56px] rounded-xl bg-[#F9FAFB] sm:p-3  sm:text-[14px] text-[#1D3A70]  placeholder:text-[#9CA3AF] w-full"
                        placeholder="Pays de naissance"
                        onClick={() => setIsOpenPays(!isOpenPays)}
                        value={selectedOptionPays}
                        onChange={(e) => {
                          formik.setFieldValue('selectedOptionPays', e.target.value), handleSelectPays(e.target.value)
                        }}
                        name="selectedOptionPays"
                        onBlur={formik.handleBlur('selectedOptionPays')}
                        autoComplete="off"
                        readOnly
                      />

                      <div
                        className="absolute top-0 right-0 mt-[15px] mx-3 sm:pr-1 sm:mx-0"
                        onClick={() => setIsOpenPays(!isOpenPays)}
                      >
                        <img src={iconSelectInput} className="h-6 w-6" />
                      </div>

                      <div
                        className={`absolute top-full left-0 z-10 min-h-auto max-h-[150px] rounded-lg w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                          isOpenPays ? 'block' : 'hidden'
                        }`}
                      >
                        {paysNaissance.map((paysList: any, index: number) => (
                          <div
                            key={index}
                            className="p-2 cursor-pointer"
                            onClick={() => {
                              formik.setFieldValue('selectedOptionPays', paysList.frenshName)
                              handleSelectPays(paysList)
                            }}
                          >
                            {paysList.frenshName}
                          </div>
                        ))}
                      </div>
                    </div>
                    {formik.touched.selectedOptionPays && formik.errors.selectedOptionPays ? (
                      <div className="text-red-500 text-[14px] sm:text-[13px]">{formik.errors.selectedOptionPays}</div>
                    ) : null}
                  </div>

                  <div className="h-[100px] sm:h-[85px] w-full">
                    <div className="relative">
                      <DatePicker
                        onBlur={formik.handleBlur('dateNaissance')}
                        selected={selectedDate}
                        onChange={(date: any, e: any) => {
                          setSelectedDate(date)
                          if (date && !isNaN(date.getTime())) {
                            formik.setFieldValue('dateNaissance', date)
                          }
                          formik.handleChange(e)
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="JJ/MM/AAAA"
                        locale="fr"
                        name="dateNaissance"
                        autoComplete="off"
                        wrapperClassName="relative w-full"
                        className="w-full rounded-xl bg-[#F9FAFB] text-[#1D3A70] placeholder:text-[#9CA3AF] sm:p-3 sm:text-[14px] h-[56px] mt-5 p-5"
                        calendarClassName="absolute w-full mt-2 border bg-white dark:bg-gray-800 rounded-lg shadow-lg p-2"
                        showIcon
                        toggleCalendarOnIconClick
                        calendarIconClassname="absolute bottom-3 right-3"
                        maxDate={maxDate}
                        customInput={
                          <input
                            type="text"
                            value={formik.values.dateNaissance}
                            onChange={(e) => {
                              const inputDate = e.target.value
                              const formattedDate = inputDate
                                .replace(/\D/g, '')
                                .replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
                              formik.setFieldValue('dateNaissance', formattedDate)
                              formik.handleChange(e)
                            }}
                          />
                        }
                      />
                    </div>
                    {formik.errors.dateNaissance && formik.touched.dateNaissance && (
                      <div className="text-red-500 text-[14px] text-sm sm:text-[13px]">
                        {formik.errors.dateNaissance}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-center mt-8 sm:mt-0">
              <button
                type="submit"
                value="submit"
                disabled={user?.user?.birthDate !== undefined}
                className={`text-white  w-1/2 rounded-xl mt-8 p-5 h-[56px] sm:h-[50px] sm:p-0
                ${user?.user?.birthDate ? 'bg-gray-400' : 'bg-[#4743C9]'}
                ${user?.user?.birthDate ? 'hidden' : 'block'}`}
              >
                Continuer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default Personalinformation
