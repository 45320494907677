import axios from '../helpers/axios'

export const PartnersList = (token: string | undefined, country: string | undefined) =>
  axios
    .get(`/partners/active?country=${country}&governorate=&city=&name=`, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})
