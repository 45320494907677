import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import imageTransferArgent from '../../assets/imageTransferArgent.svg'
import imageRechargeMobile from '../../assets/imageRechargeMobile.svg'
import imageTransferWallet from '../../assets/imageTransferWallet.svg'
import imagePaimentFacture from '../../assets/imagePaimentFacture.svg'
import iconCloseModalRecharge from '../../assets/iconCloseModalRecharge.svg'
import assuranceImage from '../../assets/assuranceImage.svg'

import { Link, useNavigate } from 'react-router-dom'
import iconUpdateProfileBeneficiary from '../../assets/iconUpdateProfileBeneficiary.svg'
import BeneficiaryContext from 'contexts/beneficiaryContext'

interface Props {
  nom: any
  num: any
  photo: string
  index: number
  indexBeneficiary: any
  isOpen: boolean
  person: any
  closeModal: () => void
}

const ModalHomeBeneficiary: React.FC<Props> = ({
  isOpen,
  closeModal,
  nom,
  num,
  photo,
  index,
  indexBeneficiary,
  person,
}) => {
  if (!isOpen) return null

  const navigate = useNavigate()
  const { beneficiaries } = useContext(BeneficiaryContext)

  return (
    <div
      className={classNames(
        'fixed justify-center items-center flex flex-col inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm',
      )}
    >
      <div
        className={classNames(
          'rounded-xl relative py-3 xl:py-1 flex flex-col w-[373px] bg-image-bg-beneficiary bg-no-repeat ] ',
        )}
      >
        <img
          src={iconCloseModalRecharge}
          width={20}
          height={20}
          onClick={closeModal}
          className="right-2 absolute hover:cursor-pointer mx-[1rem] my-[10px] xs:w-[18px]"
        />
        <div className="p-5">
          <div className="flex flex-col justify-center items-center">
            <div className="mt-[3rem] text-[#1D3A70] text-[20px] font-semibold leading-5">{nom}</div>
            <div className="mt-3 text-[#666666] text-[14px] font-normal leading-3">{num}</div>

            <div
              className="flex items-center bg-[#4743C9] px-3 py-2 mt-[3rem] w-[300px] rounded-lg hover:cursor-pointer"
              onClick={() => {
                closeModal()
                if (indexBeneficiary === index) {
                  navigate('/transfert/0')
                }
              }}
            >
              <img src={imageTransferArgent} alt="image transfer" className="mr-[17px]" />
              <span className="text-white text-[18px] font-medium leading-3">Transfert d&apos;argent</span>
            </div>

            {beneficiaries?.personalInfo?.phone?.prefix === '+212' ||
            beneficiaries?.personalInfo?.phone?.prefix === '212' ? (
              <div className="flex items-center bg-[#d0d0d1] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-not-allowed">
                <img src={imageTransferWallet} alt="image transfer" className="mr-[17px]" />
                <span className="text-black text-[16px] font-medium">Transfert vers un compte bancaire</span>
              </div>
            ) : (
              <div
                className="flex items-center bg-[#4743C9] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-pointer"
                onClick={() => {
                  closeModal()
                  if (indexBeneficiary === index) {
                    navigate('/transfert/1')
                  }
                }}
              >
                <img src={imageTransferWallet} alt="image transfer" className="mr-[17px]" />
                <span className="text-white text-[16px] font-medium">Transfert vers un compte bancaire</span>
              </div>
            )}

            <div
              className="flex items-center bg-[#4743C9] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-pointer"
              onClick={() => {
                closeModal()
                if (indexBeneficiary === index) {
                  navigate('/recharge')
                }
              }}
            >
              <img src={imageRechargeMobile} alt="image transfer" className="mr-[17px]" />
              <span className="text-white text-[18px] font-medium leading-3">Recharge Mobile</span>
            </div>

            <div
              className="flex items-center bg-[#4743C9] px-3 py-2 mt-[1rem] w-[300px] rounded-lg hover:cursor-pointer"
              onClick={() => {
                closeModal()
                if (indexBeneficiary === index) {
                  navigate('/paimentFacture')
                }
              }}
            >
              <img src={imagePaimentFacture} alt="image transfer" className="mr-[17px]" />
              <span className="text-white text-[18px] font-medium leading-3">Paiement facture</span>
            </div>
            {/* <div className="flex items-center bg-[#d5d5d5] px-3 py-2 mt-[25px] w-[300px] rounded-lg hover:cursor-not-allowed">
              <img src={imagePaimentFacture} alt="image transfer" className="mr-[17px]" />
              <span className="text-black text-[18px] font-medium leading-3">Paiement de facture</span>
            </div> */}

            {/* <div
              className="flex items-center bg-[#F18C00] px-3 py-2 mt-[25px] w-[300px] rounded-lg hover:cursor-pointer"
              onClick={() => {
                closeModal()
                if (indexBeneficiary === index) {
                  navigate('/assurance')
                }
              }}
            >
              <img src={assuranceImage} alt="image transfer" className="mr-[17px]" />
              <span className="text-white text-[16px] font-medium">Assurance</span>
            </div> */}
             {/* <div className="flex items-center bg-[#d5d5d5] px-3 py-2 mt-[25px] w-[300px] rounded-lg hover:cursor-not-allowed">
              <img src={assuranceImage} alt="image transfer" className="mr-[17px]" />
              <span className="text-black text-[18px] font-medium leading-3">Assurance</span>
            </div> */}
          </div>
        </div>
        <div className="absolute -top-[12%] transform left-[35%] ">
        <div className="relative">
          <img
            className="w-24 h-24 object-cover rounded-full shadow-xl border-white border-[5px]"
            src={photo}
            alt="Person Image"
          />
        </div>
      </div>
      </div>

    
    </div>
  )
}

export default ModalHomeBeneficiary
