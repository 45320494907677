import React from 'react'

interface Props{
    image: string
    text?: string
}

const LogoConatiner : React.FC<Props>  = ({image, text}) =>{
return(
    <div className='h-[70px] sm:h-0 xs:h-0'>
        <div className="sm:hidden flex justify-between sm:mt-0 mt-[50px]">
          <img className="ml-[70px]" src={image} alt="" />
        </div>
    </div>
)
}

export default LogoConatiner