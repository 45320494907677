import localforage from 'localforage'
import { UserLog } from 'types/User'

export default async function startup(): Promise<UserLog | null> {
  try {
    const authString = await localforage.getItem<string | null>('auth')
    if (!authString) {
      return null
    }
    const user = JSON.parse(authString)

    return user
  } catch (e) {
    return null
  }
}
