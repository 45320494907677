import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UserContext from 'contexts/UserContext'
import { TransactionsList } from '../requests/transfer'
import HistoryTable from './historyContainer/historyTable'
import HomeBlockLinks from './historyContainer/homeBlockLinks'
import ContainerHome from './containerHome/containerHome'
import { Spinner } from 'utils/loading'
import ImageHomeContainer from './homeContainers/imageHomeContainer'
import Modif from '../assets/modif.svg'
import newDefaultImageHomme from '../assets/newDefaultImageHomme.svg'
import newDefaultImageFemme from '../assets/newDefaultImageFemme.svg'
import ModalSowCountry from './homeContainers/modalShowCountry'
import { updateCountry } from 'requests/user'
import localforage from 'localforage'

import axios from 'axios'

const Home: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { user, setUser } = useContext(UserContext)
  const [transactionData, setTransactionData] = useState<any>([])
  const [isDataTransactionVide, setIsDataTransactionVide] = useState(false)
  const [showModalCountry, setShowModalCountry] = useState(false)
  const [isOperateur, setIsOperateur] = useState('null')
  const [operateur, setOperateur] = useState('')
  const [destinationDefaultCountry, setDestinationDefaultCountry] = useState('')

  //console.log('user', user)

  // function get notification msg
  // const getNotification = async () => {
  //   try {
  //     const response = await axios.get('http://localhost:5000/getNotification', {
  //       headers: {
  //         accept: 'application/json',
  //       },
  //     })
  //     return response?.data
  //   } catch (error) {
  //     console.log(error)
  //     return null
  //   }
  // }

  const checkPermission = () => {
    if (!('serviceWorker' in navigator)) {
      throw new Error('No support for service worker!')
    }
  }

  // const saveSubscription = async (subscription: PushSubscription) => {
  //   //const userId = user?.user?._id
  //   const response = await getNotification()
  //   if (response?.success) {
  //     console.log('save subscription success', response)

  //     if ('serviceWorker' in navigator && 'showNotification' in ServiceWorkerRegistration.prototype) {
  //       navigator.serviceWorker.ready.then((registration) => {
  //         registration.showNotification('hello!!', { body: response.notifications[0].text })
  //       })
  //     }
  //   } else {
  //     console.log('save subscription failed', response)
  //   }
  // }

  // const registerSW = async () => {
  //   function determinateAppServerKey() {
  //     var publicKey = 'BAVHNosbpNe7xCH14tPDthYZ3oTp5a9vJq0HpvzM4nsCdRz_aNPrjQ4oVLXz1vJ7kEvC4n3cu_vSOzyImDa5IGU'
  //     return urlBase64ToUint8Array(publicKey)
  //   }
  //   const urlBase64ToUint8Array = (base64String: any) => {
  //     const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  //     const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  //     const rawData = atob(base64)
  //     const outputArray = new Uint8Array(rawData.length)

  //     for (let i = 0; i < rawData.length; ++i) {
  //       outputArray[i] = rawData.charCodeAt(i)
  //     }
  //     return outputArray
  //   }

  //   navigator.serviceWorker
  //     .register('sw.js')
  //     .then((registration) => {
  //       console.warn('ServiceWorker registration successful', registration)
  //       registration.pushManager.getSubscription().then(function (subscription) {
  //         if (subscription === null) {
  //           registration.pushManager
  //             .subscribe({
  //               userVisibleOnly: true,
  //               applicationServerKey: determinateAppServerKey(),
  //             })
  //             .then(function (newSubscription) {
  //               return saveSubscription(newSubscription)
  //             })
  //         } else {
  //           return saveSubscription(subscription)
  //         }
  //       })
  //     })
  //     .catch(function (error) {
  //       console.error('ServiceWorker registration failed', error)
  //     })
  // }

  // useEffect(() => {
  //   checkPermission()
  //   registerSW()
  // }, [])

  const closeModalCountry = () => {
    setShowModalCountry(false)
  }

  const updateCountryUser = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    try {
      const response = await updateCountry(id, token, destinationDefaultCountry)
      if (response?.success) {
        if (user) {
          setUser({ token: user.token, success: true, user: response.user }),
            localforage.setItem('auth', JSON.stringify({ token: user.token, success: true, user: response.user }))
        }
        setShowModalCountry(false)
      } else {
        console.log(response)
      }
    } catch (error) {
      console.error('Error updating:', error)
    }
  }

  useEffect(() => {
    if (user?.user?.destinationDefaultCountry === '' || user?.user?.destinationDefaultCountry === undefined) {
      setShowModalCountry(true)
    }
  }, [])

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await TransactionsList(id, token)
        if (response?.success === true) {
          setTransactionData(response?.transfers)
          setIsDataTransactionVide(response?.transfers.length === 0)
          setLoading(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div className="">
      <div className="mx-auto 8xl:w-[650px] 8xl:ml-0">
        {/* make it in component */}
        <div className="2xl:hidden 3xl:hidden 4xl:hidden sm:block xs:block 5xl:mr-[30px] 5xl:ml-[40px] xs:mr-0 xs:ml-0">
          <div className="mt-5 flex items-center justify-around ">
            <div className="flex items-center">
              <img
                src={user?.user?.personalInfo?.gender === 'female' ? newDefaultImageFemme : newDefaultImageHomme}
                className="w-10 h-10 border-2 border-[#DCD8F0] rounded-full"
              />
              <div className="pl-3">
                <div className="text-[#0A0A0A] font-semibold text-[15px]">
                  {user?.user?.personalInfo?.firstName} {user?.user?.personalInfo?.lastName}
                </div>
                <div className="text-[#B4B4B4] text-[12px] font-normal">{user?.user?.email}</div>

                <div className="">
                  {user?.user?.status === 'pending' && !user?.user?.isDocumentSent ? (
                    <Link to="/personalinformation" className="text-red-500 text-[10px] font-medium">
                      Veuillez compléter vos informations ?
                    </Link>
                  ) : (
                    ''
                  )}
                  {user?.user?.status === 'pending' && user?.user?.isDocumentSent ? (
                    <span className="text-orange-500 text-[10px] font-medium">
                      Votre compte est en cours de validation
                    </span>
                  ) : (
                    ''
                  )}
                  {user?.user?.status === 'confirmed' && user?.user?.isDocumentSent ? (
                    <div className="text-green-500 text-[10px] font-medium">
                      Félicitations, vous pouvez envoyer de l&apos;argent
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <Link to="/settingProfile/0" className="hover:cursor-pointer">
              <img className="w-10 h-10" src={Modif} alt="Setting Profile" />
            </Link>
          </div>
        </div>

        <div className="max-w-max 2xl:mt-[20px] sm:w-fill-available sm:mt-5">
          <ImageHomeContainer />
        </div>

        <div className="sm:flex sm:flex-col sm:justify-center mt-[40px] ml-[150px] 6xl:ml-[60px] 8xl:ml-[70px] 2xl:ml-[55px] 2xs:mr-0 2xs:ml-9 xs:mr-0 xs:ml-7  xl:ml-[45px] 2xl:mr-0 xl:mx-auto sm:mt-5  max-w-max">
          <HomeBlockLinks />
        </div>

        <div className="sm:flex sm:flex-col sm:justify-center sm:w-[350px] xl:mx-auto xl:max-w-xl xl:w-[800px] 2xs:mr-0 2xs:ml-9 xs:mr-0 xs:ml-7 4xl:hidden xs:block 2xl:hidden mr-[45px] ml-[20px] 2xl:ml-[30px] 5xl:mt-[3rem] lg:ml-[40px]">
          <div className="text-[20px] sm:text-[18px] font-bold text-[#131313] py-3">Mes Bénéficaires</div>
          <ContainerHome />
        </div>

        <div className="sm:flex sm:flex-col sm:justify-center sm:w-[350px] 2xl:mr-0 2xl:ml-[25px] 2xl:w-[700px] 2xs:mr-0 2xs:ml-9 xs:mr-0 xs:ml-7 xl:w-[550px] md:mx-auto w-auto 6xl:w-full xl:ml-[40px] mr-[30px] ml-[40px] mt-[35px] sm:mt-[19.5px] 5xl:mt-[3rem] 2xl:mt-5">
          <div className="flex items-center justify-between w-[850px] 2xl:w-[700px] 6xl:w-[700px] 8xl:w-[700px] xl:w-[550px] sm:w-auto">
            <div className="text-[20px] sm:text-[18px] 5xl:py-3 text-[#0A0A0A] font-semibold">Mes Transactions</div>
            <Link
              className="text-[#5E636A] mr-[50px] sm:ml-auto text-[19px] sm:text-[16px] font-normal"
              to={'/history'}
            >
              Voir tout
            </Link>
          </div>
          {isDataTransactionVide ? (
            <div className="border-2 mb-[80px] border-dashed border-[#5754E5] 8xl:w-[700px] 3xl:w-[600px] xl:w-[500px] ml-6 mt-[15px] 2xl:mt-[30px] 2xl:w-[600px] w-[750px] sm:max-w-max sm:text-[14px] 2sm:text-[12px] 2sm:w-[300px] 2sm:ml-0 2sm:mb-5 text-[20px] text-center text-[#6B6B6B] rounded-2xl py-3 px-2">
              Aucun transfert n&apos;a été effectué jusqu&apos;à présent. Commencez maintenant !
            </div>
          ) : (
            <HistoryTable transactionData={transactionData.slice(0, 3)} />
          )}
        </div>
      </div>
      <ModalSowCountry
        isOpen={showModalCountry}
        closeModal={closeModalCountry}
        isOperateur={isOperateur}
        updateCountryUser={updateCountryUser}
        setOperateur={(value: string) => setOperateur(value)}
        onChange={(value: string) => setIsOperateur(value)}
        setDestinationDefaultCountry={(value: string) => setDestinationDefaultCountry(value)}
      />
    </div>
  )
}
export default Home
