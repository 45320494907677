import React from 'react'
import classNames from 'classnames'

interface props {
  numStep: number
  nomStep: string
  isActive: boolean
  lastStep?: boolean
  isComplete?: boolean
}

const StepTransfert: React.FC<props> = ({ numStep, nomStep, isActive, lastStep, isComplete }) => {
  return (
    <div className="flex items-center justify-center pb-5">
      <div className="relative">
        <div className="flex flex-col items-center">
          <div className={classNames(isActive ? 'bg-[#4743C9]' : 'bg-[#B4B4B4]',` text-white w-8 h-8 sm:w-6 sm:h-6 xs:w-6 xs:h-6 rounded-full flex items-center justify-center mr-2`)}>
          {numStep}
          </div>
          <div className={classNames(isActive ? 'text-[#4743C9]' : 'text-[#B4B4B4]', 'sm:text-[10px] xs:text-[10px] pt-2')}>{nomStep}</div>
        </div>
      </div>
      {lastStep && <div className={classNames(isActive ? 'bg-[#4743C9]' : 'bg-[#B4B4B4]', `mb-[25px] sm:mb-0 w-32 sm:w-10 sm:mx-0 xs:w-10 3xl:w-20 7xl:w-20 xs:mx-0 h-[2px] mx-2 `)}></div>}
    </div>
  )
}
export default StepTransfert
