import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import drapeauTunisie from '../../assets/drapeauTunisie.svg'
import drapeauMaroc from '../../assets/drapeauMaroc.svg'
import CustomSelect from 'components/CustomSelect'
import drapeauLogoTunisie from '../../assets/drapeauTunisie.png'
import UserContext from 'contexts/UserContext'
import { getCurrencies } from 'helpers/api'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import flagTunisie from '../../assets/flagTunisie.svg'

interface Props {
  dinarValue?: number
  handleDinarChange?: any
  setValuePays?: any
  valuePays?: any
  number: number
}
const ContainerTransfer: React.FC<Props> = ({ dinarValue, handleDinarChange, setValuePays, valuePays, number }) => {
  const { user } = useContext(UserContext)
  const { beneficiaries } = useContext(BeneficiaryContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const [listOption, setListOption] = useState<any[]>([])

  const initialPrefix = beneficiaries?.personalInfo?.phone?.prefix.startsWith('+')
    ? beneficiaries?.personalInfo?.phone?.prefix
    : `${'+'}${beneficiaries?.personalInfo?.phone?.prefix}`

  const getList = async () => {
    const currenciess = await getCurrencies()

    const currenciessList = currenciess?.filter((item: any) => item?.isDestination === true)

    const optionsLists: { icon: any; value: string; label: string; flag: string }[] = [
      { icon: drapeauTunisie, value: 'TND', label: 'TND', flag: 'TUN' },
      { icon: drapeauMaroc, value: 'MAD', label: 'MAD', flag: 'MAR' },
    ]

    let options: { icon: any; value: string; label: string; flag: string }[] = []

    if (number === 1) {
      options = [{ icon: drapeauLogoTunisie, value: 'TND', label: 'TND', flag: 'TUN' }]

      setValuePays({ value: 'TND' })
    } else {
      currenciessList.map((currency: any) => {
        const match = optionsLists.find((option) => option.value === currency.code)
        if (match) {
          options.push(match)
        }
      })
    }
    setListOption(options)
  }

  useEffect(() => {
    getList()
  }, [number])

  const handleSelectChange = (selectedValue: string) => {
    if (number === 1) {
      setValuePays({ value: 'TND' })
      setTransferData({ ...TransferData, valuePays: 'TND' })
    }
    setValuePays({ value: selectedValue })
    setTransferData({
      ...TransferData,
      BeneficiaryName: '',
      idBeneficiary: '',
      Motif: '',
      valuePays: selectedValue
    })
  }
  useEffect(() => {
    if (TransferData) {
      setValuePays({ value: TransferData?.valuePays })
    }
  }, [])

  useEffect(() => {
    if (valuePays.value === 'MAD') {
      setValuePays({ value: 'MAD' })
      setTransferData({ ...TransferData, valuePays: 'MAD' })
    } else if (valuePays.value === 'TND') {
      setValuePays({ value: 'TND' })
      setTransferData({ ...TransferData, valuePays: 'TND' })
    }
  }, [user?.user?.destinationDefaultCountry])

  useEffect(() => {
    if (initialPrefix === '+216') {
      setValuePays({ value: 'TND' })
      setTransferData({ ...TransferData, valuePays: 'TND' })
    } else if (initialPrefix === '+212') {
      setValuePays({ value: 'MAD' })
      setTransferData({ ...TransferData, valuePays: 'MAD' })
    }
  }, [initialPrefix])

  return (
    <div className="flex items-center gap-[12px] w-full sm:w-auto 2sm:w-[340px] mt-[1rem]">
      <div className="flex flex-col w-full">
        <div
          className={classNames(
            'text-[#050708] w-full sm:w-[240px] flex-none mb-1 font-semibold text-[17px] sm:text-[14px]',
          )}
        >
          Montant à recevoir <span className="text-[#FF0000]">*</span>
        </div>
        <input
          type="text"
          placeholder="valeur en dinar"
          value={dinarValue}
          onChange={(e: any) => {
            handleDinarChange(e.target.value)
          }}
          className="bg-gray-100  w-full  rounded-xl h-[50px] pl-3 py-3 text-[#1D3A70] placeholder:text-[#424242]"
        />
      </div>

      <div className="flex flex-col">
        <div
          className={classNames('text-[#050708] mt-1 font-semibold text-[17px] sm:text-[14px] sm:w-[99px] w-[106px]')}
        >
          Pays
        </div>
        <CustomSelect
          options={listOption}
          value={valuePays?.value}
          onChange={handleSelectChange}
          placeholder=""
          icon={listOption.filter((item: any) => item.value === valuePays?.value)[0]?.icon}
          width="sm:w-[99px] w-[110px] z-[1]"
          height="h-[48px] w-[120px]"
          textSize="text-[18px]"
          heightDropdown="max-h-max"
          marginRightIcon="mx-auto"
          showArrow={number === 1 ? false : true}
          roundedIcon="rounded-none"
          flag={listOption.filter((item: any) => item.value === valuePays?.value)[0]?.flag}
        />
      </div>
    </div>
  )
}
export default ContainerTransfer
