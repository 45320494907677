import React, { useState, useRef, useEffect, useContext } from 'react'
import ArrowDown from '../assets/arrowDown.svg'
import classNames from 'classnames'
import useClickOutside from '../hooks/useOnclickOutside'
import iconSelectInput from '../assets/iconSelectInput.svg'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

interface PropsInputSelect {
  value: string
  icon?: boolean
  data: any
  setItem: (selectedValue: string, idValue: string) => void
  isObjectData?: boolean
  width?: string
  placeholder?: string
  widthValue?: string
  keyOfIndex?: string
  keyOfIndex1?: string
  isDisabled?: boolean
  euroValue?: any
  partnersData?: any
  setReseaux?: any
  setSelectedOptionPointCollecte?: any
  setValueVille: any
  getListCity: any
  getListGovernorate: any
  setGetCity: any
  getCity: string
  setSelectedOptionReseaux:any
}
const InputSelectVille: React.FC<PropsInputSelect> = ({
  value,
  icon = true,
  data,
  setItem,
  isObjectData = false,
  width = 'w-[290px]',
  placeholder,
  widthValue,
  keyOfIndex = 'name',
  keyOfIndex1,
  isDisabled,
  euroValue,
  partnersData,
  setReseaux,
  setSelectedOptionPointCollecte,
  setValueVille,
  getListCity,
  getListGovernorate,
  setGetCity,
  getCity,
  setSelectedOptionReseaux
}) => {
  const [openVille, setOpenVille] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const toggleSetting = () => setOpenVille(false)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  useClickOutside(toggleSetting, dropDownRef, divRef)

  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value)
    setOpenVille(true)
  }

  const handleClickOutside = (event:any) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setOpenVille(false)
    }
  }

  useEffect(()=>{
    if(value !== ''){
      setGetCity(value)
    }
  },[value, getCity])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const filteredCities = getListCity.filter((city: any) => getListGovernorate.some((gov: any) => gov.name === city.gov))

  return (
    <div className={classNames(width, 'flex flex-col gap-1 relative w-full 2sm:w-[340px]')}>
      <div
        className="sm:mx-0 md:mx-0 2sm:ml-6">
        <div className="flex flex-col w-full">
          <div className="relative inline-block mt-3 hover:cursor-pointer" ref={dropDownRef}>
            <input
              type="text"
              className="p-2 border hover:cursor-pointer font-bold text-[15px] sm:h-[40px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#1D3A70]"
              placeholder={value ? value : placeholder}
              onClick={() => {
                setOpenVille(!openVille)
              }}
              value={getCity || value || searchTerm}
              onChange={(e) => {
                handleSearchChange(e)
                setReseaux('')
                setSelectedOptionPointCollecte('')
              }}
              name="selectedOptionMotif"
              autoComplete="off"
            />
            <div
              className="absolute top-0 right-0 mt-[15px] sm:mt-[10px] 2sm:mt-[10px] mx-3"
              onClick={() => setOpenVille(!openVille)}
            >
              <img src={iconSelectInput} className="h-6 w-6" />
            </div>
            {openVille && (
              <div
                className="flex flex-col rounded z-10 absolute min-h-auto max-h-[150px] overflow-auto bg-white  shadow-selct w-full"
                //ref={dropDownRef}
              >
                {filteredCities.map((item: any, index: number) => (
                  <span
                    key={index}
                    className={`hover:cursor-pointer py-2 text-[14px] pl-4 ${
                      item === value ? 'font-semibold text-[#1D3A70] bg-[#F5F5F5]' : 'text-[#424242] font-semibold'
                    }`}
                    onClick={() => {
                      setItem(item, item._id)
                      setGetCity(item)
                      setOpenVille(false)
                      if (item !== value) {
                        setReseaux('')
                        setSelectedOptionReseaux('')
                        setSelectedOptionPointCollecte('')
                        setTransferData({...TransferData,ville:item, partenaire:'', pointDeCollect:''})
                      }
                    }}
                  >
                    {item}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default InputSelectVille
