import classNames from 'classnames'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import rechargeMobileIcon from '../../assets/rechargeMobileIcon.svg'
import transferIcon from '../../assets/transferIcon.svg'

interface Props {
  isOpen: boolean
  closeModal: () => void
  responseSuccess?: any
  responseFailed?: any
  transfertPage?: any
  rechargePage?: any
  invoice?: any
}

const SecureCardTransfert: React.FC<Props> = ({
  isOpen,
  closeModal,
  responseSuccess,
  responseFailed,
  transfertPage,
  rechargePage,
  invoice
}) => {
  const navigate = useNavigate()

  if (!isOpen) return null
  return (
    <div className={classNames('justify-center items-center flex fixed inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm')}>
      <div className="relative my-6 mx-auto max-w-3xl">
        <div
          className={classNames(
            'border-0 rounded-xl pb-8 pt-5 px-[10rem] sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative xl:py-4 flex flex-col w-full bg-white',
          )}
        >
          <div className="flex flex-col justify-center items-center mt-10">
            <img
              src={transfertPage === 1 ? rechargeMobileIcon : rechargePage === 2 ? transferIcon : ''}
              className="w-[200px] h-[200px]"
            />
            <div className="text-[#1D3A70] text-[30px] sm:text-[20px] md:text[20px] font-bold pb-1 text-center">
              {responseSuccess && transfertPage === 1
                ? 'Transfert réussi'
                : responseSuccess && rechargePage === 2
                ? 'Recharge mobile réussi'
                : responseFailed && transfertPage === 1
                ? 'Transfert a échoué'
                : responseFailed && rechargePage === 2
                ? 'Recharge mobile a échoué'
                : responseSuccess && invoice === 3
                ? 'transfert réussi'
                : responseFailed && invoice === 3
                ? 'transfert a échoué'
                : ''
                }
              {}
            </div>
            <button
              onClick={() => {
                closeModal()
                navigate('/history')
              }}
              className={`rounded-xl h-[50px] mt-8 text-white text-[15px] w-full font-medium
                ${responseSuccess ? 'bg-green-500' : 'bg-red-500'}`}
            >
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecureCardTransfert
