import * as React from 'react'
import PropTypes from 'prop-types'

function HomeIcon({
  fill = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="dashboard-3--app-application-dashboard-home-layout-vertical">
        <path
          id="Vector"
          d="M23.2856 12.1428H16.4284C15.955 12.1428 15.5713 12.5266 15.5713 13V23.2857C15.5713 23.7591 15.955 24.1428 16.4284 24.1428H23.2856C23.759 24.1428 24.1427 23.7591 24.1427 23.2857V13C24.1427 12.5266 23.759 12.1428 23.2856 12.1428Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M23.2856 1.85718H16.4284C15.955 1.85718 15.5713 2.24093 15.5713 2.71432V6.16003C15.5713 6.63342 15.955 7.01718 16.4284 7.01718H23.2856C23.759 7.01718 24.1427 6.63342 24.1427 6.16003V2.71432C24.1427 2.24093 23.759 1.85718 23.2856 1.85718Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M9.57122 1.85718H2.71408C2.24069 1.85718 1.85693 2.24093 1.85693 2.71432V13C1.85693 13.4734 2.24069 13.8572 2.71408 13.8572H9.57122C10.0446 13.8572 10.4284 13.4734 10.4284 13V2.71432C10.4284 2.24093 10.0446 1.85718 9.57122 1.85718Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M9.57122 18.9829H2.71408C2.24069 18.9829 1.85693 19.3667 1.85693 19.8401V23.2858C1.85693 23.7592 2.24069 24.1429 2.71408 24.1429H9.57122C10.0446 24.1429 10.4284 23.7592 10.4284 23.2858V19.8401C10.4284 19.3667 10.0446 18.9829 9.57122 18.9829Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
HomeIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default HomeIcon
