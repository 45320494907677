import React from 'react'
import Ellipse from '../assets/Ellipse.png'
import Img1Sys from '../assets/img1Syn.png'
import Img2Sys from '../assets/img2Syn.png'
import Img3Sys from '../assets/img3Syn.svg'
import Img4Sys from '../assets/img4Syn.svg'

const Synthese: React.FC<{
  mantantToSend?: number
  taux?: number
  com?: number
  mantantToRecu?: number
  text: string
  LabelMantantToSend: string
  labelTaux: string
  labelCom: string
  LabelMantantToRecu: string
  valuePays:any
}> = ({
  mantantToSend,
  taux,
  com,
  mantantToRecu,
  text,
  LabelMantantToSend,
  labelTaux,
  labelCom,
  LabelMantantToRecu,
  valuePays
}) => {
  const calculerTotal = () => {
    const comToPay = com || 0
    if (mantantToSend) {
      const total = mantantToSend + comToPay
      return total
    } else {
      return null
    }
  }

  return (
    <div className="sm:hidden xs:hidden md:hidden px-4 sm:pt-[48px] py-[28px] sm:h-[473px] w-[375px] max-h-max sm:w-full rounded-lg shadow-lg">
      <div className="text-[20px] font-semibold">Synthèse</div>

      <div className="flex items-center justify-between mt-[25px]">
        <div className="flex items-center">
          <img src={Img1Sys} className="pt-2" />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">{LabelMantantToSend}</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">{mantantToSend ? mantantToSend : '-'}</div>
      </div>

      <div className="flex items-center justify-between mt-[25px]">
        <div className="flex items-center">
          <img src={Img2Sys} className="pt-2" />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">{labelTaux}</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">{taux}</div>
      </div>

      <div className="flex items-center justify-between mt-[25px]">
        <div className="flex items-center">
          <img src={Img3Sys} className="pt-2" />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">{labelCom}</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">{mantantToSend ? `+  ${com} €` : '-'}</div>
      </div>

      <div className="flex items-center justify-between mt-[25px]">
        <div className="flex items-center">
          <img src={Img1Sys} className="pt-2" />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">{text}</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">
          {calculerTotal() !== null ? `${calculerTotal()} €` : '-'}
        </div>
      </div>

      <div className="flex items-center justify-between mt-[25px]">
        <div className="flex items-center">
          <img src={Img4Sys} className="" />
          <div className="text-[#474747] text-[15px] font-semibold pl-3">{LabelMantantToRecu}</div>
        </div>
        <div className="text-[#5754E5] text-[15px] font-semibold">
          {mantantToSend !== undefined && mantantToRecu !== undefined
            ? Number.isInteger(mantantToSend)
              ? mantantToRecu
              : mantantToRecu.toFixed(2)
            : '-'}
          {valuePays?.value}
        </div>
      </div>
    </div>
  )
}

export default Synthese
