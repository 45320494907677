import React, { useState, useEffect, useContext, useRef } from 'react'
import UserContext from 'contexts/UserContext'
import { getListBeneficary } from 'requests/benificary'
import classNames from 'classnames'
import { Spinner } from 'utils/loading'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import ModalBeneficiary from 'containers/beneficiaireContainer/modalBeneficiary'
import ModalAddBeneficiary from 'containers/beneficiaireContainer/modalAddBeneficiary'
import iconAddBnf from '../../assets/iconAddBnf.svg'
import iconSelectInput from '../../assets/iconSelectInput.svg'
import useClickOutside from '../../hooks/useOnclickOutside'

import avatar1 from '../../assets/avatar1.svg'
import avatar2 from '../../assets/avatar2.svg'
import avatar3 from '../../assets/avatar3.svg'
import avatar4 from '../../assets/avatar4.svg'
import avatar5 from '../../assets/avatar5.svg'
import avatar6 from '../../assets/avatar6.svg'
import avatar7 from '../../assets/avatar7.svg'
import avatar8 from '../../assets/avatar8.svg'
import avatar9 from '../../assets/avatar9.svg'
import avatar10 from '../../assets/avatar10.svg'
import avatar11 from '../../assets/avatar11.svg'
import avatar12 from '../../assets/avatar12.svg'
import avatar13 from '../../assets/avatar13.svg'
import avatar14 from '../../assets/avatar14.svg'
import avatar15 from '../../assets/avatar15.svg'
import avatar16 from '../../assets/avatar16.svg'
import avatar17 from '../../assets/avatar17.svg'
import avatar18 from '../../assets/avatar18.svg'
import avatar19 from '../../assets/avatar19.svg'
import avatar20 from '../../assets/avatar20.svg'
import avatar21 from '../../assets/avatar21.svg'
import avatar22 from '../../assets/avatar22.svg'
import avatar23 from '../../assets/avatar23.svg'
import avatar24 from '../../assets/avatar24.svg'
import DataNeedsContext from 'contexts/transferBeneficiaryContext'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

interface props {
  benificiare: string
  setBenificiareId: (name: string) => void
  motif: string
  setMotif: (value: string) => void
  setNumStep: (value: number) => void
  setIdBeneficiary: (value: any) => void
  idBeneficiary: any
  apiData: any
  setColectPointId: (value: any) => void
  colectPointId: any
  setSelectedBnfList: (value: any) => void
  selectedBnfList: any
  valuePays: any
  functionTracks: any
  code: any
  selectedValue: string
}

const Benefiaire: React.FC<props> = ({
  benificiare,
  setBenificiareId,
  motif,
  setMotif,
  setNumStep,
  setIdBeneficiary,
  idBeneficiary,
  apiData,
  setColectPointId,
  colectPointId,
  setSelectedBnfList,
  selectedBnfList,
  valuePays,
  functionTracks,
  code,
  selectedValue,
}) => {
  const { user } = useContext(UserContext)
  const { beneficiaries } = useContext(BeneficiaryContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const [loading, setLoading] = useState(false)
  const [dataBeneficiaries, setBeneficiariesData] = useState([])
  const [people, setPeople] = useState([])
  const [isOpenMotif, setIsOpenMotif] = useState(false)
  const [selectedOptionMotif, setSelectedOptionMotif] = useState<any>('')
  const [isAdded, setIsAdded] = useState(false)
  const [newImage, setNewImage] = useState('')
  const [newImageSelected, setNewImageSelected] = useState(false)
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null)
  const [isOpenBnflist, setIsOpenBnflist] = useState(false)

  const [addedNewBeneficiary, setaddedNewBeneficiary] = useState('')

  const divRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const toggleSetting = () => {
    setIsOpenBnflist(false)
    setIsOpenMotif(false)
  }

  const itemsMotif = [
    { index: 0, code: 'FAMILY_SUPPORT', name: 'Aide Familiale' },
    { index: 1, code: 'OTHER_FEES', name: 'Frais' },
    { index: 2, code: 'PROPERTY_PURCHASE', name: 'Paiement' },
    { index: 3, code: 'OTHER', name: 'Autres non répertoriés' },
  ]

  const dataAvatar = [
    { index: 1, image: avatar1 },
    { index: 2, image: avatar2 },
    { index: 3, image: avatar3 },
    { index: 4, image: avatar4 },
    { index: 5, image: avatar5 },
    { index: 6, image: avatar6 },
    { index: 7, image: avatar7 },
    { index: 8, image: avatar8 },
    { index: 9, image: avatar9 },
    { index: 10, image: avatar10 },
    { index: 11, image: avatar11 },
    { index: 12, image: avatar12 },
    { index: 12, image: avatar13 },
    { index: 14, image: avatar14 },
    { index: 15, image: avatar15 },
    { index: 16, image: avatar16 },
    { index: 17, image: avatar17 },
    { index: 18, image: avatar18 },
    { index: 19, image: avatar19 },
    { index: 20, image: avatar20 },
    { index: 21, image: avatar21 },
    { index: 22, image: avatar22 },
    { index: 23, image: avatar23 },
    { index: 24, image: avatar24 },
  ]

  const handleSelectMotif = (option: any) => {
    setSelectedOptionMotif(option.code)
    setTransferData({ ...TransferData, Motif: option.code })
    setMotif(option.code)
  }

  const handleSelectbnf = (bnf: any) => {
    setSelectedBnfList(bnf)
    setIdBeneficiary(beneficiaries?._id)
  }

  useEffect(() => {
    if (TransferData) {
      setSelectedOptionMotif(TransferData?.Motif)
      setMotif(TransferData?.Motif || '')
    }
  }, [TransferData])

  useEffect(() => {
    if (TransferData) {
      setSelectedBnfList(TransferData?.BeneficiaryName)
      setIdBeneficiary(TransferData?.idBeneficiary)
    }
  }, [TransferData])

  //  useEffect(()=>{
  //   if(valuePays?.value !== TransferData?.valuePays){
  //     setTransferData({ ...TransferData, BeneficiaryName: '', idBeneficiary: '' })
  //   }
  //  },[TransferData?.valuePays])

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      const response = await getListBeneficary(id, token)
      if (response?.success === true) {
        setBeneficiariesData(response?.beneficiaries)
        // if (isAdded) {
        //   const addedBeneficiary = response?.beneficiaries[0]
        //   setaddedNewBeneficiary(`${addedBeneficiary?.personalInfo?.firstName} ${addedBeneficiary?.personalInfo?.lastName}`)
        //   setIdBeneficiary(addedBeneficiary?._id)
        // }
        setLoading(true)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user, isAdded])

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  useEffect(() => {
    if (loading && dataBeneficiaries) {
      setPeople(dataBeneficiaries)
    }
  }, [loading, dataBeneficiaries])

  // const handleClickOutside = (event: any) => {
  //   if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
  //     toggleSetting()
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [])

  if (!loading) return <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />

  return (
    <div>
      <div ref={divRef} className="relative h-[120px] mt-10 sm:mt-5 sm:ml-5">
        <div className="font-bold text-[18px] text-[#1D3A70]">
          Bénéficiaire du Transfert <span className="text-red-500">*</span>
        </div>

        <div className="relative items-center hover:cursor-pointer inline-block mt-3 w-[450px] sm:w-auto">
          <input
            type="text"
            className="p-2 bg-[#F9FAFB] hover:cursor-pointer font-bold text-[18px] sm:h-[55px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#6B7280]"
            onClick={() => {
              setIsOpenBnflist(!isOpenBnflist), setIsOpenMotif(false)
            }}
            placeholder="Choisir un bénéficiaire"
            value={idBeneficiary === undefined ? '' : selectedBnfList}
            // value={
            //   addedNewBeneficiary !== ''
            //     ? addedNewBeneficiary
            //     : idBeneficiary === undefined
            //     ? ''
            //     : selectedBnfList
            // }
            onChange={(e) => {
              handleSelectbnf(e.target.value)
            }}
            name="selectedBnfList"
            readOnly
          />
          <div className="absolute top-0 right-0 mt-[15px] mx-3" onClick={() => setIsOpenBnflist(!isOpenBnflist)}>
            <img src={iconSelectInput} className="h-6 w-6" />
          </div>

          <div
            className={`absolute top-full left-0 z-10 min-h-auto rounded-lg max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
              isOpenBnflist ? 'block' : 'hidden'
            }`}
          >
            {beneficiaries && !people ? (
              <div>
                <div
                  key={beneficiaries?._id}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setIsOpenBnflist(false)
                  }}
                >
                  <div
                    className="text-[#1D3A70] text-[15px] font-semibold"
                    onClick={() => setIdBeneficiary(beneficiaries?._id)}
                  >
                    {beneficiaries?.personalInfo?.firstName} {beneficiaries?.personalInfo?.lastName}
                  </div>
                </div>
                {(valuePays.value === 'MAD' && beneficiaries?.personalInfo?.phone?.prefix === '212') ||
                  (valuePays.value === 'MAD' && beneficiaries?.personalInfo?.phone?.prefix === '+212' && (
                    <div
                      key={beneficiaries?._id}
                      className="p-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setIsOpenBnflist(false)
                      }}
                    >
                      <div
                        className="text-[#1D3A70] text-[15px] font-semibold"
                        onClick={() => setIdBeneficiary(beneficiaries?._id)}
                      >
                        {beneficiaries?.personalInfo?.firstName} {beneficiaries?.personalInfo?.lastName}
                      </div>
                    </div>
                  ))}
                {(valuePays.value === 'TND' && beneficiaries?.personalInfo?.phone?.prefix === '216') ||
                  (valuePays.value === 'TND' && beneficiaries?.personalInfo?.phone?.prefix === '+216' && (
                    <div
                      key={beneficiaries?._id}
                      className="p-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setIsOpenBnflist(false)
                      }}
                    >
                      <div
                        className="text-[#1D3A70] text-[15px] font-semibold"
                        onClick={() => setIdBeneficiary(beneficiaries?._id)}
                      >
                        {beneficiaries?.personalInfo?.firstName} {beneficiaries?.personalInfo?.lastName}
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div>
                {people &&
                  people.map((bnf: any, index: number) => {
                    if (
                      (valuePays.value === 'MAD' && bnf?.personalInfo?.phone?.prefix === '+212') ||
                      (valuePays.value === 'MAD' && bnf?.personalInfo?.phone?.prefix === '212')
                    ) {
                      return (
                        <div
                          key={index}
                          className="p-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => {
                            handleSelectbnf(`${bnf?.personalInfo?.firstName} ${bnf?.personalInfo?.lastName}`)
                            setIsOpenBnflist(false)
                            setIdBeneficiary(bnf?._id)
                            setTransferData({
                              ...TransferData,
                              BeneficiaryName: `${bnf?.personalInfo?.firstName} ${bnf?.personalInfo?.lastName}`,
                              idBeneficiary: bnf?._id,
                            })
                          }}
                        >
                          <div className="text-[#1D3A70] text-[15px] font-semibold">
                            {bnf?.personalInfo?.firstName} {bnf?.personalInfo?.lastName}
                          </div>
                        </div>
                      )
                    } else if (
                      (valuePays.value === 'TND' && bnf?.personalInfo?.phone?.prefix === '216') ||
                      (valuePays.value === 'TND' && bnf?.personalInfo?.phone?.prefix === '+216')
                    ) {
                      return (
                        <div
                          key={index}
                          className="p-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => {
                            handleSelectbnf(`${bnf?.personalInfo?.firstName} ${bnf?.personalInfo?.lastName}`)
                            setIsOpenBnflist(false)
                            setIdBeneficiary(bnf?._id)
                            setTransferData({
                              ...TransferData,
                              BeneficiaryName: `${bnf?.personalInfo?.firstName} ${bnf?.personalInfo?.lastName}`,
                              idBeneficiary: bnf?._id,
                            })
                          }}
                        >
                          <div className="text-[#1D3A70] text-[15px] font-semibold">
                            {bnf?.personalInfo?.firstName} {bnf?.personalInfo?.lastName}
                          </div>
                        </div>
                      )
                    }
                  })}
              </div>
            )}
          </div>
        </div>

        <img
          src={iconAddBnf}
          className="absolute top-0 right-0 mt-[35px] sm:mt-[40px] sm:w-12 sm:h-12 w-14 h-14 hover:cursor-pointer"
          onClick={openModal}
        />

        <ModalBeneficiary isOpen={isModalOpen} closeModal={closeModal}>
          <ModalAddBeneficiary
            setBeneficiaryData={setPeople}
            closeModal={closeModal}
            newImage={newImage}
            setNewImage={setNewImage}
            setNewImageSelected={setNewImageSelected}
            setIsAdded={() => setIsAdded(!isAdded)}
            selectedImageId={selectedImageId}
            setSelectedImageId={setSelectedImageId}
            dataAvatar={dataAvatar}
            valuePays={valuePays}
            code={code}
          />
        </ModalBeneficiary>
      </div>

      <div className="sm:ml-5">
        <label className="sm:mx-4 md:mx-4">
          <div className="flex flex-col w-full">
            <span className="text-[#1D3A70] font-bold text-[18px] md:mt-1">
              Motif du Transfert <span className="text-red-500">*</span>
            </span>
            <div ref={dropDownRef} className="relative inline-block mt-3 hover:cursor-pointer">
              <input
                type="text"
                className="p-2 bg-[#F9FAFB] hover:cursor-pointer font-bold text-[18px] sm:h-[55px] rounded-xl w-full h-[56px] text-[#1D3A70] placeholder:text-[#6B7280]"
                placeholder="Choisir un motif"
                onClick={() => {
                  setIsOpenMotif(!isOpenMotif), setIsOpenBnflist(false)
                }}
                value={itemsMotif.find((item) => item.code === selectedOptionMotif)?.name || ''}
                onChange={(e) => {
                  handleSelectMotif(e.target.value)
                }}
                name="selectedOptionMotif"
                readOnly
              />
              <div className="absolute top-0 right-0 mt-[15px] mx-3" onClick={() => setIsOpenMotif(isOpenMotif)}>
                <img src={iconSelectInput} className="h-6 w-6" />
              </div>

              <div
                className={`absolute overflow-y-hidden top-full left-0 z-10 min-h-auto rounded-lg max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white ${
                  isOpenMotif ? 'block' : 'hidden'
                }`}
              >
                {itemsMotif.map((option: any, index: number) => (
                  <div
                    key={index}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      handleSelectMotif(option)
                    }}
                  >
                    <div className="text-[#1D3A70] text-[15px] font-semibold">{option.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </label>
      </div>
      <div className="flex gap-[16px] mt-[25px] justify-center w-full h-[56px] sm:px-4 ">
        <button
          className={classNames(
            'text-[18px] text-[#556987] px-[28px] py-[16px] border border-[#556987] w-[180px] sm:w-full font-sans rounded-lg',
          )}
          onClick={() => {
            setNumStep(0)
          }}
        >
          Précédent
        </button>
        <button
          className={classNames(
            idBeneficiary === null ||
              idBeneficiary === '' ||
              idBeneficiary === undefined ||
              motif === '' ||
              motif === null
              ? 'bg-[#A5A5A5] hover:cursor-not-allowed'
              : 'bg-[#4743C9] hover:cursor-pointer',
            'text-[18px] text-[#FFF]  px-[28px] py-[16px] md:my-0 rounded-lg w-[375px] sm:w-full ',
          )}
          onClick={() => {
            setNumStep(2)
            functionTracks('2')
          }}
          disabled={
            motif === '' ||
            motif === null ||
            idBeneficiary === null ||
            idBeneficiary === undefined ||
            idBeneficiary === ''
              ? true
              : false
          }
        >
          Suivant
        </button>
      </div>
    </div>
  )
}
export default Benefiaire
