import React, { useContext, useState } from 'react'
import ModalHomeBeneficiary from '../containers/homeContainers/modalHomeBeneficiary'
import UserContext from 'contexts/UserContext'

interface props {
  nom: string
  num: string
  photo: string
  index: number
  person: any
  setIndexBeneficiairy: any
  indexBeneficiary: any
  item:any
}

const BenificePerson: React.FC<props> = ({
  nom,
  num,
  photo,
  setIndexBeneficiairy,
  index,
  person,
  indexBeneficiary,
  item
}) => {
  const [isOpenModal, setIsopenModal] = useState(false)

  const openModalBeneficiaryPerson = (person: any) => {
    setIsopenModal(true)
  }
  const closeModalBeneficiaryPerson = () => {
    setIsopenModal(false)
  }

  return (
    <>
      <div
        className="flex items-center gap-[24px] cursor-pointer"
        onClick={() => {
          setIndexBeneficiairy(index)
          openModalBeneficiaryPerson(person)
        }}
      >
        <img src={photo} className='w-8 h-auto object-cover'/>
        <div>
          <div className="text-[18px] sm:text-[15px] xs:text-[15px] 7xl:text-[15px] md:text-[15px] lg:text-[13px] 2xl:text-[15px] text-[#0A0A0A] font-sans">{nom}</div>
          <div className="text-[#B4B4B4] text-[18px] sm:text-[15px] 7xl:text-[15px] xs:text-[15px] md:text-[15px] lg:text-[13px] 2xl:text-[15px] mt-[3px]">{num}</div>
        </div>
      </div>
      <ModalHomeBeneficiary
        nom={`${item?.personalInfo?.gender === 'male' ? 'Mr' : 'Mme'} ${nom}`}
        num={num}
        photo={photo}
        index={index}
        indexBeneficiary={indexBeneficiary}
        isOpen={isOpenModal}
        closeModal={closeModalBeneficiaryPerson}
        person={person}
      />
    </>
  )
}
export default BenificePerson
