import React, { useContext, useEffect } from 'react'
import NavBar from './NavBar'
import SideBar from './sideBar'
import classNames from 'classnames'
import NavbarMobile from './NavBarMobile'
import DetailsUserContainer from 'containers/homeContainers/detailsUserContainer'
import { ProfileRequest } from 'requests/user'
import UserContext from 'contexts/UserContext'
import localforage from 'localforage'

const Layout = (props: any) => {
  const { user, setUser } = useContext(UserContext)
  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await ProfileRequest(id, token)
        if (response?.success === true) {
          if (user) {
            setUser({ token: user.token, success: true, user: response.user })
            localforage.setItem('auth', JSON.stringify({ token: user.token, success: true, user: response.user }))
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user?.user?.status === 'pending') {
      fetchData()
    }
  }, [])
  return (
    <div>
      <div className={classNames(props.show && 'hidden')}>
        <NavBar />
        <NavbarMobile />
      </div>
      <main className="flex">
        <div className={classNames(props.show ? 'hidden ' : '')}>
          <SideBar />
        </div>
        <div className="w-full">{props.children}</div>

        <div className={classNames(props.showSideBarRight ? '' : 'hidden', 'w-[550px]')}>
          <DetailsUserContainer />
        </div>
      </main>
    </div>
  )
}

export default Layout
