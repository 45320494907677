import React from 'react'
import imageHome from '../../assets/imageHome.svg'
import imagePaimentHomeGroupe from '../../assets/imagePaimentHomeGroupe.svg'
import { Link } from 'react-router-dom'
import appStoreIcon from '../../assets/appStoreIcon.svg'
import playStoreIcon from '../../assets/playStoreIcon.svg'
import newImageHomePage from '../../assets/newImageHomePage.svg'
import imageSecondHomeResponsive from '../../assets/imageSecondHomeResponsive.svg'

const ImageHomeContainer = () => {

  return (
    <div className="flex justify-center items-center 3xl:w-[685px] 2lg:w-[600px] xl:w-[550px] max-w-max h-[250px] xxs:w-[400px] 5xl:w-auto pr-1 pl-8 bg-[#E2E2FB] sm:h-[145px] mr-[30px] ml-[40px] 2xs:mr-0  xs:mr-0 xs:ml-7 2md:ml-5 lg:mt-[40px] 2lg:mt-[140px] sm:w-[322px] 2md:w-[285px] rounded-2xl">
      <div className="flex flex-col">
        <div className="h-[200px] sm:h-auto">
          <div className="sm:text-[20px] 8xl:text-[23px] 3xl:text-[24px] 2lg:text-[25px] 6xl:text-[20px] text-[45px] 2xl:text-[23px] xl:text-[18px] font-bold sm:mt-[19px]">
            My Easy Transfer
          </div>
          <div className="sm:text-[13px] 2lg:text-[13px] 2lg:pr-5 text-[14px] xl:text-[12px] text-[#898795] sm:mt-[5px] mt-[14px] leading-[24px] sm:leading-[1rem] font-sans sm:w-[180px]">
            Envoyez votre argent quand vous voulez où vous voulez!
          </div>
          <div className="flex flex-row sm:hidden xs:hidden 2sm:hidden">
            <img className="w-32 h-32" src={appStoreIcon} />
            <img className="w-32 h-32 ml-5" src={playStoreIcon} />
          </div>

          <button
            className="sm:block xs:block 2sm:block 2xl:hidden 3xl:hidden 4xl:hidden 7xl:hidden 6xl:hidden  bg-[#4743C9] mt-[30px] sm:my-3 2xl:text-[13px] xl:text-[10px] xl:w-auto xl:ml-5 2xl:ml-[30px] ml-[50px] sm:ml-0 sm:text-[12px] 2lg:text-[15px] sm:p-2 xs:p-2 sm:rounded-xl rounded-3xl p-[11px] text-[#FFF] max-w-max"
            onClick={() => (window.location.href = 'https://myeasytransfer.com/redirection')}
          >
            Téléchargez maintenant
          </button>
        </div>
      </div>
      <div className="flex items-start mt-[50px]">
        <img src={imagePaimentHomeGroupe} className="sm:hidden xs:hidden 2sm:hidden max-w-max" />
        <img
          src={newImageHomePage}
          className="sm:hidden xs:hidden 2sm:hidden transform translate-y-[-60px] sm:transform sm:translate-y-[-40px] h-[320px] 6xl:h-[318px] 6xl:rounded-2% max-w-max sm:h-[175px]"
        />
        <img
          src={imageSecondHomeResponsive}
          className="sm:block xs:block 2sm:block 2xl:hidden 3xl:hidden 4xl:hidden 7xl:hidden 6xl:hidden transform translate-y-[-60px] sm:transform sm:translate-y-[-40px] h-[320px] 6xl:h-[318px] 6xl:rounded-2% max-w-max sm:h-[175px]"
        />
      </div>
    </div>
  )
}

export default ImageHomeContainer
