import UserContext from 'contexts/UserContext'
import React, { useContext, useState } from 'react'

const InformationPersonnellesProfile = () => {
  const { user } = useContext(UserContext)
  
  const dateString = user?.user?.birthDate
  const datePart = dateString ? dateString.split('T')[0] : null

  return (
    <div>
      <div className="border rounded-lg p-2 mt-5 flex flex-col">
        <div className="flex justify-between pt-3">
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">Identifiant My Easy</div>
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">{user?.user?.order}</div>
        </div>
        <div className="flex justify-between pt-5">
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">Nom</div>
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">{user?.user?.personalInfo?.firstName}</div>
        </div>
        <div className="flex justify-between pt-5">
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">Prénom</div>
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">{user?.user?.personalInfo?.lastName}</div>
        </div>
        <div className="flex justify-between pt-5">
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">Genre</div>
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">
            {user?.user?.personalInfo?.gender === 'female' ? 'Femme' : user?.user?.personalInfo?.gender === 'male' ? 'Homme' : ''}
          </div>
        </div>
        <div className="flex justify-between pt-5">
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">Date de naissance</div>
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">
            {user?.user?.birthDate? datePart : ''}
          </div>
        </div>
        <div className="flex justify-between pt-5">
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">Pays de naissance</div>
          <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">{user?.user?.countryOfBirth?.name}</div>
        </div>
      </div>
    </div>
  )
}
export default InformationPersonnellesProfile
