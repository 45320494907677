import BenificePerson from 'components/BenificePerson'
import RightDash from 'components/RightDash'
import UserContext from 'contexts/UserContext'
import BeneficiaryContext from 'contexts/beneficiaryContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getListBeneficary } from 'requests/benificary'

import avatar1 from '../../assets/avatar1.svg'
import avatar2 from '../../assets/avatar2.svg'
import avatar3 from '../../assets/avatar3.svg'
import avatar4 from '../../assets/avatar4.svg'
import avatar5 from '../../assets/avatar5.svg'
import avatar6 from '../../assets/avatar6.svg'
import avatar7 from '../../assets/avatar7.svg'
import avatar8 from '../../assets/avatar8.svg'
import avatar9 from '../../assets/avatar9.svg'
import avatar10 from '../../assets/avatar10.svg'
import avatar11 from '../../assets/avatar11.svg'
import avatar12 from '../../assets/avatar12.svg'
import avatar13 from '../../assets/avatar13.svg'
import avatar14 from '../../assets/avatar14.svg'
import avatar15 from '../../assets/avatar15.svg'
import avatar16 from '../../assets/avatar16.svg'
import avatar17 from '../../assets/avatar17.svg'
import avatar18 from '../../assets/avatar18.svg'
import avatar19 from '../../assets/avatar19.svg'
import avatar20 from '../../assets/avatar20.svg'
import avatar21 from '../../assets/avatar21.svg'
import avatar22 from '../../assets/avatar22.svg'
import avatar23 from '../../assets/avatar23.svg'
import avatar24 from '../../assets/avatar24.svg'
import pointExclamation from '../../assets/pointExclamationIcon.svg'
import iconHomePage from '../../assets/iconHomePage.svg'

import newDefaultImageFemme from '../../assets/newDefaultImageFemme.svg'
import newDefaultImageHomme from '../../assets/newDefaultImageHomme.svg'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'

const DetailsUserContainer = () => {
  const { user } = useContext(UserContext)
  const [indexBeneficiary, setIndexBeneficiairy] = useState(-1)
  const { beneficiaries, setBeneficiaries } = useContext(BeneficiaryContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const [person, setPerson] = useState([])
  const [loading, setLoading] = useState(false)

  const dataAvatar = [
    { index: 1, image: avatar1 },
    { index: 2, image: avatar2 },
    { index: 3, image: avatar3 },
    { index: 4, image: avatar4 },
    { index: 5, image: avatar5 },
    { index: 6, image: avatar6 },
    { index: 7, image: avatar7 },
    { index: 8, image: avatar8 },
    { index: 9, image: avatar9 },
    { index: 10, image: avatar10 },
    { index: 11, image: avatar11 },
    { index: 12, image: avatar12 },
    { index: 12, image: avatar13 },
    { index: 14, image: avatar14 },
    { index: 15, image: avatar15 },
    { index: 16, image: avatar16 },
    { index: 17, image: avatar17 },
    { index: 18, image: avatar18 },
    { index: 19, image: avatar19 },
    { index: 20, image: avatar20 },
    { index: 21, image: avatar21 },
    { index: 22, image: avatar22 },
    { index: 23, image: avatar23 },
    { index: 24, image: avatar24 },
  ]

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await getListBeneficary(id, token)
        if (response?.success === true) {
          setPerson(response?.beneficiaries)
          setLoading(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  return (
    <div>
      <div className="flex flex-col justify-center items-center relative shadow mr-[20px] 2xl:mr-[25px] 2xl:ml-0 2lg:mt-[140px]  6xl:mr-5 py-5 xs:hidden sm:hidden 2xl:block">
        <RightDash />
        <div className="text-center">
          {user?.user?.status === 'pending' && !user?.user?.isDocumentSent ? (
            <Link
              to="/personalinformation"
              className="text-red-500 flex items-center text-[15px] 8xl:px-2 lg:text-[13px] font-medium 6xl:text-[12px] 7xl:whitespace-nowrap 7xl:text-[13px] 6xl:whitespace-nowrap"
            >
              <img src={pointExclamation} className="w-6 h-6 mr-1" />
              <span>Veuillez compléter vos informations !</span>
            </Link>
          ) : (
            ''
          )}
          {user?.user?.status === 'pending' && user?.user?.isDocumentSent ? (
            <span className="text-orange-500 text-[15px] 8xl:px-2 lg:text-[13px] font-medium">
              Votre compte est en cours de validation
            </span>
          ) : (
            ''
          )}
          {user?.user?.status === 'confirmed' && user?.user?.isDocumentSent ? (
            <div className="text-green-500 text-[15px] 8xl:px-2 lg:text-[13px] font-medium mt-3 mx-3 5xl:text-center">
              Félicitations, vous pouvez envoyer de l&apos;argent
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="mt-[38px] flex flex-col gap-[34px] px-[20px]">
          <div className={`flex justify-between items-center gap-[25px] 5xl:gap-[15px]`}>
            <div className="text-[18px] 7xl:text-[14px] lg:text-[13px] font-semibold">Mes Bénéficiaires</div>
            <Link
              className={`${person.length === 0 ? 'hidden' : 'block'} text-[#B4B4B4] text-sm lg:text-[12px]`}
              to={'/beneficiary'}
            >
              Voir tout
            </Link>
          </div>
          {person.length === 0 ? (
            <div className='border-dotted border-2 border-[#C7C7C7] rounded-lg p-4 flex flex-col items-center justify-center'>
              <img src={iconHomePage} className='w-8 h-8 my-3'/>
              <div className='tetx-[#6B6B6B] font-medium text-[14px]'>Vous n&lsquo;avez pas encore ajouté de bénéficiaire.</div>
            </div>
          ) : (
            <div className="flex flex-col gap-[34px]">
              {person.slice(0, 4).map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    setBeneficiaries(item)
                    setTransferData({
                      ...TransferData,
                      BeneficiaryName: `${item?.personalInfo?.firstName} ${item?.personalInfo?.lastName}`,
                      idBeneficiary: item?._id,
                    })
                  }}
                >
                  <BenificePerson
                    nom={`${item?.personalInfo?.firstName} ${item?.personalInfo?.lastName}`}
                    num={`${
                      item?.personalInfo?.phone.prefix === '212'
                        ? '+212'
                        : item?.personalInfo?.phone.prefix === '216'
                        ? '+216'
                        : item?.personalInfo?.phone.prefix
                    }
                  ${item?.personalInfo?.phone.number}`}
                    photo={
                      dataAvatar.some((v: any) => v.index === item.avatar)
                        ? dataAvatar.find((v) => v.index === item.avatar)?.image ?? newDefaultImageHomme
                        : item?.personalInfo?.gender === 'female' && !item.avatar
                        ? newDefaultImageFemme
                        : newDefaultImageHomme
                    }
                    index={item?._id}
                    person={person}
                    setIndexBeneficiairy={setIndexBeneficiairy}
                    indexBeneficiary={indexBeneficiary}
                    item={item}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DetailsUserContainer
