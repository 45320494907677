/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { Beneficiary } from 'types/beneficiary'

  interface BeneficiaryContextProps {
    beneficiaries: Beneficiary | null
    setBeneficiaries: (beneficiaries: Beneficiary | null) => void
  }
  
  const BeneficiaryContext = createContext<BeneficiaryContextProps>({
    beneficiaries: null,
    setBeneficiaries: () => {},
  })
  
  export default BeneficiaryContext