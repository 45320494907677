import UserContext from 'contexts/UserContext'
import React, { useContext, useEffect, useState } from 'react'
import imageEasyParinage from '../../assets/imageEasyParinage.svg'
import iconCopie from '../../assets/iconCopie.svg'
import EasyParinagePageModal from './easyParinagePageModal'
import copieCodeParinageIcon from '../../assets/copieCodeParinageIcon.svg'
import { GetReferralCode, ReferralCodeParinage } from 'requests/user'
import localforage from 'localforage'

export const EasyParinagePage = () => {
  const { user, setUser } = useContext(UserContext)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [textIsCopieIcon, setTextIsCopieIcon] = useState(false)
  const [textIsCopie, setTextIsCopie] = useState(false)

  const [codeParinage, setCodeParinage] = useState('')

  const [btnGenerateCode, setBtnGenerateCode] = useState(false)

  const functionReferralCode = async () => {
    const userId = user?.user?._id
    try {
      const response = await ReferralCodeParinage(userId)
      if (response?.success === true) {
        console.log('response success Referral Code Parinage:', response)
      } else {
        console.error('response failed Referral Code Parinage', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const functionGetReferralCode = async () => {
    const userId = user?.user?._id
    const token = user?.token?.accessToken
    try {
      const response = await GetReferralCode(userId, token)
      if (response?.success === true) {
        setCodeParinage(response?.code?.code)
      } else {
        console.error('response failed Get Referral Code', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const generateCode = () => {
      if (!user?.user?.isCodeCreator) {
        functionReferralCode()
      } else {
        functionGetReferralCode()
      }
    }
    generateCode()
  }, [btnGenerateCode])


  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(codeParinage)
      setTextIsCopieIcon(!textIsCopieIcon)
    } catch (err) {
      console.error('Erreur lors de la copie dans le presse-papiers', err)
    }
  }

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(codeParinage)
      setTextIsCopie(!textIsCopie)
    } catch (err) {
      console.error('Erreur lors de la copie dans le presse-papiers', err)
    }
  }

  return (
    <div>
      <div className="flex flex-col justify-center items-center mt-8">
        <img src={imageEasyParinage} className="w-[201px] h-[190px]" />
        <span className="text-[24px] sm:text-[15px] font-bold mt-5 text-[#1D3A70]">
          Bénéficiez de 3 Transferts <span className="text-[24px] font-bold text-[#5754E5]">Gratuits</span> !
        </span>
        <div className="text-[#6B7280] text-[18px] sm:text-[13px] mt-5">
          Partagez votre code de parrainage et gagnez 3 Transferts
        </div>

        <div className={`w-full rounded-lg mt-6 p-3`}>
          {!user?.user?.isCodeCreator && !btnGenerateCode ? (
            <button
              className={`${
                btnGenerateCode ? 'hidden' : 'block'
              }font-bold text-[18px] sm:text-[13px] text-center mx-auto flex justify-center hover:cursor-pointer p-2 rounded-lg bg-[#5754E5] text-[#F9FAFB]`}
              onClick={() => {
                setBtnGenerateCode(true), functionGetReferralCode()
              }}
            >
              Générer un code
            </button>
          ) : (
            <div className="flex justify-between items-center p-2 rounded-lg bg-[#F9FAFB]">
              <img
                src={textIsCopieIcon ? copieCodeParinageIcon : iconCopie}
                className="pr-3 w-10 h-10 hover:cursor-pointer"
                onClick={copyToClipboard}
              />
              <div className="pr-3 text-[#9CA3AF] font-bold text-[18px] sm:text-[13px]">{codeParinage}</div>
              <button
                className={`font-bold text-[15px] sm:text-[13px] hover:cursor-pointer
                  ${textIsCopie ? 'text-[#5754E5]' : 'text-[#000000] '}
                  `}
                onClick={copyText}
              >
                Copier
              </button>
            </div>
          )}
        </div>

        <div className="w-[360px] text-center text-[15px] sm:text-[12px] text-[#6B7280] mt-10 sm:mt-5">
          Partagez votre code avec vos proches. S&apos;ils créent un compte et effectuent une transaction, vous
          bénéficiez de 3 transferts gratuits!
        </div>
      </div>
      {/* <EasyParinagePageModal isOpen={isModalOpen} isModalOpen={isModalOpen} closeModal={closeModal}/> */}
    </div>
  )
}
