import React, { useEffect, useState, useContext } from 'react'
import UserContext from 'contexts/UserContext'
import { getListBeneficary } from '../requests/benificary'
import ModalBeneficiary from './beneficiaireContainer/modalBeneficiary'
import ModalAddBeneficiary from './beneficiaireContainer/modalAddBeneficiary'
import iconAddBnf from '../assets/iconAddBnf.svg'
import btnDeleted from '../assets/btnDeleted.svg'
import ModalDeleteBeneficiary from './beneficiaireContainer/modalDeleteBeneficiary'

import newDefaultImageFemme from '../assets/newDefaultImageFemme.svg'
import newDefaultImageHomme from '../assets/newDefaultImageHomme.svg'

import avatar1 from '../assets/avatar1.svg'
import avatar2 from '../assets/avatar2.svg'
import avatar3 from '../assets/avatar3.svg'
import avatar4 from '../assets/avatar4.svg'
import avatar5 from '../assets/avatar5.svg'
import avatar6 from '../assets/avatar6.svg'
import avatar7 from '../assets/avatar7.svg'
import avatar8 from '../assets/avatar8.svg'
import avatar9 from '../assets/avatar9.svg'
import avatar10 from '../assets/avatar10.svg'
import avatar11 from '../assets/avatar11.svg'
import avatar12 from '../assets/avatar12.svg'
import avatar13 from '../assets/avatar13.svg'
import avatar14 from '../assets/avatar14.svg'
import avatar15 from '../assets/avatar15.svg'
import avatar16 from '../assets/avatar16.svg'
import avatar17 from '../assets/avatar17.svg'
import avatar18 from '../assets/avatar18.svg'
import avatar19 from '../assets/avatar19.svg'
import avatar20 from '../assets/avatar20.svg'
import avatar21 from '../assets/avatar21.svg'
import avatar22 from '../assets/avatar22.svg'
import avatar23 from '../assets/avatar23.svg'
import avatar24 from '../assets/avatar24.svg'

type Beneficiary = {
  id: number
  fullName: string
  numeroTel: string
  image: string
}

const Beneficiary = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalOpenDelete, setIsModalOpenDelete] = useState<boolean>(false)
  const [beneficiaryData, setBeneficiaryData] = useState<any>([])
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [indexBeneficiary, setIndexBeneficiary] = useState(-1)

  const dataAvatar = [
    { index: 1, image: avatar1 },
    { index: 2, image: avatar2 },
    { index: 3, image: avatar3 },
    { index: 4, image: avatar4 },
    { index: 5, image: avatar5 },
    { index: 6, image: avatar6 },
    { index: 7, image: avatar7 },
    { index: 8, image: avatar8 },
    { index: 9, image: avatar9 },
    { index: 10, image: avatar10 },
    { index: 11, image: avatar11 },
    { index: 12, image: avatar12 },
    { index: 12, image: avatar13 },
    { index: 14, image: avatar14 },
    { index: 15, image: avatar15 },
    { index: 16, image: avatar16 },
    { index: 17, image: avatar17 },
    { index: 18, image: avatar18 },
    { index: 19, image: avatar19 },
    { index: 20, image: avatar20 },
    { index: 21, image: avatar21 },
    { index: 22, image: avatar22 },
    { index: 23, image: avatar23 },
    { index: 24, image: avatar24 },
  ]

  const [newImage, setNewImage] = useState()

  const [newImageSelected, setNewImageSelected] = useState(false)

  const [indexBeneficiaryDeleted, setIndexBeneficiaryDeleted] = useState(-1)

  const [isAdded, setIsAdded] = useState(false)

  const [selectedImageId, setSelectedImageId] = useState<number | null>(null)

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModalDelete = (id: any) => {
    setIsModalOpenDelete(true)
    setIndexBeneficiaryDeleted(id)
  }
  const closeModalDelete = () => {
    setIsModalOpenDelete(false)
  }

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await getListBeneficary(id, token)
        if (response?.success === true) {
          setBeneficiaryData(response?.beneficiaries)
          setLoading(true)
        }
      } catch (error) {
        console.error('Error fetching beneficiary data:', error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user, isAdded])

  return (
    <div className="flex justify-around sm:justify-center xs:justify-center md:justify-center">
      <div className="flex flex-col justify-center items-center mx-auto">
        <div className="text-left sm:text-[20px] sm:w-[300px] sm:text-center sm:font-bold sm:ml-[15px] sm:mt-[20px] text-[#1D3A70] text-[35px] font-semibold">
          Mes Bénéficiaires
        </div>

        <button
          className="p-4 mt-[35px] my-[25px] text-white font-semibold text-[17px] bg-[#4743C9] w-[375px] rounded-xl md:hidden"
          onClick={openModal}
        >
          Ajouter un bénéficiaire
        </button>

        <div className="relative grid grid-cols-3 3xl:grid-cols-2 customIpadAir:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 customMaxScreen:grid-cols-4 2xl:grid-cols-2 maxScreen:grid-cols-4 mt-3 2xs:gap-[2rem]">
          {beneficiaryData.map((item: any, index: number) => (
            <div key={index} className="mx-5">
              <div
                className={`w-[250px] h-auto sm:w-[280px] xs:w-[250px] 2xs:w-[255px] ${
                  index === indexBeneficiary ? 'xs:mb-[2rem]' : ''
                }`}
              >
                <div
                  className={`w-full h-[200px] px-4 py-2 bg-[#4743c948] shadow-md rounded-xl overflow-hidden first-letter:first-line transition-transform ease-out duration-300 ${
                    index === indexBeneficiary ? 'mb-[-3rem] mt-[2rem]' : 'my-[25px] '
                  }`}
                >
                  {item?.avatar ? (
                    <>
                      {dataAvatar.some((v: any) => v.index === item.avatar) ? (
                        <img
                          src={dataAvatar.find((v) => v.index === item.avatar)?.image}
                          className={`w-full h-full object-contain rounded-lg`}
                        />
                      ) : (
                        <img
                          src={item?.personalInfo?.gender === 'male' ? newDefaultImageHomme : newDefaultImageFemme}
                          className={`w-full h-full object-contain rounded-lg`}
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={item?.personalInfo?.gender === 'male' ? newDefaultImageHomme : newDefaultImageFemme}
                      className={`w-full h-full object-contain rounded-lg`}
                    />
                  )}
                </div>
              </div>

              <div
                className={`relative bg-[#4743c980] mb-[1rem] sm:mb-1 rounded-xl flex items-center transition-transform ease-out duration-300
                    transform: ${index === indexBeneficiary ? 'translate-y-[7rem]' : 'translate-y-[-3rem]'}`}
              >
                <div className="absolute z-50 bg-[#4945c12f] backdrop-blur-md w-[250px] sm:w-[280px] xs:w-[250px] 2xs:w-[255px] rounded-xl ">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center ml-[1rem]">
                      <div className="py-2">
                        <div
                          className={`font-bold mt-2 text-[19px]`}
                          title={`${item?.personalInfo?.firstName} ${item?.personalInfo?.lastName}`}
                        >
                          {`${item?.personalInfo?.lastName}`.length > 8
                            ? `${item?.personalInfo?.firstName?.slice(0, 15)}...`
                            : `${item?.personalInfo?.firstName} ${item?.personalInfo?.lastName}`}
                        </div>

                        <div className="font-medium text-[12px]">
                          {item?.personalInfo?.phone.prefix === '+212' || item?.personalInfo?.phone.prefix === '212'
                            ? '+212'
                            : item?.personalInfo?.phone.prefix === '+216' || item?.personalInfo?.phone.prefix === '216'
                            ? '+216'
                            : ''}{' '}{item?.personalInfo?.phone.number}
                        </div>
                      </div>
                    </div>
                    {index !== indexBeneficiaryDeleted ? (
                      <div
                        className="px-2 hover:cursor-pointer"
                        onClick={() => {
                          openModalDelete(item), setIndexBeneficiaryDeleted(index)
                        }}
                      >
                        <img src={btnDeleted} className="w-10 h-10" />
                      </div>
                    ) : (
                      <div
                        className="px-2 hover:cursor-pointer"
                        onClick={() => {
                          openModalDelete(item), setIndexBeneficiaryDeleted(-1)
                        }}
                      >
                        <img src={btnDeleted} className="w-10 h-10" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <ModalDeleteBeneficiary
                isOpen={index === indexBeneficiaryDeleted}
                closeModal={closeModalDelete}
                idBnf={item?._id}
                isModalOpenDelete={isModalOpenDelete}
                setBeneficiaryData={setBeneficiaryData}
                isAdded={isAdded}
                setIsAdded={() => setIsAdded(!isAdded)}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center mb-[50px]">
          <div
            className="absolute right-[8%] top-1/2 4xl:hidden sm:block 2sm:right-[4%] 2sm:ml-10 2sm:w-14 2sm:h-14 lg:block 2xs:mt-[50px]"
            style={{ transform: 'translateY(-50%)' }}
          >
            <img className="" src={iconAddBnf} onClick={openModal} />
          </div>

          <ModalBeneficiary isOpen={isModalOpen} closeModal={closeModal}>
            <ModalAddBeneficiary
              setBeneficiaryData={setBeneficiaryData}
              closeModal={closeModal}
              newImage={newImage}
              setNewImage={setNewImage}
              setNewImageSelected={setNewImageSelected}
              setIsAdded={() => setIsAdded(!isAdded)}
              selectedImageId={selectedImageId}
              setSelectedImageId={setSelectedImageId}
            />
          </ModalBeneficiary>
        </div>
      </div>
    </div>
  )
}
export default Beneficiary
