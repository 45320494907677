/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { UserLog } from 'types/User'

 export default createContext<{
   user: UserLog | null
   setUser: (user: UserLog |  null) => void
 }>({
   user: null,
   setUser: () => {},
 })


