import React, { useContext, useEffect, useRef, useState } from 'react'
import { RechargeList, TransactionsList } from '../requests/transfer'
import UserContext from 'contexts/UserContext'
import axios from 'axios'
import HistoryTable from './historyContainer/historyTable'
import firstImage from '../assets/firstImage.jpg'
import imageProfileSetting from '../assets/imageProfileSetting.svg'
import HistoryTableRecharge from './historyContainer/historyTableRecharge'
import SecureCardTransfert from 'components/transfertComponent/secureCardTransfert'
import { historyinvoices } from 'requests/paiement'
import HistoryTablePaiement from './historyContainer/historyTablePaiement'
import iconlist from '../assets/iconlist.svg'

const History = () => {
  const [transactionData, setTransactionData] = useState<any>([])
  const [rechargeData, setrechargeData] = useState<any>([])
  const [isDataTransactionVide, setIsDataTransactionVide] = useState(false)

  const [isTransactionList, setIsTransactionList] = useState(true)

  const [isDataTransfertVide, setIsDataTransfertVide] = useState(false)
  const [legnth, setLength] = useState<any>(0)

  const [invoicesData, setInvoicesData] = useState<any>([])
  const [isDataInvoicesVide, setIsDataInvoicesVide] = useState(false)

  const [isBankAccount, setIsBankAccount] = useState([])

  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [activeText, setActiveText] = useState(1)

  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  const divRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const toggleSetting = () => {
    setShowOptions(false)
  }
  const handleClickOutside = (event: any) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      toggleSetting()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleIconClick = () => {
    setShowOptions(!showOptions)
  }

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option)
    setShowOptions(false)
  }

  const listHistory = [
    {
      id: 1,
      name: "Transfert d'argent",
    },
    {
      id: 2,
      name: 'Recharge Mobile',
    },
    {
      id: 3,
      name: 'Transfert vers un compte bancaire',
    },
    {
      id: 4,
      name: 'Paiement de factures',
    },
  ]
  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await RechargeList(id, token)
        if (response?.success === true) {
          setrechargeData(response?.recharges)
          setLoading(true)
          setIsDataTransfertVide(response?.recharges.length === 0)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await TransactionsList(id, token)
        if (response?.success === true) {
          setTransactionData(response.transfers)
          setLength(response.transfers.length)
          setIsBankAccount(response.transfers.filter((item: any) => item?.distributionChannel === 'bank_account'))
        }
        if (response.transfers.length === 0) {
          setIsTransactionList(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  useEffect(() => {
    async function fetchData() {
      const id = user?.user?._id
      const token = user?.token?.accessToken
      try {
        const response = await historyinvoices(id, token)
        if (response?.success === true) {
          //console.log('response invoices', response)
          setInvoicesData(response?.invoices)
          setLength(response?.invoices?.length)
          setLoading(true)
          setIsDataInvoicesVide(response?.invoices.length === 0)
        }
      } catch (error) {
        console.error('Error fetching beneficiary data:', error)
      }
    }
    if (user) {
      fetchData()
    }
  }, [user])

  const handleTextClick = (textNumber: any) => {
    setActiveText(textNumber)
  }

  return (
    <div>
      <div className="flex sm:flex-col 2xl:mt-[3rem] md:mt-0" ref={divRef}>
        <div className="text-gray-900 text-[50px] sm:text-[25px] lg:text-[33px] lg:ml-[3rem] sm:m-[2rem] font-bold leading-8">
          Historique
        </div>
      </div>

      <div className="relative my-[55px] ml-5 mr-10 sm:ml-3" ref={dropDownRef}>
        <input
          type="text"
          value={selectedOption}
          name="recherche"
          placeholder="Transfert d’argent"
          onClick={() => {
            handleIconClick()
          }}
          readOnly
          className="border-2 border-[#0C266C] hover:cursor-pointer rounded-md px-2 py-3 w-full sm:w-[350px] text-[#0C266C] text-[18px] font-bold placeholder:text-gray-500"
        />
        <img src={iconlist} onClick={handleIconClick} className="absolute hover:cursor-pointer right-0 top-0 mr-4 mt-6 sm:mt-6 sm:right-0" />

        {showOptions && (
          <div
            className="absolute top-full hover:cursor-pointer left-0 bg-white border border-gray-200 rounded-md w-full sm:w-[350px] z-10 shadow-lg"
          >
            {listHistory.map((item: any, index: number) => (
              <div
                key={index}
                onClick={() => {
                  setShowOptions(false)
                  handleOptionSelect(item.name)
                  handleTextClick(item.id)
                }}
                className="text-[#0C266C] text-[16px] font-bold hover:bg-gray-100 py-2 pl-1"
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>

      {activeText === 1 && (
        <div>
          {legnth !== 0 ? (
            <HistoryTable
              transactionData={transactionData.filter((item: any) => item?.distributionChannel === 'cash_pickup')}
            />
          ) : !isTransactionList ? (
            <div className="border-2 border-dashed border-[#5754E5] sm:w-[330px] ml-5 mr-10 mt-[55px] sm:mt-[25px] w-auto sm:max-w-max sm:text-[14px] 2sm:text-[12px] 2sm:w-[300px] 2sm:mb-5 text-[20px] text-center text-[#6B6B6B] rounded-2xl py-3 px-2">
              Aucun transfert n&apos;a été effectué jusqu&apos;à présent. Commencez maintenant !
            </div>
          ) : null}
        </div>
      )}

      {activeText === 2 && (
        <div>
          {isDataTransfertVide ? (
            <div className="border-2 border-dashed border-[#5754E5] sm:w-[330px] ml-5 mr-10 mt-[55px] sm:mt-[25px] w-auto sm:max-w-max sm:text-[14px] 2sm:text-[12px] 2sm:w-[300px] 2sm:mb-5 text-[20px] text-center text-[#6B6B6B] rounded-2xl py-3 px-2">
              Aucune recharge mobile n&apos;a été effectuée jusqu&apos;à présent. Commencez maintenant !
            </div>
          ) : (
            <HistoryTableRecharge rechargeData={rechargeData} />
          )}
        </div>
      )}

      {activeText === 3 && (
        <div>
          {isBankAccount.length !== 0 ? (
            <HistoryTable
              transactionData={transactionData.filter((item: any) => item?.distributionChannel === 'bank_account')}
            />
          ) : (
            <div className="border-2 border-dashed border-[#5754E5] sm:w-[330px] ml-5 mr-10 mt-[55px] sm:mt-[25px] w-auto sm:max-w-max sm:text-[14px] 2sm:text-[12px] 2sm:w-[300px] 2sm:mb-5 text-[20px] text-center text-[#6B6B6B] rounded-2xl py-3 px-2">
              Aucun paiement de factures n&apos;a été effectué jusqu&apos;à présent. Commencez maintenant !
            </div>
          )}
        </div>
      )}

      {activeText === 4 && (
        <div>
          {isDataInvoicesVide ? (
            <div className="border-2 border-dashed border-[#5754E5] sm:w-[330px] ml-5 mr-10 mt-[55px] sm:mt-[25px] w-auto sm:max-w-max sm:text-[14px] 2sm:text-[12px] 2sm:w-[300px] 2sm:mb-5 text-[20px] text-center text-[#6B6B6B] rounded-2xl py-3 px-2">
              Aucun paiement de factures n&apos;a été effectué jusqu&apos;à présent. Commencez maintenant !
            </div>
          ) : (
            <HistoryTablePaiement invoicesData={invoicesData} />
          )}
        </div>
      )}
    </div>
  )
}

export default History
