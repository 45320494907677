import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import imageSignIn from '../assets/imageSignIn.svg'
import imageLoginResponsive from '../assets/imageLoginResponsive.svg'
import Logo from '../assets/logo.png'
import ArrowRight from '../assets/ArrowRight.png'
import renitialiserPassword from '../assets/renitialiserPassword.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { activateAccount } from 'helpers/api'
import LogoConatiner from './signUpContainer/logoContainer'
import logoSignup from '../assets/logoSignup.svg'
import newImageLogin from '../assets/newImageLogin.svg'

const Activation: React.FC = () => {
  const [isInputClicked, setIsInputClicked] = useState(true)

  const { token } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      try {
        activateAccount(token).then((response: any) => {
          if (response?.success === true) {
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
            const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1

            if (isIOS) {
              window.open('https://apps.apple.com/ci/app/my-easy-transfer/id1572782943', '_blank')
            } 
            else if (isAndroid){
              window.open('https://play.google.com/store/apps/details?id=com.myeasytransfert&hl=en_US', '_blank')
            } else{
              navigate('/login')
            }
          } 
        })
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  
  return (
    <div className="flex sm:flex-col sm:gap-y-[27px] h-screen ">
      <div className="w-[50%] sm:w-[358px] sm:mx-[16px] sm:h-[450px] ">
        <LogoConatiner image={logoSignup} />
        <div className="flex flex-col justify-center mx-[186px] sm:mx-[16px]">
          <img src={renitialiserPassword} className="" />
          <div className="text-[16px] text-[#6B7280] text-center font-normal">
            Votre compte a été activé. Vous pouvez maintenant vous connecter en utilisant votre email et mot de passe.
          </div>
        </div>

        {/* <div className="flex justify-center mt-[20px]">
          <Link
            to="/login"
            className="text-white w-1/2 bg-[#5754E5] text-center px-[14px] py-4 text-[15px] font-medium rounded-xl "
          >
            Se Connecter
          </Link>
        </div> */}
      </div>

      <img
        className={classNames(
          isInputClicked ? ' hidden sm:flex w-[50%] sm:w-[330px] sm:mt-[30px] sm:mx-[30px] sm:order-first ' : 'hidden',
        )}
        src={imageLoginResponsive}
        alt=""
      />
      <img className="w-[50%] sm:hidden flex object-cover" src={newImageLogin} alt="" />
    </div>
  )
}
export default Activation
