import axios from '../helpers/axios'

export const getListBeneficary = (id: string | undefined, token: string | undefined) =>
  axios
    .get(`/beneficiaries/${id}`, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})

export const deleteBeneficary = (id: string | undefined, token: string | undefined, beneficiaryId: string | undefined) =>
  axios
    .post(`/beneficiaries/${id}/beneficiaries/${beneficiaryId}`, '' ,{
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {return response?.data}) 
    .catch((error) => {console.log(error)}
)


export const beneficiarieUpdateAvatar = (beneficiaryId: string | undefined, avatar: number | undefined) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/beneficiaries/updateAvatar`, { beneficiaryId: beneficiaryId, avatar: avatar })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

