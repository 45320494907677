import * as React from 'react'
import PropTypes from 'prop-types'

function TransfertIcon({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="payment-10--deposit-payment-finance-atm-transfer-dollar">
        <g id="Group 1000004058">
          <path
            id="Vector 2494"
            d="M13 20.9614V24.4999"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2495"
            d="M17.4231 19.1924V22.7308"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2496"
            d="M8.5769 19.1924V22.7308"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="Group 4527">
            <path
              id="Ellipse 1090"
              d="M5.48088 9.01914C5.2366 9.01914 5.03857 8.82111 5.03857 8.57683C5.03857 8.33255 5.2366 8.13452 5.48088 8.13452"
              stroke={fill}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Ellipse 1091"
              d="M5.48071 9.01914C5.72499 9.01914 5.92302 8.82111 5.92302 8.57683C5.92302 8.33255 5.72499 8.13452 5.48071 8.13452"
              stroke={fill}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="Group 4528">
            <path
              id="Ellipse 1090_2"
              d="M20.5192 9.01914C20.2749 9.01914 20.0769 8.82111 20.0769 8.57683C20.0769 8.33255 20.2749 8.13452 20.5192 8.13452"
              stroke={fill}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Ellipse 1091_2"
              d="M20.519 9.01914C20.7633 9.01914 20.9614 8.82111 20.9614 8.57683C20.9614 8.33255 20.7633 8.13452 20.519 8.13452"
              stroke={fill}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            id="Vector 3"
            d="M14.8814 6.61108C14.8167 6.42796 14.7168 6.26144 14.5899 6.11963C14.3199 5.818 13.9276 5.62817 13.491 5.62817H12.3498C11.6229 5.62817 11.0337 6.2174 11.0337 6.94424C11.0337 7.56271 11.4643 8.09774 12.0685 8.22991L13.806 8.60999C14.4829 8.75805 14.9653 9.35787 14.9653 10.0507C14.9653 10.865 14.3052 11.5256 13.491 11.5256H12.5081C11.8661 11.5256 11.32 11.1154 11.1176 10.5427"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2489"
            d="M13 5.62818V4.15381"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2490"
            d="M13 13.0002V11.5259"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2493"
            d="M23.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V14.6538C1.5 15.2061 1.94772 15.6538 2.5 15.6538H23.5C24.0523 15.6538 24.5 15.2061 24.5 14.6538V2.5C24.5 1.94772 24.0523 1.5 23.5 1.5Z"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  )
}
TransfertIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default TransfertIcon
