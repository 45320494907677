import React, { useContext } from 'react'
import UserContext from 'contexts/UserContext'
import Thumbs from '../assets/thumbs.svg'
import search from '../assets/search.png'
import Message from '../assets/message.svg'
import Bell from '../assets/bell.svg'
import Modif from '../assets/modif.svg'
import LogoEasy from '../assets/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import logoMyEasy from '../assets/logoMyEasy.svg'
import handlogohome from '../assets/handlogohome.svg'

const Haut: React.FC = () => {
  const { user } = useContext(UserContext)
  const Navigate = useNavigate()
  return (
    <div className="flex pt-[26px] pl-[40px] items-center lg:hidden w-full justify-between h-[50px] ">
      <img className="hover:cursor-pointer" src={logoMyEasy} onClick={()=>{Navigate('/')}}/>
      <div className="flex items-center justify-between ml-[150px] mr-auto gap-[550px] xl:gap-[200px] 2xl:gap-[400px] 2xl:ml-[195px]">
        <div className="flex items-center gap-[15px]">
          <div className="text-[21px] font-semibold h-[24px]">Bonjour {user?.user?.personalInfo?.firstName}</div>
          <img src={handlogohome} className="w-8 h-8" />
        </div>
      </div>

      {/* <img className="mr-[15px]" src={Bell} /> */}
     
        <div className="flex gap-[50px] mr-[50px] items-center">
          <div className="flex items-center">
            <div className="text-[21px] sm:text-[15px] xs:text-[15px] font-semibold mr-[15px] hover:cursor-pointer"onClick={()=>{Navigate('/settingProfile/0')}}>Mon Profil</div>
            <img src={Modif} alt="" width={40} height={40} className='hover:cursor-pointer' onClick={()=>{Navigate('/settingProfile/0')}}/>
          </div>
        </div>
  
    </div>
  )
}
export default Haut
