import React from 'react'
import AlertNotification from 'assets/alertNotification.svg'
import AlertNotificationRed from 'assets/alertNotificationRed.svg'

interface props {
  type: 'succes' | 'alert'
}

const Notifications: React.FC<props> = ({ type }) => {
  return (
    <div className="w-full top-0">
      {type === 'succes' ? (
        <img src={AlertNotification} alt="" className="right-2 absolute z-50 -top-0" />
      ) : (
        <img src={AlertNotificationRed} alt="" className="right-2 absolute z-50 -top-0" />
      )}
    </div>
  )
}

export default Notifications
