import React, { useState } from 'react'
import scanPhoto from '../../assets/scanPhoto.svg'
import iconLock from '../../assets/iconLock.svg'

interface props {
  handleClick: any
}

const PersonalInformationContainer: React.FC<props> = ({ handleClick }) => {
  const[onVerif, setOnVerif] = useState(false)

  return (
    <div className='flex flex-col items-center justify-center max-w-max'>
      <img src={scanPhoto} className="sm:w-[350px] w-[400px]" />
      <div className="text-[#1D3A70] text-[24px] font-bold text-center mt-5">Nous devons vérifier votre identité</div>
      <div className="text-[#6B7280] text-[15px] text-center">
        Pour continuer, nous avons besoin de vérifier votre identité.
      </div>
      <div className="text-[#6B7280] text-[15px] sm:text-[14px] text-center">
        Nous vous demanderons de prendre plusieurs photos de votre piéce d&apos;identité.
      </div>
      <div className="flex items-center justify-around rounded-lg bg-[#5754E533] mt-5 p-5 maw-w-max">
        <img src={iconLock} />
        <div className="text-[#0C266C] font-semibold text-[15px] sm:text-[14px] pl-4">
          Nous prenons la sécurité de vos données très au sérieux. <br /> Elles ne serons utilisées qu&apos;à des fins
          de vérification.
        </div>
      </div>
      <div className="flex justify-center">
        <button
          type="submit"
          disabled={onVerif}
          onClick={()=>{handleClick(), setOnVerif(true)}}
          className={`text-white text-[16px] font-bold p-4 w-[400px] rounded-xl sm:w-[300px] sm:text-[14px] mt-8 
          ${onVerif ? 'bg-gray-400' : 'bg-[#4743C9]'}`}>
          Vérifiez votre identité
        </button>
      </div>
    </div>
  )
}

export default PersonalInformationContainer
