import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logoTransferPaiment from '../../assets/logoTransferPaiment.svg'
import logoTransferWallet from '../../assets/logoTransferWallet.svg'
import logoTransfer from '../../assets/logoTransfer.svg'
import logoRecharge from '../../assets/logoRecharge.svg'
import FlecheBlue from '../../assets/flechebleu.svg'
import assuranceImage from '../../assets/assuranceImage.svg'

const homeBlockLinks: React.FC = () => {
  const list: { icon: any; image: any; linkTo: string; text: string }[] = [
    { icon: FlecheBlue, image: logoTransfer, linkTo: '/transfert', text: "Transfert d'argent" },
    { icon: FlecheBlue, image: logoRecharge, linkTo: '/recharge', text: 'Recharge Mobile' },
    { icon: FlecheBlue, image: logoTransferWallet, linkTo: '/transfert', text: 'Transfert vers un compte bancaire' },
    { icon: FlecheBlue, image: logoTransferPaiment, linkTo: '/paimentFacture', text: 'Paiement de factures' },
    { icon: FlecheBlue, image: assuranceImage, linkTo: '/assurance', text: 'Assurance' },
  ]

  const navigate = useNavigate()
  return (
    <div className="grid grid-cols-2 gap-5 8xl:gap-x-[65px] xl:gap-x-5 justify-center items-center">
      {list.map((item: any, index: number) => (
        <div
          key={index}
          className={`flex  5xl:w-[235px] w-[70%] sm:w-[145px] 2xl:w-[280px] 4xl:w-[300px] xxs:w-[155px] 2md:w-[115px] items-center shadow-card px-2 mt-[1rem] rounded-xl
          ${item.text === 'Transfert vers un compte bancaire' ? 'py-3' : 'py-5'}
          ${item.text === 'Assurance' ? 'bg-[#d5d5d5]' : 'bg-[#f2f2f2]'}
        `}
        >
          <div
            className={`flex items-center sm:flex-col xs:flex-col sm:items-start xs:items-start
          `}
          >
            <div
              className={`${item.text === 'Assurance' ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'}`}
              onClick={() => {
                if (item.text === 'Transfert vers un compte bancaire') {
                  navigate('/transfert/1')
                } else if (item.text === "Transfert d'argent") {
                  navigate('/transfert/0')
                } else if (item.text === 'Recharge Mobile') {
                  navigate(item.linkTo)
                }
                // else if (item.text === 'Paiement de facture') {
                //   navigate(item.linkTo)
                // } else if (item.text === 'Assurance') {
                //   navigate(item.linkTo)
                // }
              }}
            >
              <img src={item.image} alt="image transfer" className="mr-[17px] sm:w-12 sm:h-12 w-10 h-10 sm:mr-[25px]" />
            </div>

            <div
              className={`text-black sm:text-[20px] sm:h-[113px] sm:mt-[15px] text-[18px] sm:pt-2 xs:pt-2 2xl:text-[15px] font-semibold
             ${item.text === 'Transfert vers un compte bancaire' ? 'text-[16px] pl-5 sm:pl-0' : 'text-[18px] text-center sm:text-left'}
             ${item.text === 'Assurance' ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer '}
             `}
              onClick={() => {
                if (item.text === 'Transfert vers un compte bancaire') {
                  navigate('/transfert/1')
                } else if (item.text === "Transfert d'argent") {
                  navigate('/transfert/0')
                } else if (item.text === 'Recharge Mobile') {
                  navigate(item.linkTo)
                } else if (item.text === 'Paiement de facture') {
                  navigate(item.linkTo)
                } 
                // else if (item.text === 'Assurance') {
                //   navigate(item.linkTo)
                // }
              }}
            >
              {item.text}
            </div>
          </div>

          <div
            className={`ml-auto ${item.text === 'Paiement de facture' || item.text === 'Assurance' ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer '}`}
            onClick={() => {
              if (item.text === 'Transfert vers un compte bancaire') {
                navigate('/transfert/1')
              } else if (item.text === "Transfert d'argent") {
                navigate('/transfert/0')
              } else if (item.text === 'Recharge Mobile') {
                navigate(item.linkTo)
              } else if (item.text === 'Paiement de facture') {
                navigate(item.linkTo)
              } 
              // else if (item.text === 'Assurance') {
              //   navigate(item.linkTo)
              // }
            }}
          >
            <img
              className={`sm:hidden xs:hidden ${
                item.text === 'Transfert vers un compte bancaire' ? 'w-[50px] h-[50px]' : 'w-8 h-8'
              }`}
              src={item.icon}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default homeBlockLinks
