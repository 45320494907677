import classNames from 'classnames'
import React, { useState } from 'react'
import closeModalIcon from '../../assets/closeModal.svg'

import avatar1 from '../../assets/avatar1.svg'
import avatar2 from '../../assets/avatar2.svg'
import avatar3 from '../../assets/avatar3.svg'
import avatar4 from '../../assets/avatar4.svg'
import avatar5 from '../../assets/avatar5.svg'
import avatar6 from '../../assets/avatar6.svg'
import avatar7 from '../../assets/avatar7.svg'
import avatar8 from '../../assets/avatar8.svg'
import avatar9 from '../../assets/avatar9.svg'
import avatar10 from '../../assets/avatar10.svg'
import avatar11 from '../../assets/avatar11.svg'
import avatar12 from '../../assets/avatar12.svg'
import avatar13 from '../../assets/avatar13.svg'
import avatar14 from '../../assets/avatar14.svg'
import avatar15 from '../../assets/avatar15.svg'
import avatar16 from '../../assets/avatar16.svg'
import avatar17 from '../../assets/avatar17.svg'
import avatar18 from '../../assets/avatar18.svg'
import avatar19 from '../../assets/avatar19.svg'
import avatar20 from '../../assets/avatar20.svg'
import avatar21 from '../../assets/avatar21.svg'
import avatar22 from '../../assets/avatar22.svg'
import avatar23 from '../../assets/avatar23.svg'
import avatar24 from '../../assets/avatar24.svg'

interface Props {
  isOpen: boolean
  closeModal: () => void
  selectedImageId:any
  handleImageClick:any
  newImage:any
  setNewImage:any
}

const ModalImageBeneficiary: React.FC<Props> = ({ isOpen, closeModal, selectedImageId ,handleImageClick, newImage, setNewImage }) => {
  
  if (!isOpen) return null

  const dataAvatar = [
    { index: 1, image: avatar1 },
    { index: 2, image: avatar2 },
    { index: 3, image: avatar3 },
    { index: 4, image: avatar4 },
    { index: 5, image: avatar5 },
    { index: 6, image: avatar6 },
    { index: 7, image: avatar7 },
    { index: 8, image: avatar8 },
    { index: 9, image: avatar9 },
    { index: 10, image: avatar10 },
    { index: 11, image: avatar11 },
    { index: 12, image: avatar12 },
    { index: 13, image: avatar13 },
    { index: 14, image: avatar14 },
    { index: 15, image: avatar15 },
    { index: 16, image: avatar16 },
    { index: 17, image: avatar17 },
    { index: 18, image: avatar18 },
    { index: 19, image: avatar19 },
    { index: 20, image: avatar20 },
    { index: 21, image: avatar21 },
    { index: 22, image: avatar22 },
    { index: 23, image: avatar23 },
    { index: 24, image: avatar24 },
  ]


  const setNewImageAndCloseModal = () => {
    if (selectedImageId !== null) {
      const selectedImage = dataAvatar.find((image:any) => image.index === selectedImageId)
      if (selectedImage) {
        setNewImage(selectedImage.image)
        closeModal()
      }
    }
  }

  return (
    <div>
      <div className={classNames('justify-center items-center flex fixed inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm')}>
        <div className="relative my-6 mx-auto max-w-3xl">
          
        <div className={classNames('border-0 rounded-2xl pb-8 sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative xl:py-1 flex flex-col w-[452px] sm:w-[350px] h-[560px] bg-white',)}>
          
        <img
            src={closeModalIcon}
            width={20}
            height={20}
            onClick={() => {
              closeModal()
            }}
            className="right-2 absolute hover:cursor-pointer xs:w-[18px] my-[25px] mr-[20px] ml-[35px] sm:mx-2 sm:mt-2"
          />

          <div className="flex flex-col gap-[2rem] items-center mt-[15px] ml-[10px] h-[400px] sm:h-[450px] no-scrollbar overflow-y-auto">
            <div className="flex flex-wrap justify-center">
              {dataAvatar.map((imageList: any, index: number) => (
                <div key={index}>
                  <img
                    src={imageList.image}
                    className={`w-[75px] h-[75px] m-5 sm:m-2 ${selectedImageId === imageList.index ? 'border-4 rounded-full border-[#4945c1]' : ''}`}
                    onClick={() => handleImageClick(imageList.index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center mt-[8px] mb-[10px]">
            <button
              className="bg-[#4743C9] text-white font-bold text-[20px] w-[70%] rounded-xl py-4  mt-5"
              onClick={setNewImageAndCloseModal}
            >
              Ajouter
            </button>
          </div>
        </div>

          {/* <div className={classNames('border-0 rounded-2xl pb-8 sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative  xl:py-1 flex flex-col w-[452px] h-[300px] bg-white',)}>
            <div className="flex flex-col gap-[2rem] justify-center items-center h-[400px] sm:h-[450px]">
              <div className="flex flex-wrap justify-center">
                {dataAvatar.map((imageList: any, index: number) => (
                  <div key={index}>
                    <img src={imageList.image} 
                      className={`w-[75px] h-[75px] m-5 sm:m-2 ${selectedImageId === imageList.index ? 'border-4 rounded-full border-[#4945c1]' : ''}`} 
                      onClick={() => handleImageClick(imageList.index)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-[#4743C9] text-white font-bold text-[20px] w-[70%] rounded-xl py-4  mt-5"
                onClick={setNewImageAndCloseModal}
              >
                Ajouter
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ModalImageBeneficiary
