import React from 'react'
import classNames from 'classnames'

interface props {
  numStep: number
  nomStep: string
  isActive: boolean
  lastStep?: boolean
  isComplete?: boolean
}

const Step: React.FC<props> = ({ numStep, nomStep, isActive, lastStep, isComplete }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative">
        <div className="flex flex-col items-center">
          <div className={classNames(isActive ? 'bg-[#4743C9]' : 'bg-[#B4B4B4]',` text-white w-8 h-8  rounded-full flex items-center justify-center mr-2`)}>
          {numStep}
          </div>
          <div className={classNames(isActive ? 'text-[#4743C9]' : 'text-[#B4B4B4]', 'sm:text-[14px] xs:text-[14px] pt-2')}>{nomStep}</div>
        </div>
      </div>
      {lastStep && <div className={classNames(isActive ? 'bg-[#4743C9]' : 'bg-[#B4B4B4]', `mb-[25px] sm:mb-0 w-48 sm:w-16 2xl:w-32 md:w-32 7xl:w-32 2sm:w-16 sm:mr-[25px] sm:ml-0 2sm:mr-[25px] 2sm:ml-0 h-[2px] mx-2 `)}></div>}
    </div>
  )
}
export default Step
