import axios from '../helpers/axios'

export const verificationEmail = (email: string | undefined) => {
    return new Promise((resolve, reject) => {
        axios
          .post(`/users/forget-pass`, { email : email})
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
      })
    })
}
 

export const resetPassword = (password: string | undefined, token: string | undefined ) => {
    return new Promise((resolve, reject) => {
        axios
          .post(`/users/reset-pass`, { password : password}, {
            headers: {
              token: token,
              accept: 'application/json',
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
      })
    })
}
 


export const updatePassword = (userId: string | undefined, authorizationToken: string | undefined,  oldPassword: string | undefined,  password: string | undefined ) => {
    return new Promise((resolve, reject) => {
        axios
          .post(`/users/${userId}/update-pass/?token=${authorizationToken}`,
          {
            oldPassword:oldPassword,
            password:password
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
      })
    })
}

export const usersUpdateAvatar = (userId: string | undefined, avatar: number | undefined, token: any | undefined) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/users/updateAvatar`, { userId: userId, avatar: avatar }, {
        headers: {
          Authorization: token,
          accept: 'application/json',
        }
      })
      
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const ProfileRequest = (id: string | undefined, token: string | undefined) =>
axios
  .get(`users/${id}`, {
    headers: {
      Authorization: token,
      accept: 'application/json',
    },
  })
  .then((response) => {
    return response?.data
  })
  .catch((error) => {
    console.log(error)
})   

export const updateCountry = (id: string | undefined, token: string | undefined, body: any) =>
  axios
    .post(`/users/updateDefaultCountry`, {userId: id, destinationDefaultCountry: body}, {
      headers: {
        Authorization: token,
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})


export const ReferralCodeParinage = (userId: any) =>
  axios
    .post(`/referrals/referral-code`, {userId: userId}, {
      headers: {
        accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
})


export const GetReferralCode = (userId: string | undefined, token: string | undefined) =>
  axios
    .get(`/referrals/${userId}/code`, {
      headers: {
        Authorization: token,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })


    export const FindReferralCode = (code: string | undefined) =>
    axios
    .post('/referrals/find-code', {code: code}, {
      headers: {
        Accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })


    export const GetStateOfUser = (userId: string | undefined) =>
    axios
    .post('/users/stateOfdata', {userId: userId}, {
      headers: {
        Accept: 'application/json',
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
