/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { Recharge } from 'types/recharge'

interface RechargeContextProps {
    recharges: Recharge | null
    setRecharges: (recharges: Recharge | null) => void
  }
  
  const RechargeContext = createContext<RechargeContextProps>({
    recharges: null,
    setRecharges: () => {},
  })
  
  export default RechargeContext