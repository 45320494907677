import React, { useState, useRef } from 'react'
import ArrowDown from '../assets/arrowDown.svg'
import classNames from 'classnames'
import useClickOutside from '../hooks/useOnclickOutside'

interface Option {
  icon?: string
  value: string
  label: string
  background?: string
}

interface CustomSelectProps {
  options: Option[]
  value: string
  onChange: (selectedValue: string) => void
  placeholder?: string
  icon?: string
  width?: string
  height?: string
  textSize?: string
  background?: string
  showArrow?: boolean
  heightDropdown?: string
  marginRight?: string
  textColor?: string
  marginRightIcon?:string
  iconSelect?:string
  roundedIcon?:string
  phonePrefix?:any
  flag?:string
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  icon,
  width,
  height,
  textSize,
  showArrow,
  background,
  heightDropdown = 'h-[200px]',
  marginRight,
  textColor,
  marginRightIcon,
  iconSelect,
  roundedIcon,
  phonePrefix,
  flag
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (selectedValue: string) => {
    onChange(selectedValue)
    setIsOpen(false)
  }
  const divRef = useRef<HTMLDivElement>(null)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const toggleSetting = () => setIsOpen(false)

  useClickOutside(toggleSetting, dropDownRef, divRef)
  return (
    <div className={classNames(width, height, marginRight)} ref={divRef}>
      <div className={`w-full h-full hover:cursor-pointer rounded-xl text-[#6B7280] shadow-sm flex items-center justify-between ${background ? background : 'bg-gray-100'}`} onClick={handleToggle} >
        <div className='flex items-center'>
          {icon && (
          <img
            className={` h-[25px] ml-2 ${roundedIcon ? roundedIcon : 'rounded-[50%]'}`}
            src={icon}
            alt=""
            width={25}
            height={25}
          />
        )}

        {value ? (
          <span
            className={classNames(
              textSize,
              `whitespace-nowrap flex  flex-col ${textColor ? textColor : 'text-[#0C266C]'} ml-2`, 
            )}
          >
            {flag && value ? flag : value}
          </span>
        ) : (
          <span className={classNames(textSize, ' whitespace-nowrap  ml-2')}>
            {placeholder}
          </span>
        )}
        </div>
        
        {showArrow ? (
          <img src={iconSelect ? iconSelect : ArrowDown} alt="" className={`${marginRightIcon ? marginRightIcon : ''}`} width={26} height={26} />
        ) : (
          <div className="w-[40px]" />
        )}
      </div>
      {isOpen && showArrow && (
        <div
          ref={dropDownRef}
          className={classNames(
            width,
            heightDropdown,
            'absolute bg-white shadow-selct hover:cursor-pointer rounded mt-1 overflow-auto',
          )}
        >
          {options.map((option:any, index: number) => (
            <span
              key={index}
              className={` w-full h-[50px] py-2 px-2 text-[14px] sm:text-[12px] flex gap-1 items-center ${
                option.label === value 
                  ? ' text-[#32307D] bg-[#F5F5F5]'
                  : 'text-[#424242]'
              }`}
              onClick={() => handleOptionClick(option.label)}
            >
              {option?.icon && (
                <img src={option?.icon} alt="" width={20} height={20}/>
              )}
              {option.flag ? option.flag : option.label}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelect
