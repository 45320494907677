import React, { useContext } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import ProtectedRoute from 'utils/ProtuctedRoute'
import UserContext from 'contexts/UserContext'
import Home from './home'
import Recharge from './recharge'
import Login from './login'
import Signup from './singup'
import Transfert from './transfert'
import Layout from 'components/Layout'
import Settings from './settings'
import Beneficiary from './beneficiary'
import SettingProfile from './settingProfile'
import Benefiaire from './transfertContainer/beneficiare'
import History from './history'
import Personalinformation from './personalinformation'
import MotDePassOublier from './motDePassOublier'
import Activation from './activation'
import EmailVerificationContainer from './emailVerificationContainer'
import SecureCard from './secureCard'
import Success from './success'
import Error from './error'
import PaimentFacture from './paimentFacture'
import Assurance from './assuranceContainer/assurance'

const RootContainer = () => {
  const { user } = useContext(UserContext)
  const location = useLocation()

  return (

    <Layout
      show={
        location.pathname === '/login' ||
        location.pathname === '/signup' ||
        location.pathname === '/emailVerificationContainer'||
        location.pathname.startsWith('/activation') ||
        location.pathname.startsWith('/reset-pass')
      }
      showSideBarRight={
        location.pathname === '/' ||
        location.pathname === '/personalinformation' ||
        location.pathname === '/beneficiary' ||
        location.pathname === '/History' ||
        location.pathname === '/assurance' ||
        location.pathname === '/paimentFacture'
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-pass/:token" element={<MotDePassOublier />} />
        <Route path="/activation/:token" element={<Activation />} />
        <Route path="/emailVerificationContainer" element={<EmailVerificationContainer />} />

        {location.pathname !== '/activation/:token' &&  location.pathname !== "/reset-pass/:token" &&(
          <Route element={<ProtectedRoute isAuthenticated={user?.success} />}>
            <Route path="/" element={<Home />} />
            <Route path="/transfert/:code" element={<Transfert />} />
            <Route path="/recharge" element={<Recharge />} />
            <Route path="/setting" element={<Settings />} />
            <Route path="/beneficiary" element={<Beneficiary />} />
            <Route path="/settingProfile/:code" element={<SettingProfile />} />
            <Route path="/history" element={<History />} />
            <Route path="/personalinformation" element={<Personalinformation />} />
            <Route path="/secureCard" element={<SecureCard />} />
            <Route path='/success' element={<Success />} />
            <Route path='/error' element={<Error />} />
            <Route path='/paimentFacture' element={<PaimentFacture />} />
            <Route path='/assurance' element={<Assurance />} />
          </Route>
        )}

        <Route path="*" element={<div>404 not found</div>} />
      </Routes>
    </Layout>
  )
}

export default RootContainer
