import Step from 'components/Step'
import StepTransfert from 'components/stepTransfert'
import UserContext from 'contexts/UserContext'
import React, { useContext, useEffect, useState } from 'react'
import { getAssurance } from 'requests/assurance'
import Produit from './produit'
import Inscription from './inscription'
import Paiement from './paiement'
import { tracks } from 'requests/transfer'

export default function assurance() {
  const [loading, setLoading] = useState(false)
  const { user } = useContext(UserContext)

  const nomstep = ['Produit', 'Inscription', 'Paiement']
  const [numStep, setNumStep] = useState(0)

  const [produitAssurance, setProduitAssurance] = useState('')
  const [partenaireAssurance, setPartenaireAssurance] = useState('')

  const [linkAssurance, setlinkAssurance] = useState("https://portail.magassur.com.tn/Agences/MRHTRE/MRHTRE.jsp")

  const [trackId, setTrackId]= useState('')
  
  const functionTracks = async (stepNumber: string) =>{
    const id = user?.user?._id
    let dataTracks = {
      label: 'assurance',
      step: stepNumber,
      userId: id,
    }
    if (stepNumber !== '0') {
      const newDataTracks = {
        ...dataTracks,
        trackId: trackId, 
      }
      dataTracks = newDataTracks
    }
    try {
      const response = await tracks(dataTracks)
      if (response?.success === true) { 
        setTrackId(response?.trackId)
        //console.log('response track assurance:', response)
      } else {
        console.error('response failed', response)
      }
      setLoading(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(()=>{
    functionTracks('0')
  },[])


  return (
    <div
      className={`flex justify-center xl:items-center gap-[200px] 7xl:gap-5 2xl:gap-5 4xl:ml-10 xl:flex-col max-w-max 2sm:mx-auto sm:mx-auto
      ${numStep === 1 || numStep === 2 ? '3xl:gap-0' : ''}
    `}
    >
      <div className="flex flex-col lg:mt-[50px] w-auto 6xl:ml-0 3xl:ml-0 ml-auto">
        <div className={`h-[200px] sm:h-[150px] 2sm:h-[150px]`}>
          <div className="flex mt-[25px] sm:mt-4 justify-between sm:mx-4 mx-[50px] 2xl:mr-5">
            {nomstep.map((item, index) => (
              <Step key={index} nomStep={item} numStep={index + 1} isActive={index <= numStep} lastStep={index < 2} />
            ))}
          </div>
        </div>
        
        {numStep === 0 && (
          <Produit
            produitAssurance={produitAssurance}
            setProduitAssurance={(value: string) => setProduitAssurance(value)}
            partenaireAssurance={partenaireAssurance}
            setPartenaireAssurance={(value: string) => setPartenaireAssurance(value)}
            setNumStep={setNumStep}
            functionTracks={functionTracks}
          />
        )}

       {numStep === 1 && (
          <Inscription
            linkAssurance={linkAssurance}
            setNumStep={setNumStep}
            functionTracks={functionTracks}
          />
        )}

         {numStep === 2 && (
          <Paiement
           functionTracks={functionTracks}
          />
        )}
      </div>
    </div>
  )
}
