import React, { useContext, useEffect, useState } from 'react'
import imageWafaCashMaroc from '../../assets/imageWafaCashMaroc.svg'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import UserContext from 'contexts/UserContext'
import { Partner } from 'types/Transfert'

interface props {
  setNumStep: (value: number) => void
  networkCheckedMaroc: number
  setNetworkCheckedMaroc: (value: number) => void
  functionTracks: any
  setColectPointId: any
  partnersData: any
}
const PointDeCollectMaroc: React.FC<props> = ({
  setNumStep,
  networkCheckedMaroc,
  setNetworkCheckedMaroc,
  functionTracks,
  setColectPointId,
  partnersData,
}) => {
  const { user } = useContext(UserContext)

  const statusComplete = user?.user?.status === 'confirmed' && user?.user?.isDocumentSent
  const statusPending = user?.user?.status === 'pending' && user?.user?.isDocumentSent
  const statusNotFound = user?.user?.status === 'pending' && !user?.user?.isDocumentSent

  const navigate = useNavigate()

  const [isChecked, setIsChecked] = useState(true)
  const [openIsModalStatus, setOpenIsModalStatus] = useState(false)

  const handleCheckChange = () => {
    setIsChecked(!isChecked)
    setNetworkCheckedMaroc(1)
  }
  const openModalStatus = () => {
    setOpenIsModalStatus(true)
  }

  useEffect(() => {
    if (isChecked) {
      const defaultNetwork = partnersData.map((item: any) => item?._id)
      if (defaultNetwork.length > 0) {
        setColectPointId(defaultNetwork[0])
      }
    }
  }, [isChecked])

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    if (isChecked) {
      setNetworkCheckedMaroc(1)
    }
  }, [isChecked])
  return (
    <div className="">
      <div className="flex flex-col h-[400px]">
        <div className="text-[#4743C9] text-[20px] font-bold">Point de retrait</div>
        <div className="text-[#6B7280] text-[14px] font-normal mt-[13px]">
          Votre bénéficiaire peut récupérer l&lsquo;argent dans n&lsquo;importe quelle agence Wafacash.
        </div>
        <div className="flex flex-col items-center justify-center mx-auto mt-[25px] border-2 border-[#5653E4] rounded-lg p-5 w-1/3">
          <img src={imageWafaCashMaroc} />

          <div className="flex justify-center items-center">
            <label htmlFor="toggle" className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  id="toggle"
                  type="checkbox"
                  className="hidden"
                  checked={isChecked}
                  readOnly
                  //onChange={handleCheckChange}
                />
                <div
                  className={`w-8 h-8 rounded-full border-2 border-[#5653E4] flex items-center justify-center ${
                    isChecked ? 'bg-[#5653E4]' : 'bg-white'
                  }`}
                >
                  {isChecked && <span className="text-white">✔</span>}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="flex sm:flex-col-reverse gap-[16px] sm:mt-[3rem] justify-center w-full h-[56px] sm:h-[40px] sm:px-4 2sm:mx-4">
        <button
          className={classNames(
            'text-[18px] text-[#556987] px-[28px] sm:py-4 py-[16px] 2sm:py-[13px] border border-[#556987] w-[180px] sm:w-full font-sans rounded-lg',
          )}
          onClick={() => {
            setNumStep(1)
          }}
        >
          Précédent
        </button>

        <button
          className={classNames(
            'text-[18px] text-[#FFF] bg-[#4743C9] hover:cursor-pointer px-[28px] py-[16px] 2sm:py-[13px] sm:py-4 md:my-0 rounded-lg w-[375px] sm:w-full',
          )}
          onClick={() => {
            if (statusComplete) {
              setNumStep(3)
              functionTracks('3')
            } else if (statusPending) {
              openModalStatus()
            } else if (statusNotFound) {
              navigate('/personalinformation')
            }
          }}
        >
          Suivant
        </button>
      </div>
    </div>
  )
}

export default PointDeCollectMaroc
