import React, { useContext, useState } from 'react'
import InformationPersonnellesProfile from './informationPersonnellesProfile'
import InformationContactProfile from './informationContactProfile'
import UserContext from 'contexts/UserContext'
import { EasyParinagePage } from './easyParinagePage'
import { UpdateProfile } from './updateProfile'

interface props{
  code?:any
}

const SettingProfileSecondPart :React.FC<props>  = ({code}) => {
  
  const { user } = useContext(UserContext)
  const number = parseInt(code)
  const [isLink, setIsLink] = useState(number)
  
  const handleTextClick = (number: any) => {
    setIsLink(number)
  }

  return (
    <div>
      <div className="bg-[#5754E5] px-5 flex justify-between items-center h-[50px] rounded-sm xl:mt-8 xxs:mt-5 xs:mx-4">
        <div
          className={`relative text-[18px] xl:text-[14px] xs:text-[9px] font-semibold px-2 py-1 hover:cursor-pointer
          ${isLink === 0 ? 'text-white' : 'text-[#A2BAE7]'}`}
          onClick={() => handleTextClick(0)}
        >
          Mon Profil
          {isLink === 0 && <div className="absolute bottom-0 left-0 w-full h-1 bg-white pb-1"></div>}
        </div>

        <div
          className={`relative text-[18px] xl:text-[14px] xs:text-[9px] font-semibold px-2 py-1 hover:cursor-pointer ${
            isLink === 1 ? 'text-white' : 'text-[#A2BAE7]'
          }`}
          onClick={() => handleTextClick(1)}
        >
          EasyParrainage
          {isLink === 1 && <div className="absolute bottom-0 left-0 w-full h-1 bg-white pb-1"></div>}
        </div>

        <div
          className={`relative text-[18px] xl:text-[14px] xs:text-[9px] font-semibold px-2 py-1 hover:cursor-pointer ${
            isLink === 2 ? 'text-white' : 'text-[#A2BAE7]'
          }`}
          onClick={() => handleTextClick(2)}
        >
          Modifier mot de passe
          {isLink === 2 && <div className="absolute bottom-0 left-0 w-full h-1 bg-white pb-1"></div>}
        </div>
      </div>
      {isLink === 0 ? (
        <div className="mt-8 xs:mx-4 xxs:mt-4">
          <div>
            <div className="text-[#6B7280] text-[20px] xs:text-[15px] xxs:text-[10px] font-bold">
              Informations Personnelles
            </div>
            <InformationPersonnellesProfile />
            <div className="text-[#6B7280] text-[20px] xs:text-[15px] xxs:text-[10px] font-bold mt-5">
              Informations de contact
            </div>
            <InformationContactProfile />
            <div className="text-[#6B7280] text-[20px] xs:text-[15px] xxs:text-[10px] font-bold mt-5">Mon Adresse</div>

            <div className="flex flex-col border rounded-lg py-3 mt-5">
              <div className="flex justify-between">
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">
                  Adresse postale
                </div>
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">
                  {user?.user?.address?.street}
                </div>
              </div>

              <div className="flex justify-between pt-3">
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">
                  Code postale
                </div>
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">
                  {user?.user?.address?.zipCode}
                </div>
              </div>

              <div className="flex justify-between pt-3">
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">
                  Ville
                </div>
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">
                  {user?.user?.address?.city}
                </div>
              </div>
              <div className="flex justify-between pt-3">
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#6B7280]">Pays</div>
                <div className="text-[15px] xs:text-[13px] xxs:text-[10px] px-2 font-semibold text-[#1D3A70]">
                  {user?.user?.address?.country?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isLink === 1 ? (
        <EasyParinagePage />
      ) : (
        <UpdateProfile />
      )}
    </div>
  )
}
export default SettingProfileSecondPart
