import React, { useContext, useEffect, useState } from 'react'
import RadioFocus from '../../assets/radioFocus.svg'
import RadioON from '../../assets/radioON.svg'
import inwiLogo from '../../assets/inwiLogo.svg'
import orangeMarocLogo from '../../assets/orangeMarocLogo.svg'
import ttMarocLogo from '../../assets/ttMarocLogo.svg'
import classNames from 'classnames'
import RechargeContext from 'contexts/rechargeContext'

interface props {
  isOrangeMaroc: boolean
  isTTMaroc: boolean
  isInwi: boolean
  productMaroc: any
  indexRechargeMaroc: number
  setIndexRechargeMaroc: any
  onChangeVal: (value3: string, value2: string) => void
  setMontantTnd: (value: string) => void
  setMontantEur: (value: string) => void
  setSkuCode: (value: string) => void
  setMontantDinar: (value: string) => void
  montantTnd:string
  montantDinar:string
  montantEur:string
}

const OffreMaroc: React.FC<props> = ({
  isOrangeMaroc,
  isTTMaroc,
  isInwi,
  productMaroc,
  indexRechargeMaroc,
  setIndexRechargeMaroc,
  onChangeVal,
  setMontantTnd,
  setMontantEur,
  setSkuCode,
  setMontantDinar,
  montantTnd,
  montantDinar,
  montantEur
}) => {
  const [ONMaroc, setONMaroc] = useState(false)
  const [valMaroc, setValMaroc] = useState<any>()
  const { recharges, setRecharges } = useContext(RechargeContext)

  useEffect(() => {
    if (!recharges) return
    if (recharges) {
      setMontantTnd(recharges?.MontantTnd || '')
      setMontantDinar(recharges?.MontantDinar || '')
      setMontantEur(recharges?.MontantEur || '')
      setValMaroc(recharges?.indexRechargeTN)
      setIndexRechargeMaroc(recharges?.indexRechargeTN || -1)
      setONMaroc(recharges?.isOn || false)
    }
  }, [recharges, montantTnd, montantDinar, montantEur, valMaroc, ONMaroc, indexRechargeMaroc])

  
  return (
    <div className="flex flex-wrap 2sm:mx-auto 2sm:max-w-max 2sm:overflow-y-auto no-scrollbar 2sm:max-h-[250px] sm:max-h-[340px] justify-center items-center gap-x-[40px] 2xl:gap-x-5 6xl:gap-x-[60px] mx:gap-x-[70px] gap-y-[15px]">
      {productMaroc &&
        productMaroc.map((item: any, index: any) => (
          <React.Fragment key={index}>
            <div
              className={classNames(
                `w-1/3 sm:w-[300px] ${isOrangeMaroc ? '' : 'hidden'} ${item.ProviderCode === 'ORMA' ? '' : 'hidden'}`,
              )}
              onClick={() => {
                setMontantTnd(item.Maximum.SendCurrencyIso),
                  setMontantEur(item.Maximum.SendValue),
                  setMontantDinar(item.Maximum.ReceiveValue),
                  setSkuCode(item.SkuCode),
                  setRecharges({ ...recharges,
                    MontantTnd: item.Maximum.SendCurrencyIso,
                    MontantDinar: item.Maximum.ReceiveValue,
                    MontantEur: item.Maximum.SendValue,
                    indexRechargeTN: index,
                    isOn:true
                  })
              }}
            >
              <div className="flex flex-col items-center">
                <div
                  className="flex flex-col bg-black px-5 py-3 rounded-2xl w-fill-available 2xl:w-[250px] hover:cursor-pointer"
                  onClick={() => {
                    onChangeVal(item.Maximum.ReceiveValue, item.Maximum.SendValue)
                    setValMaroc(indexRechargeMaroc !== index ? setIndexRechargeMaroc(index) : setIndexRechargeMaroc(-1))
                    setONMaroc(true)
                    setRecharges({...recharges, indexRechargeTN: index, isOn:true})
                  }}
                >
                  <div className="flex justify-between items-center">
                    <img src={orangeMarocLogo} />
                    <div className="relative items-center">
                      <img src={RadioFocus} className="w-6 h-6" />
                      <img
                        src={RadioON}
                        className={classNames(
                          (ONMaroc && indexRechargeMaroc === index ) || (recharges?.isOn && recharges?.indexRechargeTN === index)? 'absolute inset-[25%] w-3 h-3' : 'hidden',
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-5">
                    <div className="relative">
                      <span className="text-white text-[20px] font-normal">{item.Maximum.ReceiveValue}</span>
                      <span className="text-white absolute bottom-[1rem] 2xl:left-10 font-bold text-[16px]">
                        {item.Maximum.ReceiveCurrencyIso}
                      </span>
                    </div>
                    <div className="mr-[25px] relative">
                      <span className="text-white font-bold text-[19px]">{item.Maximum.SendValue}{' '}{item.Maximum.SendCurrencyIso}</span>
                      <span className="text-white absolute bottom-[1.5rem] whitespace-nowrap left-1 text-[12px]">
                        Montant envoyé
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={classNames(`w-1/3 sm:w-[300px] ${isInwi ? '' : 'hidden'} ${item.ProviderCode === 'INMA' ? '' : 'hidden'}`)}
              onClick={() => {
                setMontantTnd(item.Maximum.SendCurrencyIso),
                  setMontantEur(item.Maximum.SendValue),
                  setMontantDinar(item.Maximum.ReceiveValue),
                  setSkuCode(item.SkuCode),
                  setRecharges({ ...recharges,
                    MontantTnd: item.Maximum.SendCurrencyIso,
                    MontantDinar: item.Maximum.ReceiveValue,
                    MontantEur: item.Maximum.SendValue,
                    indexRechargeTN: index,
                    isOn:true
                  })
              }}
            >
              <div className={`flex flex-col items-center`}>
                <div
                  className="flex flex-col bg-[#9D3493] px-5 py-3 rounded-2xl w-[250px] 2xl:w-[250px] hover:cursor-pointer"
                  onClick={() => {
                    onChangeVal(item.Maximum.ReceiveValue, item.Maximum.SendValue)
                    setValMaroc(indexRechargeMaroc !== index ? setIndexRechargeMaroc(index) : setIndexRechargeMaroc(-1))
                    setONMaroc(true)
                    setRecharges({...recharges, indexRechargeTN: index, isOn:true})
                  }}
                >
                  <div className="flex justify-between items-center">
                    <img src={inwiLogo} />
                    <div className="relative items-center">
                      <img src={RadioFocus} className="w-6 h-6" />
                      <img
                        src={RadioON}
                        className={classNames(
                          (ONMaroc && indexRechargeMaroc === index)|| (recharges?.isOn && recharges?.indexRechargeTN === index) ? 'absolute inset-[25%] w-3 h-3' : 'hidden',
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-5">
                    <div className="relative">
                      <span className="text-white text-[20px] font-normal">{item.Maximum.ReceiveValue}</span>
                      <span className="text-white absolute bottom-[1rem] 2xl:left-8 font-bold text-[18px]">
                        {item.Maximum.ReceiveCurrencyIso}
                      </span>
                    </div>
                    <div className="mr-[25px] relative">
                      <span className="text-white font-bold text-[19px]">{item.Maximum.SendValue}{' '}{item.Maximum.SendCurrencyIso}</span>
                      <span className="text-white absolute bottom-[1.5rem] whitespace-nowrap left-1 text-[12px]">
                        Montant envoyé
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={classNames(
                `w-1/3 sm:w-[300px] ${isTTMaroc ? '' : 'hidden'} ${item.ProviderCode === 'MOMA' ? '' : 'hidden'}`,
              )}
              onClick={() => {
                setMontantTnd(item.Maximum.SendCurrencyIso),
                  setMontantEur(item.Maximum.SendValue),
                  setMontantDinar(item.Maximum.ReceiveValue),
                  setSkuCode(item.SkuCode),
                  setRecharges({ ...recharges,
                    MontantTnd: item.Maximum.SendCurrencyIso,
                    MontantDinar: item.Maximum.ReceiveValue,
                    MontantEur: item.Maximum.SendValue,
                    indexRechargeTN: index,
                    isOn:true
                  })
              }}
            >
              <div className={`flex flex-col items-center`}>
                <div
                  className="flex flex-col bg-[#264B92] px-5 py-3 rounded-2xl 2xl:w-[220px] w-fill-available hover:cursor-pointer"
                  onClick={() => {
                    onChangeVal(item.Maximum.ReceiveValue, item.Maximum.SendValue)
                    setValMaroc(indexRechargeMaroc !== index ? setIndexRechargeMaroc(index) : setIndexRechargeMaroc(-1))
                    setONMaroc(true)
                    setRecharges({...recharges, indexRechargeTN: index, isOn:true})
                  }}
                >
                  <div className="flex justify-between items-center">
                    <img src={ttMarocLogo} />
                    <div className="relative items-center">
                      <img src={RadioFocus} className="w-6 h-6" />
                      <img
                        src={RadioON}
                        className={classNames(
                          (ONMaroc && indexRechargeMaroc === index|| (recharges?.isOn && recharges?.indexRechargeTN === index)) ? 'absolute inset-[25%] w-3 h-3' : 'hidden',
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-5">
                    <div className="relative">
                      <span className="text-white text-[20px] font-normal">{item.Maximum.ReceiveValue}</span>
                      <span className="text-white absolute bottom-[1rem] 2xl:left-8 font-bold text-[18px]">
                        {item.Maximum.ReceiveCurrencyIso}
                      </span>
                    </div>
                    <div className="mr-[25px] relative">
                      <span className="text-white font-bold text-[19px]">{item.Maximum.SendValue}{' '}{item.Maximum.SendCurrencyIso}</span>
                      <span className="text-white absolute bottom-[1.5rem] whitespace-nowrap left-1 text-[12px]">
                        Montant envoyé
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}
export default OffreMaroc
