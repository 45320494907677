import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import logoSignup from '../assets/logoSignup.svg'
import iconSelectInput from '../assets/iconSelectInput.svg'
import FrIcon from '../assets/flag-fr.png'
import GrcIcon from '../assets/grc.svg'
import ItaIcon from '../assets/ita.svg'
import NldIcon from '../assets/nld.svg'
import PrtIcon from '../assets/prt.svg'
import LuxIcon from '../assets/lux.svg'
import GerIcon from '../assets/ger.svg'
import DeuIcon from '../assets/deu.svg'
import AutIcon from '../assets/aut.svg'
import BelIcon from '../assets/bel.svg'
import ArrowDown from '../assets/arrowDown.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Signup } from 'helpers/api'
import ModalSignUp from './signUpContainer/modalSignUp'
import LogoConatiner from './signUpContainer/logoContainer'
import ImageSignUp from './signUpContainer/imageSignUp'
import InvisibleEye from '../assets/invisibleEye.svg'
import VisibleEye from '../assets/visibleEye.svg'
import imageLoginMobile from '../assets/imageLoginMobile.svg'
import newImageLogin from '../assets/newImageLogin.svg'
import plusIcon from '../assets/plusIcon.svg'
import closeIconParainage from '../assets/closeIconParainage.svg'
import { FindReferralCode } from 'requests/user'

const Home: React.FC = () => {
  const [email, setEmail] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isInputClicked, setIsInputClicked] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [isSuccess, setIsSuccess] = useState('')
  const [responseSuccess, setResponseSuccess] = useState(true)
  const [firstSubmit, setFirstSubmit] = useState(true)
  const [visible, setVisible] = useState(false)

  const [codeParinage, setCodeParinage] = useState('')

  const [messageCode, setMessageCode] = useState<string>('')

  const [apiCalled, setApiCalled] = useState(false)

  const openModalSignUp = () => {
    setIsOpenModal(!isOpenModal)
  }

  const closeModalSignUp = () => {
    setTimeout(() => navigate('/login'), 500)
    setIsOpenModal(false)
  }

  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  const options = [
    { index: 0, code: 'female', name: 'Femme' },
    { index: 1, code: 'male', name: 'Homme' },
  ]

  const handleSelect = (option: any) => {
    setSelectedOption(option)
    setIsOpen(false)
  }

  const [isOpenPays, setIsOpenPays] = useState(false)

  const optionsPaysSelected: { icon: any; code: string; frenshName: string; prefix: string }[] = [
    { icon: FrIcon, frenshName: 'France', code: 'FRA', prefix: '+33' },
    { icon: DeuIcon, frenshName: 'Espagne', code: 'ESP', prefix: '+34' },
    { icon: AutIcon, frenshName: 'Autriche', code: 'AUT', prefix: '+43' },
    { icon: BelIcon, frenshName: 'Belgique', code: 'BEL', prefix: '+39' },
    { icon: GrcIcon, frenshName: 'Grèce', code: 'GRC', prefix: '+32' },
    { icon: ItaIcon, frenshName: 'Italie', code: 'ITA', prefix: '+30' },
    { icon: NldIcon, frenshName: 'Pays Bas', code: 'NLD', prefix: '+31' },
    { icon: PrtIcon, frenshName: 'Portugal', code: 'PRT', prefix: '+351' },
    { icon: LuxIcon, frenshName: 'Luxembourg', code: 'LUX', prefix: '+352' },
    { icon: GerIcon, frenshName: 'Allemagne', code: 'DEU', prefix: '+49' },
  ]

  //const optionsPays = getSenderCountries()

  const [selectedOptionPays, setSelectedOptionPays] = useState('')
  const [selectedPrefix, setSelectedPrefix] = useState('')
  const [selectedCountryCode, setSelectedCountryCode] = useState('')

  const [openCode, setOpenCode] = useState(false)

  function removePlusPrefix(prefix: string) {
    if (prefix.startsWith('+')) {
      return prefix.slice(1) // Retourne le préfixe sans le "+"
    }
    return prefix // Si le préfixe ne commence pas par "+", retourne tel quel
  }

  const handleSelectPays = (optionPays: any) => {
    setSelectedOptionPays(optionPays.frenshName)
    setSelectedPrefix(optionPays.prefix)
    setSelectedCountryCode(optionPays.code)
    setIsOpenPays(false)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const functionfindCode = async () => {
    const code = codeParinage
    try {
      const response = await FindReferralCode(code)
      setApiCalled(true)
      if (response?.success === true) {
        //console.log('response success find Code Parinage:', response)
        setCodeParinage(response?.code)
        setMessageCode('true')
      } else {
        setMessageCode('false')
        console.error('response failed find Code Parinage', response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (codeParinage && codeParinage.length === 8) {
      functionfindCode()
    }
  }, [codeParinage])

  const initialFormData = {
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    password: '',
    selectedOption: '',
    selectedOptionPays: '',
    valuePaysSelect: '',
    checkbox: false,
  }

  const [formData, setFormData] = useState(initialFormData)

  const validationSchema = yup.object({
    // nom: yup
    //   .string()
    //   .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Des lettres uniquement')
    //   .test('', function (value) {
    //     if (value === undefined) {
    //       return false
    //     }
    //     return !/[0-9~!@#$%^&*()_+{}:;<>,.?/-]/.test(value)
    //   })
    //   .required('Le nom est obligatoire'),

    nom: yup
      .string()
      .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Des lettres uniquement')
      .test('', function (value) {
        if (value === undefined) {
          return false
        }
        if (value.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return !/[0-9~!@#$%^&*()_+{}:;<>,.?/-]/.test(value)
      })
      .required('Le nom est obligatoire'),

    prenom: yup
      .string()
      .matches(/^[a-zA-Z\u0600-\u06FF\s]+$/, 'Des lettres uniquement')
      .test('', function (valuePrenom) {
        if (valuePrenom === undefined) {
          return false
        }
        if (valuePrenom.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return !/[0-9~!@#$%^&*()_+{}:;<>,.?/-]/.test(valuePrenom)
      })
      .required('Le prenom est obligatoire'),

    // telephone: yup
    //   .string()
    //   .required('Téléphone obligatoire')
    //   .matches(/^[0-9]+$/, 'Le numéro de téléphone invalide'),
    telephone: yup
      .string()
      .required('Téléphone obligatoire')
      .test('', function (valueTelephone) {
        if (valueTelephone === undefined || valueTelephone.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return true
      })
      .matches(/^[0-9]+$/, 'Le numéro de téléphone est invalide'),

    password: yup
      .string()
      .required('Mot de pass obligatoire')
      .test('', function (valuePassword) {
        if (valuePassword === undefined || valuePassword.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return true
      })
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[&,$,?,+!,#,%(),\-_<>=@[\]^|}{~]).{8,16}$/,
        'Le mot de passe doit contenir au moins 8 caractères avec au moins 1 lettre, 1 chiffre et 1 caractère spécial.',
      ),
    email: yup
      .string()
      .required('Email obligatoire')
      .test('', function (valueEmail) {
        if (valueEmail === undefined || valueEmail.trim() === '') {
          return this.createError({ message: 'Ce champ est requis.' })
        }
        return true
      })
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9\u0600-\u06FF.-]+\.[a-zA-Z]{2,4}$/, "Format d'e-mail incorrect."),
    selectedOption: yup.string().required('Genre obligatoire'),
    checkbox: yup.boolean().oneOf([true], 'La présence du cocker est requise.'),
  })

  const handleSubmit = (values: typeof initialFormData) => {
    // const prefixForSignup =removePlusPrefix(values.perfixSelected)

    const credentials = {
      nom: values.nom,
      prenom: values.prenom,
      email: values.email,
      telephone: values.telephone,
      password: values.password,
      valueGender: values.selectedOption,
      valuePaysSelect: values.selectedOptionPays,
    }
    if (formik.isValid) {
      const signup: any = {
        nom: values.nom,
        prenom: values.prenom,
        email: values.email,
        telephone: values.telephone,
        password: values.password,
        valueGender: values.selectedOption,
        valuePaysSelect: values.selectedOptionPays,
      }

      setFormData(initialFormData)
      const prefix = removePlusPrefix(selectedPrefix)

      const bodyOfSignup = {
        ...credentials,
        prefix,
        contryCode: selectedCountryCode,
      }

      Signup(bodyOfSignup).then((response: any) => {
        if (response?.success) {
          setFirstSubmit(false)
          openModalSignUp()
        } else if (response.code === 1007) {
          setIsSuccess(response?.code)
        } else {
          setResponseSuccess(response?.success)
        }
      })
    } else {
      console.log('Nous sommes désolé une erreur est survenue. Merci de réessayer plus tard')
    }
  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <div>
      <div className="flex xs:flex-col sm:gap-y-[27px]">
        <div className="w-[50%] lg:w-full sm:w-[358px] sm:mx-[16px] sm:h-[450px] ">
          <LogoConatiner image={logoSignup} />
          <div className="mx-[186px] 6xl:mx-[150px] sm:mx-[16px] 2xl:mx-auto xl:mx-[90px]">
            <div className="">
              <div
                className={classNames(
                  `sm:text-[20px] 6xl:text-[18px] text-center xl:mt-auto md:mt-[50px] lg:text-[25px] xl:text-[18px] 2xl:text-[20px] text-[30px] my-5 sm:font-semibold font-bold mt-[30px]`,
                  isInputClicked && 'sm:mt-0',
                )}
              >
                <span className="text-[#1D3A70]">Créez votre Compte</span>
                <br />
                <span className="text-[#5754E5]">My Easy Transfer</span>
              </div>

              {isSuccess !== '' && (
                <div className="text-red-500 text-[13px] p-0 my-0 mx-[25px] leading-0">Vous avez déja un compte.</div>
              )}

              {responseSuccess === false && (
                <div className="text-red-500 text-[13px] p-0 my-0 mx-[25px] leading-0">
                  Nous sommes désolé une erreur est survenue. Merci de réessayer plus tard.
                </div>
              )}

              <form
                className="grid grid-cols-1 sm:gap-[15px] gap-[24px] mt-[35px] place-items-center"
                onSubmit={formik.handleSubmit}
              >
                <div className="">
                  <div className="flex h-[95px]">
                    <div className="w-full mr-5">
                      <input
                        type="text"
                        placeholder="Nom"
                        value={formik.values.nom}
                        name="nom"
                        className="rounded-xl bg-[#F9FAFB] w-full placeholder:text-[#9CA3AF] text-[#5754E5] p-4"
                        onChange={formik.handleChange}
                        autoComplete="off"
                        onBlur={formik.handleBlur('nom')}
                      />
                      {formik.errors.nom && formik.touched.nom && (
                        <div className="text-red-500 text-[14px] sm:text-[12px] p-0 m-0 leading-0">
                          {formik.errors.nom}
                        </div>
                      )}
                    </div>

                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Prénom"
                        value={formik.values.prenom}
                        name="prenom"
                        className="rounded-xl bg-[#F9FAFB] w-full placeholder:text-[#9CA3AF] text-[#5754E5] p-4"
                        onChange={formik.handleChange}
                        autoComplete="off"
                        onBlur={formik.handleBlur('prenom')}
                      />
                      <br />
                      {formik.errors.prenom && formik.touched.prenom && (
                        <div className="text-red-500 text-sm text-[14px] sm:text-[12px]">{formik.errors.prenom}</div>
                      )}
                    </div>
                  </div>

                  <div className="relative inline-block w-full h-[95px]">
                    <input
                      type="text"
                      className="p-4 w-full bg-[#F9FAFB] rounded-xl placeholder:text-[#9CA3AF] text-[#5754E5]"
                      placeholder="Genre"
                      onClick={() => setIsOpen(!isOpen)}
                      value={selectedOption}
                      onChange={(e) => {
                        formik.setFieldValue('selectedOption', e.target.value)
                        handleSelect(e.target.value)
                      }}
                      autoComplete="off"
                      name="selectedOption"
                      onBlur={formik.handleBlur('selectedOption')}
                      readOnly
                    />
                    <div className="absolute top-0 right-0 mt-[15px] mx-3" onClick={() => setIsOpen(!isOpen)}>
                      <img src={ArrowDown} className="h-6 w-6" />
                    </div>

                    <div
                      className={`absolute top-0left-0 z-10 min-h-auto rounded-lg max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                        isOpen ? 'block' : 'hidden'
                      }`}
                    >
                      {options.map((option) => (
                        <div
                          key={option.name}
                          className="py-2 pl-3 cursor-pointer hover:bg-gray-100"
                          onClick={() => {
                            formik.setFieldValue('selectedOption', option.code)
                            handleSelect(option.name)
                          }}
                        >
                          <span className="text-[#1D3A70] text-[15px] font-semibold">{option.name}</span>
                        </div>
                      ))}
                    </div>
                    {formik.touched.selectedOption && formik.errors.selectedOption ? (
                      <div className="text-red-500 py-1 text-[14px] sm:text-[12px]">{formik.errors.selectedOption}</div>
                    ) : null}
                  </div>

                  <div className="w-full h-[82px] sm:h-[100px]">
                    <input
                      type="text"
                      placeholder="Email"
                      value={formik.values.email}
                      name="email"
                      className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                      onChange={(e: any) => {
                        formik.handleChange(e), setEmail(e.target.value)
                      }}
                      onBlur={formik.handleBlur('email')}
                      autoComplete="off"
                    />
                    <br />
                    {formik.errors.email && formik.touched.email && (
                      <div className="text-red-500 text-sm text-[14px] sm:pl-[27px] sm:text-[12px]">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>

                  <div className="flex">
                    <div className="relative h-[80px] mr-4">
                      <div className="relative inline-block">
                        <div
                          className="p-4 w-[120px] bg-[#F9FAFB] rounded-xl text-[#9CA3AF]"
                          onClick={() => setIsOpenPays(!isOpenPays)}
                          onChange={(e: any) => {
                            formik.setFieldValue('selectedOptionPays', e.target.value)
                            handleSelectPays(e.target.value)
                          }}
                        >
                          {selectedOptionPays ? (
                            <div className="text-[#5754E5] w-[120px]">
                              {selectedOptionPays && (
                                <div className="flex items-center">
                                  <img
                                    src={
                                      optionsPaysSelected.find((item) => item.frenshName === selectedOptionPays)?.icon
                                    }
                                    className="w-6 h-6 mr-2"
                                  />
                                  <span>
                                    {optionsPaysSelected.find((item) => item.frenshName === selectedOptionPays)?.prefix}
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : (
                            'Préfixe'
                          )}
                        </div>

                        <div
                          className="absolute top-0 right-0 mt-[15px] mx-3"
                          onClick={() => setIsOpenPays(!isOpenPays)}
                        >
                          <img src={iconSelectInput} className="h-6 w-6" />
                        </div>

                        <div
                          className={`absolute top-full rounded-lg left-0 z-10 min-h-auto max-h-[150px] w-full shadow-selct text-[#1D3A70] bg-white overflow-y-auto ${
                            isOpenPays ? 'block' : 'hidden'
                          }`}
                        >
                          {optionsPaysSelected.map((optionPays) => (
                            <div
                              key={optionPays.frenshName}
                              className="p-1 pl-3 hover:bg-gray-100 cursor-pointer flex items-center"
                              onClick={() => {
                                formik.setFieldValue('selectedOptionPays', optionPays.frenshName)
                                handleSelectPays(optionPays)
                              }}
                            >
                              <img src={optionPays.icon} className="w-6 h-6 mr-3" /> {optionPays.prefix}
                            </div>
                          ))}
                        </div>
                      </div>
                      {formik.touched.selectedOptionPays && formik.errors.selectedOptionPays ? (
                        <div className="text-red-500 text-[14px] sm:text-[12px]">
                          {formik.errors.selectedOptionPays}
                        </div>
                      ) : null}
                    </div>
                    <div className="h-[80px] sm:ml-3 w-full">
                      <input
                        type="text"
                        placeholder="Numéro de téléphone"
                        value={formik.values.telephone}
                        name="telephone"
                        className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur('telephone')}
                        autoComplete="off"
                      />
                      {formik.errors.telephone && formik.touched.telephone && (
                        <div className="text-red-500 text-[14px] sm:text-[12px]">{formik.errors.telephone}</div>
                      )}
                    </div>
                  </div>
                  <div className="relative h-[100px]">
                    <input
                      className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                      name="password"
                      placeholder="Mot de passe"
                      type={visible ? 'text' : 'password'}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur('password')}
                      autoComplete="off"
                    />
                    <img
                      src={InvisibleEye}
                      alt=""
                      className={classNames(
                        'absolute cursor-pointer',
                        !visible ? 'top-1/3 right-3 transform -translate-y-1/2 cursor-pointer' : 'hidden',
                      )}
                      onClick={() => setVisible(!visible)}
                    />
                    <img
                      src={VisibleEye}
                      alt=""
                      className={classNames(
                        'absolute cursor-pointer',
                        visible ? 'top-1/3 right-3 transform -translate-y-1/2 cursor-pointer' : 'hidden',
                      )}
                      onClick={() => setVisible(!visible)}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <div className="text-red-500 text-[14px] sm:text-[12px]">{formik.errors.password}</div>
                    )}
                  </div>

                  {/* <div className={`${openCode ? 'h-[100px]' : 'h-[50px]' }`}>
                    <div className="flex items-center justify-between">
                      <div className="text-[#1D3A70] font-semibold text-[18px]">Code de parrainage</div>
                      <img
                        src={plusIcon}
                        className={`${openCode ? 'hidden' : 'block'} w-6 h-6 object-cover hover:cursor-pointer`}
                        onClick={() => setOpenCode(true)}
                      />
                      <img
                        src={closeIconParainage}
                        className={`${openCode ? 'block' : 'hidden'} w-8 h-8 object-cover hover:cursor-pointer`}
                        onClick={() => setOpenCode(false)}
                      />
                    </div>

                    {openCode && (
                      <div className="flex items-center my-5 justify-between bg-[#F9FAFB] rounded-lg p-1">
                        <input
                          value={codeParinage}
                          autoFocus
                          maxLength={8}
                          name="codeParinage"
                          autoComplete="off"
                          onChange={(e: any) => {
                            setCodeParinage(e.target.value)
                          }}
                          className="p-2 text-[#9CA3AF] focus:outline-none focus:ring-0 w-full bg-[#F9FAFB] font-bold"
                        />
                        <div
                          onClick={functionfindCode}
                          className="text-[#1D3A70] mx-2 font-semibold text-[16px] hover:cursor-pointer"
                        >
                          Appliquer
                        </div>
                      </div>
                    )}
                  </div> */}

                  <div className="h-[100px]">
                    <input
                      className="rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF] text-[#5754E5] p-4 w-full"
                      name="codeParinage"
                      value={codeParinage}
                      placeholder="Code de parrainage(facultatif)"
                      type="text"
                      maxLength={8}
                      autoComplete="off"
                      onChange={(e: any) => {
                        setCodeParinage(e.target.value)
                      }}
                    />

                    {apiCalled &&
                      (messageCode === 'false' ? (
                        <div className="text-red-600 text-[15px] py-1 font-semibold">Code de parrainage invalide.</div>
                      ) : (
                        <div className="text-green-700 text-[15px] py-1 font-semibold">Code de parrainage valide.</div>
                      ))}
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="h-[50px]">
                      <div className="flex items-center sm:mt-0">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          checked={formik.values.checkbox}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="checkbox"
                          autoComplete="off"
                          className="w-4 h-4 text-[#5754E5] bg-gray-100 border-[#5754E5] rounded focus:ring-[#D8D0FF] dark:focus:ring-[#5754E5]  dark:ring-[#5754E5] focus:ring-2"
                        />
                        <label
                          htmlFor="default-checkbox"
                          className="ms-2 text-[15px] sm:text-[12px] mx:text-[12px] font-semibold"
                        >
                          <a
                            href="https://www.myeasytransfer.com/cgv-cgu-Myeasytransfer"
                            className="underline text-[#5754E5]"
                          >
                            J’accepte les conditions générales*
                          </a>
                        </label>
                      </div>
                      {formik.errors.checkbox && formik.touched.checkbox && (
                        <div className="text-red-500 text-[14px] sm:text-[12px] p-0 m-0 leading-0">
                          {formik.errors.checkbox}
                        </div>
                      )}
                    </div>

                    <div className="text-[15px] 6xl:text-[13px] h-[50px] underline text-[#5754E5] sm:text-[12px] font-semibold ml-5 sm:ml-0 text-left">
                      <Link to="/login">J&apos;ai un compte</Link>
                    </div>
                  </div>
                  <button
                    type="submit"
                    value="Submit"
                    disabled={formik.isSubmitting || !formik.isValid}
                    className={`text-white w-full rounded-xl mt-1 sm:my-1 p-4 sm:p-3 ${
                      formik.isSubmitting || !formik.isValid ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#4743C9]'
                    }`}
                  >
                    S’inscrire
                  </button>
                </div>
              </form>
            </div>
          </div>
          <ModalSignUp isOpen={isOpenModal} closeModal={closeModalSignUp} email={email} />
        </div>
        <img
          className={classNames(
            isInputClicked
              ? ' hidden sm:flex w-[50%] sm:w-[330px] sm:mt-[30px] sm:mx-[30px] sm:order-first '
              : 'hidden',
          )}
          src={imageLoginMobile}
          alt=""
        />
        <ImageSignUp image={newImageLogin} SignUp="h-[950px]" />
      </div>
    </div>
  )
}
export default Home
