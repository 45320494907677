import SecureCardTransfert from 'components/transfertComponent/secureCardTransfert'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const Error = () => {
  const location = useLocation()
  const [responseFailed, setResponseFailed] = useState(null)
  const [transfertPage, setTransfertPage]  = useState(false)
  const [rechargePage, setRechargePage]  = useState(2)

  useEffect(() => {
    if (location.state && location.state.responseFailed) {
      setResponseFailed(location.state.responseFailed)
      setTransfertPage(location.state.responseSuccess)
      setRechargePage(location.state.responseSuccess)
    }
  }, [location])

  const [openModal, setOpenModal] = useState(false)
  const closeModalFunction = () => {
    setOpenModal(true)
  }


  return (
    <div>
      <SecureCardTransfert isOpen={true} closeModal={closeModalFunction} responseFailed={true} transfertPage={1} rechargePage={2} invoice={3}/>
    </div>
  )
}

export default Error
