import React, { useContext, useState } from 'react'
import UserContext from 'contexts/UserContext'

import newDefaultImageHomme from '../assets/newDefaultImageHomme.svg'
import newDefaultImageFemme from '../assets/newDefaultImageFemme.svg'

const RightDash: React.FC = () => {
  const { user } = useContext(UserContext)

  return (
    <div className="flex flex-col 2xl:mt-5">
      <div className="place-self-center">
        <div className="rounded-full ml-[2rem] lg:ml-1 6xl:ml-[30px]">
          <img
            className={`border-8 rounded-full w-[100px] h-auto 
            ${
              user?.user?.status === 'pending' && !user?.user?.isDocumentSent ? 'border-red-500' : ''
            }
            ${
              user?.user?.status === 'pending' && !user?.user?.isDocumentSent ? 'border-orange-500' : ''
            }
            ${
              user?.user?.status === 'confirmed' && !user?.user?.isDocumentSent ? 'border-green-500' : ''
            }
            `}
            src={user?.user?.personalInfo?.gender === 'female' ? newDefaultImageFemme : newDefaultImageHomme}
          />
        </div>
        <div className="flex mt-[16px]">
          <div className="text-[15px] lg:text-[13px] text-center font-semibold pl-[2rem]">
            {user?.user?.personalInfo?.firstName}{' '}{user?.user?.personalInfo?.lastName}
          </div>
        </div>
        <div className="text-[#B4B4B4] text-[15px] lg:text-[13px] font-light">{user?.user?.email}</div>
      </div>
    </div>
  )
}

export default RightDash
