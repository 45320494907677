import classNames from 'classnames'
import React, { ReactNode, useState } from 'react'
import closeModalIcon from '../../assets/closeModal.svg'
import imageModalSignUp from '../../assets/imageModalSignUp.svg'
import checkIconSignUp from '../../assets/checkIconSignUp.svg'
import mailIconSingUp from '../../assets/mailIconSingUp.svg'
interface Props {
  isOpen: boolean
  closeModal: () => void
  email: string
}

const ModalSignUp: React.FC<Props> = ({ isOpen, closeModal, email }) => {
   if (!isOpen) return null

   const [username, domain] = email.split('@')
   const maskedDomain = '*'.repeat(domain.length - 1)

  return (
    <div className={classNames('justify-center items-center flex fixed inset-0 z-50 bg-[#4945c12f] backdrop-blur-sm')}>
      <div className="relative my-6 mx-auto max-w-3xl">
        <div
          className={classNames(
            'border-0 rounded-xl pb-8 pt-5 px-[10rem] sm:px-8 md:px-8 sm:py-8 md:py-8 shadow-xl relative xl:py-4 flex flex-col w-full bg-white',
          )}
        >
          <div className="flex flex-col justify-center items-center mt-10">
            <img src={imageModalSignUp} className="w-[200px] h-[200px]" />
            <div className="text-[#1D3A70] text-[30px] sm:text-[20px] md:text[20px] font-bold pb-1 text-center">
              Vérifiez votre adresse mail
            </div>
            <div className="text-[#6B7280] font-normal text-[15px] sm:text-[12px] md:text[12px] text-center w-full">
              Merci pour votre inscription. Un lien <br /> d’activation vous a été envoyé par mail.
              <br/>
              Il se pourrait que l&#39;email soit dans vos e-mails indésirable (SPAM).
            </div>
            <div className="flex items-center justify-between rounded-xl bg-[#F9FAFB] p-5 w-full mt-8">
              <div className="flex items-center">
                <img src={checkIconSignUp} />
                <div className="flex flex-col ml-5">
                  <div className="text-[#1D3A70] text-[18px] font-medium">Email</div>
                  <div className="text-normal text-[#6B7280] text-[13px]">
                    {username.charAt(0) + maskedDomain}@{domain}
                  </div>
                </div>
              </div>

              <div>
                <img src={mailIconSingUp} />
              </div>
            </div>
            <button
              onClick={closeModal}
              className="bg-[#4743C9] rounded-xl h-[50px] mt-8 text-white text-[15px] w-full font-medium"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalSignUp
