import React, { useState, useEffect, useContext } from 'react'
import verifiedbyvisamastercard from '../../assets/verifiedbyvisamastercard.svg'
import verifiedbyvisa from '../../assets/verifiedbyvisa.svg'

import KRGlue from '@lyracom/embedded-form-glue'
import { AddTransaction, ValidateTransfer } from 'requests/transfer'
import { Spinner } from 'utils/loading'
import ModalPayment from './modalPayment'
import { useNavigate } from 'react-router-dom'
import UserContext from 'contexts/UserContext'
import transferBeneficiaryContext from 'contexts/transferBeneficiaryContext'
import { ProfileRequest } from 'requests/user'

interface props {
  departureCurrencyId: string
  destinationCurrencyId: string
  amountD: number
  currencyToTND: number
  modePay: string
  com: number
  totalSend: number
  benificiareId: string
  motif: string
  colectPointId: string
  senderId: string | undefined
  token: string | undefined
  taux: number
  setOpenAlert: () => void
  idBeneficiary: any
  banqueName: any
  iban: any
  choisirCarte: any
  reseaux?: any
  paymentMode: any
  bnfidentifiant: any
  bankcode: any
  swiftBic: any
  promoCode: any
  valuePays: any
  networkCheckedMaroc: number
  fxRateFirstTransfer: any
  functionTracks: any
}
const Paiement: React.FC<props> = ({
  departureCurrencyId,
  destinationCurrencyId,
  amountD,
  currencyToTND,
  modePay,
  com,
  totalSend,
  benificiareId,
  motif,
  colectPointId,
  senderId,
  token,
  taux,
  setOpenAlert,
  idBeneficiary,
  banqueName,
  iban,
  choisirCarte,
  reseaux,
  paymentMode,
  bnfidentifiant,
  bankcode,
  swiftBic,
  promoCode,
  valuePays,
  networkCheckedMaroc,
  fxRateFirstTransfer,
  functionTracks,
}) => {
  const navigate = useNavigate()

  const { user, setUser } = useContext(UserContext)
  const { TransferData, setTransferData } = useContext(transferBeneficiaryContext)

  const statusComplete = user?.user?.status === 'confirmed' && user?.user?.isDocumentSent
  const statusPending = user?.user?.status === 'pending' && user?.user?.isDocumentSent
  const statusNotFound = user?.user?.status === 'pending' && !user?.user?.isDocumentSent

  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(false)

  const [publicKey, setKey] = useState('')
  const [formToken, settokenT] = useState('')
  const [loading, setLoading] = useState(false)
  const [linkPaiment, setLinkPaiment] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const [responseSuccess, setResponseSuccess] = useState(false)
  const [responseFailed, setResponseFailed] = useState(false)

  const [isFailed, setIsFailed] = useState(false)

  const [showErrorMsg, setShowErrorMsg] = useState('')

  const closeModalPayment = () => {
    setOpenModal(false)
  }

  const openModalPayment = () => {
    setOpenModal(true)
  }

  useEffect(() => {
    const credentials: any = {
      amountToRecieve: currencyToTND,
      amountToSend: amountD.toString(),
      commissionValue: taux,
      departureCurrencyId: departureCurrencyId,
      destinationCurrencyId: destinationCurrencyId,
      distributionInfo: {
        partnerId: valuePays.value === 'MAD' ? '66757f4c4e5d0ed8aaffede8' : colectPointId ,
        channel: `${choisirCarte === 0 ? 'cash_pickup' : 'bank_account'}`,
      },
      fxRateBank: null,
      //fxRateCustomer: fxRateFirstTransfer !== undefined ? fxRateFirstTransfer : com,
      fxRateCustomer: com,
      network: networkCheckedMaroc === 1 ? 'Wafacash' : reseaux || null,
      paimentMode: paymentMode,
      paymentInitiantion: false,
      reason: motif,
      recieverId: idBeneficiary,
      senderId: senderId,
      totalAmount: totalSend,
      bankName: banqueName ? banqueName : null,
      Iban: iban ? iban : null,
      beneficiaryIdentifiant: bnfidentifiant ? bnfidentifiant : null,
      bankCode: bankcode ? bankcode : null,
      SWIFTBIC: swiftBic ? swiftBic : null,
      promoCode: promoCode ? promoCode : null,
      destinationCountryCode: valuePays.value === 'MAD' ? 'MAR' : 'TUN',
    }
    console.log('----credentials----', credentials)
    async function setupPaymentForm() {
      const endpoint = 'https://api.payzen.eu'
      let publicKey: any = null
      let formToken: any = null
      let transferID: any = null

      try {
        await AddTransaction(token, credentials)
          .then(async (response: any) => {
            publicKey = response?.paymentDetails?.public_key
            formToken = response?.paymentDetails?.payzen_sdk_token
            transferID = response?.transferId
            functionTracks('4')
            if (response.success && response.paymentDetails.url !== '') {
              setLinkPaiment(response.paymentDetails.url)
              setTransferData(null)
              console.log('Add Transaction',response)
              setLoading(true)
            } else if (response?.success === false && response?.code === 3000) {
              setShowErrorMsg(response?.msg)
              setTransferData(null)
            } else {
              setIsFailed(true)
              setTransferData(null)
              console.log('--- Transaction failed ---', response)
            }
          })
          .catch((error: any) => {
            setMessageError(true)
            console.log(error)
          })

        const { KR } = await KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
        setLoading(true)
        await KR.setFormConfig({
          /* set the minimal configuration */
          formToken: formToken,
          'kr-language': 'fr' /* to update initialization parameter */,
        })

        const { result } = await KR.attachForm('#myPaymentForm') /* Attach a payment form  to myPaymentForm div*/

        await KR.showForm(result.formId) /* show the payment form */
        const body = {
          transferId: transferID,
          promoCode: promoCode ? promoCode : null,
        }
        await KR.onSubmit(async (paymentData) => {
          const response = await ValidateTransfer(token, body)
            .then(async (response: any) => {
              if (response?.data?.success) {
                setResponseSuccess(true)
                navigate('/success', { state: { responseSuccess, transfert: 1 } })
              } else {
                setResponseFailed(true)
                navigate('/error', { state: { responseFailed, transfert: 1 } })
              }
            })
            .catch((error: any) => {
              console.log('message error', error)
            })
          return false //Return false to prevent the redirection
        })
      } catch (error) {
        setMessage(error + ' (see console for more details beem)')
      }
    }
    setupPaymentForm()
  }, [])

  return (
    <div className="form">
      {loading ? (
        <>
          {paymentMode === 'cb' && (
            <div className="flex flex-col justify-center items-center sm:ml-[25px]">
              <div className="border-2 border-[#4743c948] max-w-max p-5 rounded-lg shadow">
                <div id="myPaymentForm"></div>
                {/* <ModalPayment isOpen={openModal} closeModal={closeModalPayment} message={message} /> */}
              </div>
              <div>
                <div className="text-center text-[15] 2md:text-[15px] 2md:w-[335px] xs:text-[20px] sm:text-[20px] text-normal mx-[60px] sm:mx-[30px] xs:mx-[30px] 2xl:mx-[90px] md:mx-0 text-[#6B7280] sm:w-[450px] xs:w-[450px] w-[550px] mt-5">
                  Selon votre établissement bancaire, vous pourrez être redirigé vers la page d&rsquo;authentification
                  de votre banque avant la validation de votre paiement.
                  <br />
                  <span className="pt-2">Sécurisé par :</span>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img src={verifiedbyvisamastercard} className="w-32 h-32 mr-5" />
                <img src={verifiedbyvisa} className="w-32 h-32" />
              </div>
            </div>
          )}
          {paymentMode === 'pi' && (
            <div className="flex flex-col justify-center items-center">
              <iframe src={linkPaiment} width="540" height="470" className="sm:max-w-max sm:h-[500px]"></iframe>
            </div>
          )}
        </>
      ) : messageError ? (
        <div className="text-red-500 text-center text-[18px] py-5 mx-5 max-w-max">
          Vous avez atteint votre nombre maximal de transferts autorisés par jour.
        </div>
      ) : showErrorMsg ? (
        <div className="text-red-500 text-center text-[18px] py-5 mx-5 max-w-max">{showErrorMsg}</div>
      ) : isFailed ? (
         <div className="text-red-500 text-center text-[18px] py-5 mx-5 max-w-max">
            Erreur du serveur, veuillez réessayer plus tard.
         </div>
      ) : <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />}
    </div>
  )
}

export default Paiement
