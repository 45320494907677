import React, { useContext, useEffect, useRef, useState } from 'react'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import SuccessPaymentModal from './successPaymentModal'
import classNames from 'classnames'
import verifiedbyvisamastercard from '../../assets/verifiedbyvisamastercard.svg'
import verifiedbyvisa from '../../assets/verifiedbyvisa.svg'
import RechargeContext from 'contexts/rechargeContext'

interface props {
  clientSecret: any
  confirmPayment: any
  SendValue: string
  ProviderCode: any
  isTelecom: boolean
  isOrange: boolean
  isOoredoo: boolean
  buttonClicked: boolean
  selectionCount: number
  functionTracks: any
}

const PaiementForm: React.FC<props> = ({
  clientSecret,
  confirmPayment,
  SendValue,
  ProviderCode,
  isTelecom,
  isOrange,
  isOoredoo,
  buttonClicked,
  selectionCount,
  functionTracks,
}) => {
  const elements = useElements()
  const stripe = useStripe()

  const { recharges, setRecharges } = useContext(RechargeContext)

  const cardNumberRef = useRef<HTMLInputElement>(null)
  const cardExpiryRef = useRef<HTMLInputElement>(null)
  const cardCvcRef = useRef<HTMLInputElement>(null)

  const [cardNumberElement, setCardNumberElement] = useState<any>(null)
  const [cardExpiryElement, setCardExpiryElement] = useState<any>(null)
  const [cardCvcElement, setCardCvcElement] = useState<any>(null)

  const [successPayment, setSuccessPayment] = useState('')
  const [openModalSuccessPayment, setOpenModalSuccessPayment] = useState(false)

  const [isButtonClicked, setIsButtonClicked] = useState(false)

  const [countStatus, setCountStatus] = useState(1)
  const [countNbClick, setCountNbClick] = useState(1)

  const closeModalSuccessPayment = () => {
    setOpenModalSuccessPayment(false)
  }

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const cardNumberElement = elements.create('cardNumber')
    const cardExpiryElement = elements.create('cardExpiry')
    const cardCvcElement = elements.create('cardCvc')

    if (cardNumberRef.current) {
      cardNumberElement.mount(cardNumberRef.current)
    }

    if (cardExpiryRef.current) {
      cardExpiryElement.mount(cardExpiryRef.current)
    }

    if (cardCvcRef.current) {
      cardCvcElement.mount(cardCvcRef.current)
    }

    setCardNumberElement(cardNumberElement)
    setCardExpiryElement(cardExpiryElement)
    setCardCvcElement(cardCvcElement)

    cardNumberElement.update({ placeholder: 'Numéro de carte' })
    cardExpiryElement.update({ placeholder: 'Date' })
    cardCvcElement.update({ placeholder: 'CVC' })

    return () => {
      cardNumberElement.destroy()
      cardExpiryElement.destroy()
      cardCvcElement.destroy()
    }
  }, [stripe, elements])

  const handleSubmit = async (e: any) => {
    setIsButtonClicked(true)
    e.preventDefault()
    if (!stripe || !elements || !cardNumberElement) {
      return
    }
    const clientSecretKey = clientSecret

    

    const { paymentIntent } = await stripe.confirmCardPayment(clientSecretKey, {
      payment_method: {
        card: cardNumberElement,
      },
    })
    functionTracks('3')
    

    // if (successPayment === 'undefined') {
    //   setCountStatus(countStatus + 1)
    //   setCountNbClick(countNbClick + 1)
    //   if (countStatus === 2 && countNbClick === 2) {
    //     setIsButtonClicked(true)
    //     // setOpenModalSuccessPayment(true)
    //   }
    //   //console.log('is undefined !')
    //   setRecharges({})
    // }
    if (paymentIntent?.status === 'succeeded') {
      confirmPayment()
      setIsButtonClicked(true)
      setOpenModalSuccessPayment(true)
      setSuccessPayment(`${paymentIntent?.status}`)
      setRecharges({})
    }
  }

  return (
    <div className="">
      {/* {ProviderCode === 'Telecom' && (
        <div className={classNames(isTelecom ? '' : 'hidden')}>
          <form onSubmit={handleSubmit} id="payment-form" autoComplete="off">
            <div className="h-[80px] sm:h-[110px]">
              <div className="relative mt-3 flex justify-center">
                <div
                  id="card-number"
                  ref={cardNumberRef}
                  contentEditable="true"
                  className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                >
                </div>
              </div>
            </div>

            <div className="h-[80px] sm:h-[110px] w-full">
              <div className="relative flex justify-center">
                <div
                  id="card-expiry"
                  ref={cardExpiryRef}
                  contentEditable="true"
                  className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                ></div>
              </div>
            </div>

            <div className="h-[80px] sm:h-[110px]">
              <div className="relative flex justify-center">
                <div
                  id="card-cvc"
                  ref={cardCvcRef}
                  contentEditable="true"
                  className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                ></div>
              </div>
            </div>

            <div className="flex justify-center my-2 items-center ">
              <button
                className={classNames(
                  `text-[18px] text-[#FFF] w-1/2 p-2 h-[56px] rounded-xl sm:w-[300px] xs:w-[300px]
                  ${isButtonClicked ? 'bg-[#d3d3d3] ' : 'bg-[#4743C9]'}
                    `,
                  )}
                disabled={isButtonClicked}
              >
                Payer <span className="pl-4 font-semibold">{SendValue} EUR</span>
              </button>
            </div>

            <div className="text-center text-[15] 2md:text-[15px] 2md:w-[335px] xs:text-[20px] sm:text-[13px] text-normal mx-[60px] sm:mx-[30px] xs:mx-[30px] 2xl:w-[400px] md:mx-0 text-[#6B7280] sm:w-[450px] xs:w-[450px] w-[550px] mt-5">
              Selon votre établissement bancaire, vous pourrez être redirigé vers la page d&rsquo;authentification de
              votre banque avant la validation de votre paiement.
            </div>
            <div className="text-center text-[15px] xs:text-[12px] sm:text-[12px] text-normal text-[#6B7280]">
              <br /> Sécurisé par :
            </div>
            <div className="flex justify-center items-center">
              <img src={verifiedbyvisamastercard} className="w-32 h-32 mr-5" />
              <img src={verifiedbyvisa} className="w-32 h-32" />
            </div>
          </form>
        </div>
      )}

      {ProviderCode === 'Orange' && (
        <div className={classNames(isOrange ? '' : 'hidden')}>
          <form onSubmit={handleSubmit} id="payment-form" autoComplete="off">
            <div className="h-[80px] sm:h-[110px]">
              <div className="relative mt-3 flex justify-center">
                <div
                  id="card-number"
                  ref={cardNumberRef}
                  className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]">
                </div>
              </div>
            </div>

            <div className="h-[80px] sm:h-[110px] w-full">
              <div className="relative flex justify-center">
                <div
                  id="card-expiry"
                  ref={cardExpiryRef}
                  className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                ></div>
              </div>
            </div>

            <div className="h-[80px] sm:h-[110px]">
              <div className="relative flex justify-center">
                <div
                  id="card-cvc"
                  ref={cardCvcRef}
                  className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                ></div>
              </div>
            </div>

            <div className="flex justify-center my-2 items-center ">
              <button
                className={classNames(
                  `text-[18px] text-[#FFF] w-1/2 p-2 h-[56px] rounded-xl sm:w-[300px] xs:w-[300px]
                  ${isButtonClicked 
                    ?
                     'bg-[#d3d3d3] ' :
                     'bg-[#4743C9]'
                    }
                  `,
                )}
                disabled={isButtonClicked}
              >
                Payer <span className="pl-4 font-semibold">{SendValue} EUR</span>
              </button>
            </div>

            <div className="text-center text-[15] 2md:text-[15px] 2md:w-[335px] xs:text-[20px] sm:text-[13px] text-normal mx-[60px] sm:mx-[30px] xs:mx-[30px] 2xl:w-[400px] md:mx-0 text-[#6B7280] sm:w-[450px] xs:w-[450px] w-[550px] mt-5">
              Selon votre établissement bancaire, vous pourrez être redirigé vers la page d&rsquo;authentification de
              votre banque avant la validation de votre paiement.
            </div>
            <div className="text-center text-[15px] xs:text-[12px] sm:text-[12px] text-normal text-[#6B7280]">
              <br /> Sécurisé par :
            </div>
            <div className="flex justify-center items-center">
              <img src={verifiedbyvisamastercard} className="w-32 h-32 mr-5" />
              <img src={verifiedbyvisa} className="w-32 h-32" />
            </div>
          </form>
        </div>
      )}

      {ProviderCode === 'Ooredoo' && ( */}
      {/* <div className={classNames(isOoredoo ? '' : 'hidden')}> */}
      <form onSubmit={handleSubmit} id="payment-form" autoComplete="off">
        <div className="h-[80px] sm:h-[110px]">
          <div className="relative mt-3 flex justify-center">
            <div
              id="card-number"
              ref={cardNumberRef}
              className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
            ></div>
          </div>
        </div>

        <div className="h-[80px] sm:h-[110px] w-full">
          <div className="relative flex justify-center">
            <div
              id="card-expiry"
              ref={cardExpiryRef}
              className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
            ></div>
          </div>
        </div>

        <div className="h-[80px] sm:h-[110px]">
          <div className="relative flex justify-center">
            <div
              id="card-cvc"
              ref={cardCvcRef}
              className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
            ></div>
          </div>
        </div>

        <div className="flex justify-center my-2 items-center ">
          <button
            className={classNames(
              `text-[18px] text-[#FFF] w-1/2 p-2 h-[56px] rounded-xl sm:w-[300px] xs:w-[300px]
                  ${isButtonClicked ? 'bg-[#d3d3d3] ' : 'bg-[#4743C9]'}
                  `,
            )}
            disabled={isButtonClicked}
          >
            Payer <span className="pl-4 font-semibold">{SendValue} EUR</span>
          </button>
        </div>

        <div className="text-center text-[15] 2md:text-[15px] 2md:w-[335px] xs:text-[20px] sm:text-[13px] text-normal mx-[60px] sm:mx-[30px] xs:mx-[30px] 2xl:w-[400px] md:mx-0 text-[#6B7280] sm:w-[450px] xs:w-[450px] w-[550px] mt-5">
          Selon votre établissement bancaire, vous pourrez être redirigé vers la page d&rsquo;authentification de votre
          banque avant la validation de votre paiement.
        </div>
        <div className="text-center text-[15px] xs:text-[12px] sm:text-[12px] text-normal text-[#6B7280]">
          <br /> Sécurisé par :
        </div>
        <div className="flex justify-center items-center">
          <img src={verifiedbyvisamastercard} className="w-32 h-32 mr-5" />
          <img src={verifiedbyvisa} className="w-32 h-32" />
        </div>
      </form>
      {/* </div> */}
      {/* )} */}

      {successPayment !== '' && (
        <SuccessPaymentModal
          isOpen={openModalSuccessPayment}
          closeModal={closeModalSuccessPayment}
          successPayment={successPayment}
        />
      )}
    </div>
  )
}

export default PaiementForm
