import * as React from 'react'
import PropTypes from 'prop-types'

function RechargeIcon({
  fill = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="26"
      viewBox="0 0 21 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mobile-phone-camera--photos-photo-picture-photography-camera-pictures-phone">
        <path
          id="Vector"
          d="M8.71436 19.8569H10.4286"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M9.57122 1.85718H3.57122C2.62445 1.85718 1.85693 2.62469 1.85693 3.57146V22.4286C1.85693 23.3754 2.62445 24.1429 3.57122 24.1429H15.5712C16.518 24.1429 17.2855 23.3754 17.2855 22.4286V16.4286"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="Group 1000004059">
          <path
            id="Vector 3"
            d="M19.4627 4.84996C19.3876 4.63757 19.2718 4.44443 19.1246 4.27996C18.8115 3.93012 18.3564 3.70996 17.85 3.70996H16.5264C15.6834 3.70996 15 4.39335 15 5.23636C15 5.95368 15.4995 6.57422 16.2002 6.72751L18.2154 7.16833C19.0005 7.34006 19.56 8.03574 19.56 8.83934C19.56 9.78374 18.7944 10.55 17.85 10.55H16.71C15.9655 10.55 15.332 10.0741 15.0973 9.40996"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2489"
            d="M17.2803 3.71V2"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 2490"
            d="M17.2803 12.26V10.55"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  )
}
RechargeIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}
export default RechargeIcon
