import SecureCardTransfert from 'components/transfertComponent/secureCardTransfert'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const Success = () => {
  const location = useLocation()
  const [responseSuccess, setResponseSuccess] = useState(null)
  const [transfertPage, setTransfertPage]  = useState(1)
  const [rechargePage, setRechargePage]  = useState(2)

  useEffect(() => {
    if (location.state && location.state.responseSuccess) {
      setResponseSuccess(location.state.responseSuccess)
      setTransfertPage(location.state.responseSuccess)
      setRechargePage(location.state.responseSuccess)
    }
  }, [location])

  const [openModal, setOpenModal] = useState(false)
  const closeModalFunction = () => {
    setOpenModal(true)
  }
  return (
    <div>
      <SecureCardTransfert isOpen={true} closeModal={closeModalFunction} responseSuccess={true} transfertPage={1} rechargePage={2} invoice={3} />
    </div>
  )
}

export default Success
