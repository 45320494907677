/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { PaimentFacture } from 'types/paimentFacture'

  interface PaimentFactureProps {
    paimentFacture: PaimentFacture | null
    setPaimentFacture: (TransferData: PaimentFacture | null) => void
  }
  
  const paimentFactureContext = createContext<PaimentFactureProps>({
    paimentFacture: null,
    setPaimentFacture: () => {},
  })
  
  export default paimentFactureContext