import React, { useContext } from 'react'

import RadioON from '../../assets/radioON.svg'
import RadioFocus from '../../assets/radioFocus.svg'

import firstImageMaroc from '../../assets/firstImageMaroc.svg'
import secondImageMaroc from '../../assets/secondImageMaroc.svg'
import thirdImageMaroc from '../../assets/thirdImageMaroc.svg'
import RechargeContext from 'contexts/rechargeContext'

interface props {
  setOperateur: (value: string) => void
  onChange: (value: string) => void
  onChangePrefix: (value: string) => void
  phoneNumber: any
  handleClick: any
  isOperateur: string
  isClicked: boolean
}

const MarocCarte: React.FC<props> = ({
  setOperateur,
  onChange,
  handleClick,
  isOperateur,
  onChangePrefix,
  phoneNumber,
  isClicked,
}) => {
  const { recharges, setRecharges } = useContext(RechargeContext)

  return (
    <div>
      <div className={`flex flex-col lg:flex-row mt-[47px] 2sm:mr-6 md:mt-[75px]`}>
        <div className=" flex gap-[33px] 2sm:flex-row sm:flex-row md:text-center md:flex-row lg:flex-row xl:flex-row">
          <div onClick={handleClick} className="flex flex-col items-center hover:cursor-pointer">
            <img
              src={firstImageMaroc}
              onClick={() => {
                setOperateur('INMA')
                onChange('Inwi')
                setRecharges({...recharges, isOperateur: 'Inwi'})
              }}
              className="rounded-md w-[150px] h-[100px] object-cover"
            />

            <div className="relative mt-3 items-center">
              <img
                src={RadioFocus}
                className="w-6 h-6"
                onClick={() => {
                  setOperateur('INMA') 
                  onChange('Inwi')
                  setRecharges({...recharges, isOperateur: 'Inwi'})
                }}
              />
              {(isOperateur === 'Inwi') && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
            </div>
          </div>

          <div onClick={handleClick} className="flex flex-col items-center hover:cursor-pointer">
            <img
              src={secondImageMaroc}
              onClick={() => {
                setOperateur('ORMA')
                onChange('OrangeMaroc')
                setRecharges({...recharges, isOperateur: 'OrangeMaroc'})
              }}
              className="rounded-md w-[150px] h-[100px] object-cover"
            />

            <div className="relative mt-3 items-center">
              <img
                src={RadioFocus}
                className="w-6 h-6"
                onClick={() => {
                  setOperateur('ORMA')
                  onChange('OrangeMaroc')
                  setRecharges({...recharges, isOperateur: 'OrangeMaroc'})
                }}
              />
              {isOperateur === 'OrangeMaroc' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
            </div>
          </div>

          <div onClick={handleClick} className="flex flex-col items-center hover:cursor-pointer">
            <img
              src={thirdImageMaroc}
              onClick={() => {
                setOperateur('OMMA')
                onChange('TTMaroc')
                setRecharges({...recharges, isOperateur: 'TTMaroc'})
              }}
              className="rounded-md w-[150px] h-[100px] object-cover"
            />

            <div className="relative mt-3 items-center">
              <img
                src={RadioFocus}
                className="w-6 h-6"
                onClick={() => {
                  setOperateur('OMMA')
                  onChange('TTMaroc')
                  onChangePrefix(phoneNumber)
                  setRecharges({...recharges, isOperateur: 'TTMaroc'})
                }}
              />
              {isOperateur === 'TTMaroc' && <img src={RadioON} className="absolute inset-[25%] w-3 h-3" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarocCarte
