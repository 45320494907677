import React, { useContext, useEffect, useState } from 'react'
import newDefaultImageHomme from '../../assets/newDefaultImageHomme.svg'
import newDefaultImageFemme from '../../assets/newDefaultImageFemme.svg'
import avatar1 from '../../assets/avatar1.svg'
import avatar2 from '../../assets/avatar2.svg'
import avatar3 from '../../assets/avatar3.svg'
import avatar4 from '../../assets/avatar4.svg'
import avatar5 from '../../assets/avatar5.svg'
import avatar6 from '../../assets/avatar6.svg'
import avatar7 from '../../assets/avatar7.svg'
import avatar8 from '../../assets/avatar8.svg'
import avatar9 from '../../assets/avatar9.svg'
import avatar10 from '../../assets/avatar10.svg'
import avatar11 from '../../assets/avatar11.svg'
import avatar12 from '../../assets/avatar12.svg'
import avatar13 from '../../assets/avatar13.svg'
import avatar14 from '../../assets/avatar14.svg'
import avatar15 from '../../assets/avatar15.svg'
import avatar16 from '../../assets/avatar16.svg'
import avatar17 from '../../assets/avatar17.svg'
import avatar18 from '../../assets/avatar18.svg'
import avatar19 from '../../assets/avatar19.svg'
import avatar20 from '../../assets/avatar20.svg'
import avatar21 from '../../assets/avatar21.svg'
import avatar22 from '../../assets/avatar22.svg'
import avatar23 from '../../assets/avatar23.svg'
import avatar24 from '../../assets/avatar24.svg'

import UserContext from 'contexts/UserContext'
import ImageUserUpdate from './imageUserUpdate'
import { usersUpdateAvatar } from 'requests/user'
import localforage from 'localforage'

const SettingProfileFirstPart = () => {
  const dataAvatar = [
    { index: 1, image: avatar1 },
    { index: 2, image: avatar2 },
    { index: 3, image: avatar3 },
    { index: 4, image: avatar4 },
    { index: 5, image: avatar5 },
    { index: 6, image: avatar6 },
    { index: 7, image: avatar7 },
    { index: 8, image: avatar8 },
    { index: 9, image: avatar9 },
    { index: 10, image: avatar10 },
    { index: 11, image: avatar11 },
    { index: 12, image: avatar12 },
    { index: 12, image: avatar13 },
    { index: 14, image: avatar14 },
    { index: 15, image: avatar15 },
    { index: 16, image: avatar16 },
    { index: 17, image: avatar17 },
    { index: 18, image: avatar18 },
    { index: 19, image: avatar19 },
    { index: 20, image: avatar20 },
    { index: 21, image: avatar21 },
    { index: 22, image: avatar22 },
    { index: 23, image: avatar23 },
    { index: 24, image: avatar24 },
  ]

  const { user, setUser } = useContext(UserContext)

  const [updateImageUser, setUpdateImageUser] = useState(false)
  const [avatarUser, setAvatarUser] = useState(false)
  const [avatarId, setAvatarId] = useState<number | null>(null)
  const [newImage, setNewImage] = useState<any>()

  const closeModalImageUser = () => {
    setUpdateImageUser(false)
  }
  const handleImageClick = (index: number) => {
    setAvatarId(index)
  }

  const updateAvatar = async () => {
    const id = user?.user?._id
    const token = user?.token?.accessToken
    try {
      await usersUpdateAvatar(id, avatarId || undefined, token)
        .then((response: any) => {
          if (response?.success) {
            setAvatarUser(true)
            // if (user) {
            //   setUser({
            //     token: user.token,
            //     success: true,
            //     user: response.user,
            //   })
            // }
            }else {
              setAvatarUser(false)
            if (user) {
              setUser({ token: user.token, success: true, user: response.user })
            }
          }
        })
        .catch((error) => {
          console.error('Error updating avatar:', error)
        })
    } catch (error) {
      console.error('Error updating avatar:', error)
    }
  }

  return (
    <div className="">
      <div className="text-black text-[24px] font-bold p-3">Mon compte</div>
      <div className="xs:flex xs:justify-center">
        <div className="flex flex-col items-center pt-[35px] bg-[#F9FAFB] mt-3 w-[355px] h-[400px] rounded-xl sm:w-[320px] sm:h-[360px] xs:w-[265px] xs:h-[360px]">
          {avatarId !== null ? (
            <div>
              <img
                src={dataAvatar.find((avatar) => avatar.index === avatarId)?.image || ''}
                alt="image user"
                className="border-[#4743c95e] w-24 h-24 border-4 rounded-full"
              />
            </div>
          ) : (
            <img
              src={user?.user?.personalInfo?.gender === 'female' ? newDefaultImageFemme : newDefaultImageHomme}
              className="border-[#4743c95e] w-24 h-24 border-4 rounded-full"
            />
          )}
          
          <div className="text-black text-[18px] font-semibold mt-5">
            {user?.user?.personalInfo?.firstName} {user?.user?.personalInfo?.lastName}
          </div>
          <div className="text-[#B4B4B4] text-[14px] mt-2">{user?.user?.email}</div>
          {/* <button
            onClick={() => setUpdateImageUser(true)}
            className="bg-[#4743c9] text-white mt-5 text-[15px] font-bold p-2 w-1/2 rounded-xl"
          >
            Modifier
          </button> */}
        </div>
      </div>

      <ImageUserUpdate
        isOpen={updateImageUser}
        closeModal={closeModalImageUser}
        selectedImageId={avatarId}
        handleImageClick={handleImageClick}
        newImage={newImage}
        setNewImage={setNewImage}
        updateAvatar={updateAvatar}
      />
    </div>
  )
}
export default SettingProfileFirstPart
