import axios from 'axios'
// import { BASE_URL } from './config'


export default axios.create({
  // baseURL: 'https://www.api.myeasytransfer.com/v1',
  baseURL: 'https://www.app-dev.api.myeasytransfer.com/v1',
  timeout: 20000,
})

