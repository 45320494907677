import React, { ReactNode } from 'react'
import closeModalIcon from '../../assets/closeModal.svg'
import classNames from 'classnames'

interface Props {
  isOpen: boolean
  children: ReactNode
  closeModal: () => void
}

const ModalBeneficiary = ({ isOpen, children, closeModal }: Props) => {
  if (!isOpen) return null

  return (
    <div
      className={classNames(
        'items-center flex fixed inset-0 outline-none px-2 focus:outline-none shadow-inner z-50 bg-[#4945c12f] backdrop-blur-sm',
      )}
    >
      <div className="relative my-6 sm:my-0 lg:my-0 mx-auto sm:w-full xs:w-full xxs:w-full md:w-full">
        <div
          className={classNames(
            'border-0 shadow-lg py-3 px-[35px] sm:px-0 xs:px-0 2sm:px-0 sm:py-1 lg:py-2 rounded-2xl flex flex-col w-full bg-white outline-none focus:outline-none',
          )}
        >
          <img
            src={closeModalIcon}
            width={20}
            height={20}
            onClick={() => {
              closeModal()
            }}
            className="right-2 absolute hover:cursor-pointer xs:w-[18px] m-[25px] sm:mx-2 sm:mt-3"
          />
          {children}
        </div>
      </div>
    </div>
  )
}

export default ModalBeneficiary
